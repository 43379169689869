import { MappedTheme, ITheme } from '@interfaces/theme'

export const extend = (extending: ITheme, newTheme: ITheme): ITheme => {
  return { ...extending, ...newTheme }
}

export const mapTheme = (variables: ITheme): MappedTheme => {
  const themeObj: MappedTheme = {
    '--color-base-50': variables.base['50'] || '',
    '--color-base-100': variables.base['100'] || '',
    '--color-base-200': variables.base['200'] || '',
    '--color-base-300': variables.base['300'] || '',
    '--color-base-400': variables.base['400'] || '',
    '--color-base-500': variables.base['500'] || '',
    '--color-base-600': variables.base['600'] || '',
    '--color-base-700': variables.base['700'] || '',
    '--color-base-800': variables.base['800'] || '',
    '--color-base-900': variables.base['900'] || '',
    '--color-label-50': variables.label['50'] || '',
    '--color-label-100': variables.label['100'] || '',
    '--color-label-200': variables.label['200'] || '',
    '--color-label-400': variables.label['400'] || '',
    '--color-label-500': variables.label['500'] || '',
    '--color-label-600': variables.label['600'] || '',
    '--color-label-700': variables.label['700'] || '',
    '--color-label-800': variables.label['800'] || '',
    '--color-label-900': variables.label['900'] || '',
    '--color-timer-start': variables.timer.start || '',
    '--color-timer-middle': variables.timer.middle || '',
    '--color-timer-end': variables.timer.end || '',
    '--color-background-start': variables.background['start'] || '',
    '--color-background-end': variables.background['end'] || '',
    '--color-gradient-start': variables.gradient['start'] || '',
    '--color-gradient-end': variables.gradient['end'] || '',
    '--color-gradient-background': variables.gradient.background || '',
    '--color-primary': variables.primary || '',
    '--color-primary-tint': variables['primary-tint'] || variables.primary,
    '--color-primary-shade': variables['primary-shade'] || variables.primary,
    '--color-secondary': variables.secondary || '',
    '--color-secondary-tint':
      variables['secondary-tint'] || variables.secondary,
    '--color-secondary-shade':
      variables['secondary-shade'] || variables.secondary,
    '--color-bits': variables.bits || '',
    '--color-bits-tint': variables['bits-tint'] || variables.bits,
    '--color-bits-shade': variables['bits-shade'] || variables.bits,
    '--color-success': variables.success || '',
    '--color-success-tint': variables['success-tint'] || variables.success,
    '--color-success-shade': variables['success-shade'] || variables.success,
    '--color-warning': variables.warning || '',
    '--color-warning-tint': variables['warning-tint'] || variables.warning,
    '--color-warning-shade': variables['warning-shade'] || variables.warning,
    '--color-error': variables.error || '',
    '--color-error-tint': variables['error-tint'] || variables.error,
    '--color-error-shade': variables['error-shade'] || variables.error,
    '--color-white': variables.white || '',
    '--color-black': variables.black || '',
    '--color-gold': variables.gold || '',
    '--color-gold-tint': variables['gold-tint'] || variables.gold,
    '--color-gold-shade': variables['gold-shade'] || variables.gold,
    '--font-font-base': variables['font-base'],
    '--font-font-secondary': variables['font-secondary'],
  }

  // Mutates themeObject to include rgb variants of colors
  Object.keys(themeObj).forEach((property) => {
    if (property === 'name') {
      return
    }

    const rgbColor = hexToRgb(themeObj[property])
    if (rgbColor) {
      const rgb = `${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}`
      themeObj[`${property}-rgb`] = rgb

      if (rgbColor.a) {
        themeObj[`${property}-rgb-alpha`] = String(rgbColor.a)
      }
    }
  })

  return themeObj
}

const hexToRgb = (
  hex: string | null
): { r: number; g: number; b: number; a?: number } | null => {
  if (!hex) return null

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i.exec(
    hex
  )

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
        ...(!!result?.[4] && { a: parseInt(result[4], 16) / 255 }),
      }
    : null
}
