import React, { FC } from 'react'
import { ReactComponent as BitsIcon } from '@assets/bits.svg'
import { VipLeaderboardBannerProps } from './types'

export const VipLeaderboardBanner: FC<VipLeaderboardBannerProps> = ({
  className,
  amount,
  onClick,
}) => {
  const formattedAmount = amount?.toLocaleString('en-US')
  const text = 'Unlock exclusive rewards and join the VIP leaderboard'
  return (
    <div
      data-testid="vip-leaderboard-banner"
      className={`flex flex-row w-full py-1 pe-2 ps-4 bg-primary items-center justify-between ${className}`}
    >
      <p className="text-label-800 text-xs font-semibold">{text}</p>
      <button
        onClick={onClick}
        data-testid="vip-leaderboard-banner-button"
        className="flex items-center outline-none cursor-pointer flex-shrink-0 ms-2 border-none justify-center rounded bg-label-800 px-1.5 py-1"
      >
        <BitsIcon className="w-4 h-4" />
        <span className="text-label-50 text-xs font-semibold">
          {formattedAmount}
        </span>
      </button>
    </div>
  )
}

export default VipLeaderboardBanner
