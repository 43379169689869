import React, {
  FC,
  useRef,
  useState,
  useEffect,
  KeyboardEvent,
  useCallback,
  PropsWithChildren,
  forwardRef,
  useImperativeHandle,
} from 'react'
import {
  DefaultProperties,
  fixPrefixCursor,
  getFormattedValue,
  getMaxLength,
  getNextCursorPosition,
  getPostDelimiter,
  getPrefixStrippedValue,
  headStr,
  setSelection,
  stripDelimiters,
} from './helpers'
import {
  CustomChangeEvent,
  CustomFocusEvent,
  MaskedInputProps,
  MaskedInputRef,
} from './types'

const MaskedInput = forwardRef<
  MaskedInputRef,
  PropsWithChildren<MaskedInputProps>
>(
  (
    {
      value: initValue = '',
      blocks = [],
      className = '',
      uppercase = true,
      lowercase = false,
      'data-testid': testId = 'code-input__input',
      onChange,
    },
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement | null>(null)
    const owner = useRef(
      DefaultProperties.assign({}, { initValue, blocks, uppercase, lowercase })
    )
    const [value, setValue] = useState('')
    const [cursorPosition, setCursorPosition] = useState<number>()

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
      owner.current.lastInputValue = owner.current.result
      owner.current.isBackward = event.key === 'Backspace'
    }

    const handleFocus = (event: CustomFocusEvent<HTMLInputElement>) => {
      event.target.rawValue = getRawValue()

      if (!inputRef.current) return

      fixPrefixCursor(
        inputRef.current,
        owner.current.prefix,
        owner.current.delimiter
      )
    }

    const handleChange = (event: CustomChangeEvent<HTMLInputElement>) => {
      owner.current.isBackward = owner.current.isBackward

      // hit backspace when last character is delimiter
      const postDelimiter = getPostDelimiter(
        owner.current.lastInputValue,
        owner.current.delimiter
      )

      if (owner.current.isBackward && postDelimiter) {
        owner.current.postDelimiterBackspace = postDelimiter
      } else {
        owner.current.postDelimiterBackspace = false
      }

      onInput(event.target.value)

      event.target.rawValue = getRawValue()
      event.target.value = owner.current.result

      onChange && onChange(event)
    }

    const updateValueState = () => {
      if (!inputRef.current) {
        setValue(owner.current.result)
        return
      }

      const oldValue = inputRef.current.value
      const newValue = owner.current.result

      owner.current.lastInputValue = newValue

      // ts check
      if (inputRef.current.selectionEnd === null) return

      inputRef.current.selectionEnd = getNextCursorPosition(
        inputRef.current.selectionEnd,
        oldValue,
        newValue,
        owner.current.delimiter
      )

      setValue(newValue)
      setCursorPosition(inputRef.current.selectionEnd)
    }

    const onInput = useCallback((value: any, fromProps?: boolean) => {
      const postDelimiterAfter = getPostDelimiter(
        value,
        owner.current.delimiter
      )
      if (
        !fromProps &&
        owner.current.postDelimiterBackspace &&
        !postDelimiterAfter
      ) {
        value = headStr(
          value,
          value.length - owner.current.postDelimiterBackspace.length
        )
      }

      // // strip delimiters
      value = stripDelimiters(value, owner.current.delimiter)
      // // strip prefix
      value = getPrefixStrippedValue(
        value,
        owner.current.prefix,
        owner.current.prefixLength,
        owner.current.result,
        owner.current.delimiter,
        owner.current.noImmediatePrefix,
        owner.current.tailPrefix,
        owner.current.signBeforePrefix
      )
      // // convert case
      value = owner.current.uppercase ? value.toUpperCase() : value
      value = owner.current.lowercase ? value.toLowerCase() : value
      // // prevent from showing prefix when no immediate option enabled with empty input value
      if (owner.current.prefix) {
        if (owner.current.tailPrefix) {
          value = value + owner.current.prefix
        } else {
          value = owner.current.prefix + value
        }
        // no blocks specified, no need to do formatting
        if (owner.current.blocksLength === 0) {
          owner.current.result = value
          updateValueState()
          return
        }
      }

      // // strip over length characters
      value =
        owner.current.maxLength > 0
          ? headStr(value, owner.current.maxLength)
          : value
      // apply blocks
      owner.current.result = getFormattedValue(
        value,
        owner.current.blocks,
        owner.current.blocksLength
      )

      updateValueState()
    }, [])

    const getRawValue = () => {
      if (owner.current.rawValueTrimPrefix) {
        owner.current.result = getPrefixStrippedValue(
          owner.current.result,
          owner.current.prefix,
          owner.current.prefixLength,
          owner.current.result,
          owner.current.delimiter,
          owner.current.noImmediatePrefix,
          owner.current.tailPrefix,
          owner.current.signBeforePrefix
        )
      }

      owner.current.result = stripDelimiters(
        owner.current.result,
        owner.current.delimiter
      )

      return owner.current.result
    }

    useEffect(() => {
      // no need to format
      if (owner.current.blocksLength === 0) {
        onInput(owner.current.initValue)
        return
      }

      owner.current.maxLength = getMaxLength(owner.current.blocks)

      if (owner.current.initValue) onInput(owner.current.initValue)
    }, [onInput])

    useEffect(() => {
      setSelection(inputRef.current, cursorPosition, owner.current.document)
    })

    useImperativeHandle(ref, () => ({
      clear: () => {
        onInput('')
      },
    }))

    return (
      <input
        data-testid={testId}
        type="text"
        ref={inputRef}
        value={value}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        onFocus={handleFocus}
        className={className}
      />
    )
  }
)

MaskedInput.displayName = 'MaskedInput'

export default MaskedInput
