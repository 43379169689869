import { VodVoteable, Voteable } from '@interfaces/voteable'
import { WelcomeBuff } from '@interfaces/welcomeBuff'
import { Announcement, VodAnnouncement } from '@interfaces/announcement'
import { StreamWinner } from '@interfaces/streamWinner'

export type Buff =
  | Voteable
  | VodVoteable
  | WelcomeBuff
  | Announcement
  | VodAnnouncement
  | StreamWinner

export enum BuffType {
  PREDICTION = 'PREDICTION',
  QUIZ = 'QUIZ',
  POLL = 'POLL',
  EMOJI = 'EMOJI',
  ANNOUNCEMENT = 'ANNOUNCEMENT',
  STAR = 'STAR',
  POPULAR_VOTE = 'POPULAR_VOTE',
  STREAM_WINNER = 'STREAM_WINNER',
}

// Buff types that show a resolved state
export const resolveableTypes = [
  BuffType.PREDICTION,
  BuffType.QUIZ,
  BuffType.POPULAR_VOTE,
]

/**
 * Buff types that show an expired state
 */
export const expiredTypes = [
  BuffType.POLL,
  BuffType.EMOJI,
  BuffType.STAR,
  BuffType.PREDICTION,
]
