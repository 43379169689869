import baseTheme from '@themes/base'
import { BuffSize } from '@interfaces/theme'

export const getDefaultTheme = () => {
  return baseTheme
}

export const getResponsiveVars = (size?: BuffSize) => {
  let fontSize
  let spacing
  let maxWidth
  switch (size) {
    case BuffSize.small:
      fontSize = '14px'
      spacing = '5px'
      maxWidth = '296px'
      break

    case BuffSize.medium:
      fontSize = '16px'
      spacing = '6px'
      maxWidth = '340px'
      break

    case BuffSize.large:
      fontSize = '20px'
      spacing = '9px'
      maxWidth = '500px'
      break

    case BuffSize.xSmall:
    default:
      fontSize = '12px'
      spacing = '4px'
      maxWidth = '256px'
      break
  }

  return {
    '--baseFontSize': fontSize,
    '--spacing': spacing,
    '--maxWidthXXS': maxWidth,
  }
}
