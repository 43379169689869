import { LanguageEnum } from './languages'

interface AssetDimensions {
  width: number
  height: number
}

export interface Asset {
  altText?: Partial<Record<LanguageEnum, string>>
  class?: AssetClass
  createdAt?: string
  description?: string
  dimensions?: AssetDimensions
  fileName: string
  id: string
  mimeType?: string
  publicUrl: string
  size?: number
  title?: string
  updatedAt?: string
}

export enum AssetClass {
  AnswerTile = 'answer-tile',
  VoteableSponsor = 'voteable-sponsor',
  LeaderboardSponsor = 'leaderboard-sponsor',
  AnnouncementTile = 'announcement-tile',
  Author = 'author',
  ProfilePicture = 'profile-picture',
  ProfilePictures = 'profile-pictures',
}

export interface AssetsResponse {
  assets: Asset[]
  pagination: {
    next: string
  }
}
