import { QueryClient } from 'react-query'
const ONE_SECOND_MS = 1000

export const LEADERBOARD_STANDING_STALE_TIME = 15 * ONE_SECOND_MS

export const LEADERBOARD_META_STALE_TIME = 60 * ONE_SECOND_MS

export const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
})
