import { ITheme } from '@interfaces/theme'

const base700 = '#323A46'
const baseTheme: ITheme = {
  variant: 'SportBuff',
  base: {
    50: '#F7F8F9',
    100: '#E1E4E8',
    200: '#D1D6DE',
    300: '#B8C0CC',
    400: '#A0ABBB',
    500: '#64748B',
    600: '#4B5768',
    700: base700,
    800: '#191D23',
    900: '#0D0F11',
  },
  label: {
    50: '#191D23',
    100: '#E7EAEE',
    200: '#FFFFFF',
    400: '#A0ABBB',
    500: '#717985',
    600: '#373F4A',
    700: '#091B32',
    800: '#FFFFFF',
    900: '#FFFFFF',
  },
  background: {
    start: '#F7F8F9CC',
    end: '#F7F8F9CC',
  },
  gradient: {
    start: '#173EAD',
    end: '#6183E4',
    background: base700,
  },
  timer: {
    start: '#10B981',
    middle: '#EC990B',
    end: '#D52525',
  },
  primary: '#1D4ED8',
  'primary-tint': '#6183E4',
  'primary-shade': '#1940B0',
  secondary: '#08274F',
  'secondary-tint': '#08274F',
  'secondary-shade': '#091B32',
  bits: '#f7ce46',
  'bits-tint': '#f9dd7e',
  'bits-shade': '#c6a538',
  gold: '#FED851',
  'gold-tint': '#FFE99C',
  'gold-shade': '#FED851',
  success: '#10B981',
  'success-tint': '#34D399',
  'success-shade': '#138761',
  warning: '#F59E0B',
  'warning-tint': '#FBBF24',
  'warning-shade': '#F59E0B',
  error: '#DC2626',
  'error-tint': '#EF4444',
  'error-shade': '#DC2626',
  white: '#FFFFFF',
  black: '#000000',
  'font-base': '"Open Sans", sans-serif',
  'font-secondary': '"Open Sans", sans-serif',
}

export default baseTheme
