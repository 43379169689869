/**
 * Creates a shadow dom container and puts it on the window.
 * This allows styles to be loaded into the shadow dom using style-loader
 */
const createShadowDOM = () => {
  const container = document.createElement('div')

  container.id = 'SportBuff-container'

  container.style.position = 'absolute'
  container.style.top = '0'
  container.style.right = '0'
  container.style.left = '0'
  container.style.bottom = '0'
  container.style.pointerEvents = 'none'

  container.attachShadow({ mode: 'open' })
  ;(window as any).SportBuffShadowDOMContainer = container
}

createShadowDOM()
