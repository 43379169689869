/**
 * When given a number it returns a number with its ordinal.
 * sufixes[(res - 20) % 10] handles the unique cases of numbers ending in 11, 12 or 13
 *
 * @param {number} number
 * @return {*}  {string}
 */
export function getNumberWithOrdinal(number: number): string {
  const sufixes = ['th', 'st', 'nd', 'rd']
  const res = number % 100
  return number + (sufixes[(res - 20) % 10] || sufixes[res] || sufixes[0])
}
