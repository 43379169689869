import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { RoutePaths } from '@interfaces/navigation'
import { friendsGroupIdRouteRegex } from '@utils/friendsGroups'
import { queryClient } from '@utils/reactQuery'
import { leaveGroupById } from '@services/requests/friendGroups'
import Confirmation from '@components/organisms/Confirmation'
import { METRIC_EVENTS } from '@interfaces/metrics'
import { sendEvent } from '@utils/metrics'
import { logError } from '@utils/log'
import { LeaveGroupProps } from './types'

const LeaveGroup = (props: LeaveGroupProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const regexResult = pathname.match(friendsGroupIdRouteRegex)
  const groupId = regexResult ? regexResult[1] : 'UNKNOWN'

  const handleLeaveGroup = async () => {
    const updateQueryData = () => {
      queryClient.invalidateQueries(['user-groups'])
    }
    try {
      await leaveGroupById(groupId)
      sendEvent(METRIC_EVENTS.privateGroupLeft, { id: groupId })
      updateQueryData()
      navigate(RoutePaths.FRIENDS)
    } catch (error) {
      logError(error)
    }
  }

  const handleClose = () => {
    navigate(`/friends/${groupId}/configuration`)
  }

  return (
    <Confirmation
      title={t('friends.leaveGroup')}
      text={t('friends.leaveGroupConfirmation')}
      closeText={t('general.cancel')}
      confirmText={t('friends.leaveGroup')}
      onClose={handleClose}
      onConfirm={handleLeaveGroup}
    />
  )
}

export default LeaveGroup
