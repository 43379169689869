import { useState, useCallback, useEffect } from 'react'
import storage from '@utils/storage'
import { missedBuffsStorageKey } from '@services/providers/BuffProvider'
import { BUFF_LOCAL_STORAGE_NAMESPACE } from '../../constants'

export const localStorageKey = `${BUFF_LOCAL_STORAGE_NAMESPACE}.snoozeStartDate`

export const useSnoozeHandler = () => {
  const [snoozeStartDate, setSnoozeStartDate] = useState<number | null>(() => {
    const startDateString = storage.getItem(localStorageKey)
    if (!startDateString) return null
    const startDateNum = parseInt(startDateString)
    if (Number.isNaN(startDateNum)) return null
    const startDate = new Date(startDateNum)
    const prefExpiryDate = new Date(startDateNum)

    prefExpiryDate.setDate(startDate.getDate() + 7)

    if (Date.now() > prefExpiryDate.getTime()) return null
    return startDateNum
  })

  const snooze = Boolean(snoozeStartDate)

  const updateSnooze = useCallback((snooze: boolean) => {
    setSnoozeStartDate(snooze ? Date.now() : null)
  }, [])

  // Update storage with snoozeStartDate
  useEffect(() => {
    if (snoozeStartDate) {
      storage.setItem(localStorageKey, snoozeStartDate.toString())
    } else {
      storage.removeItem(localStorageKey)
      storage.removeItem(missedBuffsStorageKey)
    }
  }, [snoozeStartDate])

  return {
    snooze,
    updateSnooze,
    snoozeStartDate,
  }
}
