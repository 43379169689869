import { WidgetConfig } from '@interfaces/widget'

export enum VideoPlayerEvent {
  /**
   * Emitted when video ui is hidden
   */
  HIDE_UI = 'hide-ui',

  /**
   * Emitted when video ui is shown
   */
  SHOW_UI = 'show-ui',

  /**
   * Emitted when the time updates on a VOD video
   */
  TIME_UPDATE = 'time-update',

  /**
   * Emitted when the time updates on a time sync video
   */
  TIME_SYNC_DATE_UPDATE = 'time-sync-date-update',

  /**
   * Emitted when the QR code should show from calling showQRCode function
   */
  SHOW_QR_CODE = 'show-qr-code',

  /**
   * Emitted when the toggleSnooze function is called
   */
  TOGGLE_SNOOZE = 'toggle-snooze',

  /**
   * Emitted when the custom sign in is completed
   */
  CUSTOM_SIGNUP_COMPLETED = 'custom-signup-completed',
}

type OtherVideoPlayerEvents = Omit<
  VideoPlayerEvent,
  VideoPlayerEvent.TIME_UPDATE | VideoPlayerEvent.TIME_SYNC_DATE_UPDATE
>

export interface VideoPlayer {
  on(
    event: VideoPlayerEvent.TIME_UPDATE,
    cb: (seconds: number) => Promise<void> | void
  ): void
  on(
    event: VideoPlayerEvent.TIME_SYNC_DATE_UPDATE,
    cb: (unixTimestamp: number) => Promise<void> | void
  ): void
  on(
    event: VideoPlayerEvent.CUSTOM_SIGNUP_COMPLETED,
    cb: (refreshToken: string) => Promise<void> | void
  ): void
  on(event: OtherVideoPlayerEvents, cb: () => void): void

  off(
    event: VideoPlayerEvent.TIME_UPDATE,
    cb: (seconds: number) => Promise<void> | void
  ): void
  off(
    event: VideoPlayerEvent.TIME_SYNC_DATE_UPDATE,
    cb: (dateTime: string) => Promise<void> | void
  ): void
  off(
    event: VideoPlayerEvent.CUSTOM_SIGNUP_COMPLETED,
    cb: (refreshToken: string) => Promise<void> | void
  ): void
  off(event: OtherVideoPlayerEvents, cb: () => void): void

  emit(event: VideoPlayerEvent, data?: any): void

  /**
   * Function called to append sportBuffContainer to the video
   */
  appendContainer: (sportBuffContainer: HTMLElement) => void

  /**
   * Function used to tear down. Should unbind any event listeners and
   * remove sportBuffContainer from video
   */
  destroy: () => void

  /**
   * Functions that will be exposed to sdk integrator
   */
  controls?: {
    hideUi: () => void
    showUi: () => void
    updateTimeVOD: (seconds: number) => void
    updateTimeTimeSync: (timestamp: number) => void
    showQRCode: (durationMS: number) => void
    toggleSnooze: () => void
    customSignupCompleted: (refreshToken: string) => void
  }
}

export type VideoPlayerHandler = (config: WidgetConfig) => Promise<VideoPlayer>
