import React, { useContext } from 'react'
import { useLocation, useNavigate, useOutlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ShareCode from '@components/molecules/ShareCode'
import GroupManagement from '@components/molecules/GroupManagement'
import Modal from '@components/molecules/Modal'
import { UserContext } from '@services/providers/UserProvider'
import { queryClient } from '@utils/reactQuery'
import { friendsGroupIdRouteRegex } from '@utils/friendsGroups'
import { RouteSubPaths } from '@interfaces/navigation'
import { useMutateFriendGroupLocked } from '@utils/hooks/useMutateFriendGroupLocked'
import { useGetFriendGroup } from '@utils/hooks/useGetFriendGroup'
import { logError } from '@utils/log'

const FriendsGroupSettings = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const outlet = useOutlet()
  const navigate = useNavigate()
  const { userId } = useContext(UserContext)

  const regexResult = pathname.match(friendsGroupIdRouteRegex)
  const groupId = regexResult ? regexResult[1] : 'UNKNOWN'

  const mutateLocked = useMutateFriendGroupLocked(groupId)

  const { data: friendGroupData } = useGetFriendGroup(groupId)

  const isOwner = userId ? friendGroupData?.owner === userId : false

  const onShowLeaveConfirmation = () => {
    navigate(`/friends/${groupId}/${RouteSubPaths.LEAVE}`)
  }

  const onShowDeleteConfirmation = () => {
    navigate(`/friends/${groupId}/${RouteSubPaths.DELETE}`)
  }

  const handleBack = () => {
    navigate(`/friends/${groupId}`)
  }

  const handleRotateCode = async () => {
    navigate(
      `/friends/${groupId}/${RouteSubPaths.CONFIGURATION}/${RouteSubPaths.ROTATE_CODE}`
    )
  }

  const handleLockToggle = async (locked: boolean) => {
    try {
      const newGroup = await mutateLocked.mutateAsync(locked)
      queryClient.setQueryData(['friends.info', groupId], () => {
        return newGroup
      })
    } catch (error) {
      logError(error)
    }
  }

  return (
    <Modal
      title={t('friends.groupSettingsTitle')}
      data-testid="friendsGroupSettings"
      onGoBack={handleBack}
      centerTitle
      routerChildren={outlet}
    >
      <div className="w-full">
        <ShareCode
          code={friendGroupData?.code}
          showRefreshCodeButton={isOwner}
          onRotateCode={handleRotateCode}
          isGroupLocked={friendGroupData?.locked}
        />
        <GroupManagement
          isOwner={isOwner}
          onDelete={onShowDeleteConfirmation}
          onLeave={onShowLeaveConfirmation}
          isLocked={friendGroupData?.locked}
          onLockToggle={handleLockToggle}
          isLoading={mutateLocked.isLoading}
        />
      </div>
    </Modal>
  )
}

export default FriendsGroupSettings
