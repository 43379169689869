export interface ISimpleRoutePath {
  path: string
  element: JSX.Element
}

export interface IRoutePath extends ISimpleRoutePath {
  children?: ISimpleRoutePath[]
}

export type TRoutes = IRoutePath[]

/**
 * Main routes to track the active selected tab in the floating panel
 */
export const RouteMainPaths = {
  FRIENDS: '/friends',
  SETTINGS: '/settings',
  PROFILE: '/profile',
  LEADERBOARDS: '/leaderboards',
  AUTH: '/auth',
  ASSETS: '/assets',
  PURCHASE: '/purchase',
} as const

/**
 * Subpaths might be shared between main routes
 */
export const RouteSubPaths = {
  JOIN: 'join',
  ERROR: 'error',
  LEAVE: 'leave',
  DELETE: 'delete',
  EXTENDED: 'extended',
  LANGUAGES: 'languages',
  PROFILE: 'profile',
  LINK_TWITCH: 'link-twitch',
  CONFIGURATION: 'configuration',
  EMAIL_LOGIN: 'email-login',
  PASSCODE: 'passcode',
  WELCOME: 'welcome',
  PRIVACY_POLICY: 'privacy-policy',
  TERMS_CONDITIONS: 'terms-conditions',
  CHAT: 'chat',
  ASSETS: 'assets',
  ROTATE_CODE: 'rotate-code',
} as const

/**
 * Full paths to import in the components and navigate to them
 */
export const RoutePaths = {
  //   Leaderboards paths
  LEADERBOARDS: RouteMainPaths.LEADERBOARDS,
  LEADERBOARDS_EXTENDED: `${RouteMainPaths.LEADERBOARDS}/${RouteSubPaths.EXTENDED}`,
  //   Friends paths
  FRIENDS: RouteMainPaths.FRIENDS,

  FRIENDS_EXTENDED: `${RouteMainPaths.FRIENDS}/${RouteSubPaths.EXTENDED}`,
  FRIENDS_CONFIGURATION: `${RouteMainPaths.FRIENDS}/${RouteSubPaths.CONFIGURATION}`,
  FRIENDS_LEAVE: `${RouteMainPaths.FRIENDS}/${RouteSubPaths.LEAVE}`,
  FRIENDS_ERROR: `${RouteMainPaths.FRIENDS}/${RouteSubPaths.ERROR}`,

  FRIENDS_WELCOME: `${RouteMainPaths.FRIENDS}/${RouteSubPaths.WELCOME}`,
  FRIENDS_JOIN: `${RouteMainPaths.FRIENDS}/${RouteSubPaths.WELCOME}/${RouteSubPaths.JOIN}`,
  // FRIENDS_LEADERBOARD: RouteMainPaths.FRIENDS + RouteSubPaths.

  //   Settings paths
  SETTINGS: RouteMainPaths.SETTINGS,
  SETTINGS_CONFIGURATION: `${RouteMainPaths.SETTINGS}/${RouteSubPaths.CONFIGURATION}`,
  SETTINGS_LINK_TWITCH: `${RouteMainPaths.SETTINGS}/${RouteSubPaths.LINK_TWITCH}`,
  SETTINGS_LANGUAGES: `${RouteMainPaths.SETTINGS}/${RouteSubPaths.LANGUAGES}`,
  SETTINGS_PRIVACY_POLICY: `${RouteMainPaths.SETTINGS}/${RouteSubPaths.PRIVACY_POLICY}`,
  SETTINGS_TERMS_CONDITIONS: `${RouteMainPaths.SETTINGS}/${RouteSubPaths.TERMS_CONDITIONS}`,

  //   Profile paths
  PROFILE: RouteMainPaths.PROFILE,
  PROFILE_ASSETS: `${RouteMainPaths.PROFILE}/${RouteSubPaths.ASSETS}`,

  // Auth
  AUTH: RouteMainPaths.AUTH,
  EMAIL_LOGIN: `${RouteMainPaths.AUTH}/${RouteSubPaths.EMAIL_LOGIN}`,
  PASSCODE: `${RouteMainPaths.AUTH}/${RouteSubPaths.EMAIL_LOGIN}/${RouteSubPaths.PASSCODE}`,

  WELCOME: `${RouteMainPaths.AUTH}/${RouteSubPaths.WELCOME}`,
  WELCOME_ASSETS: `${RouteMainPaths.AUTH}/${RouteSubPaths.WELCOME}/${RouteSubPaths.ASSETS}`,
  PRIVACY_POLICY_AUTH: `${RouteMainPaths.AUTH}/${RouteSubPaths.PRIVACY_POLICY}`,
  TERMS_CONDITIONS_AUTH: `${RouteMainPaths.AUTH}/${RouteSubPaths.TERMS_CONDITIONS}`,

  PURCHASE: RouteMainPaths.PURCHASE,
} as const
