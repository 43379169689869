import { ClientConfig } from '@interfaces/clientConfig'
import createClient, { Service } from '@utils/createClient'

interface ClientConfigProps {
  twitchClientName?: string
}

export const getClientConfig = async ({
  twitchClientName = '',
}: ClientConfigProps) => {
  const client = createClient(Service.CONFIG, twitchClientName)
  const data = await client.get<ClientConfig>(`/full`)

  return data.data
}
