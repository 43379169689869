import { FC } from 'react'
import { ConditionalWrapperProps } from './types'

const ConditionalWrapper: FC<ConditionalWrapperProps> = ({
  condition,
  wrapper,
  children,
}) => (condition ? wrapper(children) : children)

export default ConditionalWrapper
