import React, { useContext } from 'react'
import { AnimatePresence } from 'framer-motion'
import QRCode from '@components/organisms/QRCode'
import { ConfigContext } from '@services/providers/ConfigProvider'
import { ScreenContext } from '@services/providers/ScreenProvider'
import { BuffContext } from '@services/providers/BuffProvider'
import {
  useStreamConfigPosition,
  StreamConfigTarget,
} from '@utils/hooks/useStreamConfigPosition'
import { getSecondScreenAppUrl } from '@utils/url'
import { UserContext } from '@services/providers/UserProvider'
import { languagesMap } from '@utils/language'
import { QRCODE_URL } from '../../../constants'
import { QRCodeWrapperProps } from './types'

const QRCodeWrapper = (props: QRCodeWrapperProps) => {
  const { widgetConfig } = useContext(ConfigContext)
  const { userLanguage } = useContext(UserContext)
  const { screenDetails, tvConfig, isQRVisible } = useContext(ScreenContext)
  const { activeBuff } = useContext(BuffContext)
  const buffPositionStyles = useStreamConfigPosition(
    StreamConfigTarget.VOTEABLE
  )

  if (!widgetConfig.tvDeviceId || !screenDetails) return null

  const language = languagesMap[userLanguage]?.locale

  const descriptionLines = tvConfig?.qrCode?.text?.[language]

  const descriptions = descriptionLines
    ? [
        descriptionLines?.line1,
        descriptionLines?.line2,
        descriptionLines?.line3,
      ].filter((desc) => !!desc)
    : undefined

  const url = getSecondScreenAppUrl(
    tvConfig?.qrCode?.url || QRCODE_URL,
    screenDetails.screenId,
    widgetConfig.clientName
  )

  return (
    <AnimatePresence>
      {isQRVisible && !activeBuff ? (
        <QRCode
          style={buffPositionStyles}
          url={url}
          descriptions={descriptions}
        />
      ) : null}
    </AnimatePresence>
  )
}

export default QRCodeWrapper
