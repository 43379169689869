import React, { FC, useContext } from 'react'
import {
  useNavigate,
  useLocation,
  useParams,
  useOutlet,
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ReactComponent as DefaultAvatar } from '@assets/icons/avatar-circle.svg'
import Ranks from '@components/atoms/Ranks'
import Image from '@components/atoms/Image'
import { ReactComponent as BackIcon } from '@assets/icons/icon_filled_back.svg'
import { useProfileView } from '@utils/hooks/useProfileView'
import { ConfigContext } from '@services/providers/ConfigProvider'
import { UserContext } from '@services/providers/UserProvider'
import { getLeaderboardName } from '@utils/getLeaderboardName'
import { friendsGroupIdPrivateRouteRegex } from '@utils/friendsGroups'
import Button, { ButtonVariant } from '@components/atoms/Button'
import { RouteSubPaths } from '@interfaces/navigation'
import './LeaderboardItemExtended.styles.css'

const LeaderboardItemExtended: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const regexResult = location.pathname.match(friendsGroupIdPrivateRouteRegex)
  const groupId = regexResult ? regexResult[1] : undefined
  const { entityId, leaderboardId } = useParams()
  const outlet = useOutlet()

  const { userId, showDelete } = location.state || {}

  const { standing, leaderboardMeta } = useProfileView({
    groupId,
  })
  const { streamConfig } = useContext(ConfigContext)
  const { userLanguage } = useContext(UserContext)

  const leaderboardName = !!groupId
    ? t('leaderboard.yourLeaderboard')
    : getLeaderboardName(userLanguage, leaderboardMeta)

  const username = standing?.displayName ?? ''
  const image = standing?.profilePicture

  const handleDelete = () => {
    navigate(
      `/friends/${groupId}/${RouteSubPaths.EXTENDED}/${leaderboardId}/${entityId}/${RouteSubPaths.DELETE}`,
      { state: { userId, navigateBackCount: -2 } }
    )
  }

  return (
    <div className="bg-base-100 absolute inset-0 z-20 flex flex-col text-label-50">
      {/* Top section / image */}
      <div className="relative h-24">
        <div className="flex items-center m-3">
          <button
            className="bg-transparent h-6 w-6 modal__back-button"
            onClick={() => navigate(-1)}
            data-testid="leaderboardItemExtended__button-back"
            aria-label="Back"
          >
            <BackIcon fill="var(--color-base-800)" />
          </button>
          <span className="text-lg mx-1 font-bold capitalize">
            {t('general.leaderboard')}
          </span>
        </div>

        <div className="absolute bg-base-100 flex items-center justify-center -bottom-12 transform -translate-x-1/2 left-1/2 h-24 w-24 rounded-full">
          <div className="h-20 w-20 rounded-full overflow-hidden">
            <Image
              data-testid="leaderboardItemExtended__image"
              className="block h-full w-full object-cover"
              alt={t('leaderboard.imageAltText')}
              forceFallback={streamConfig?.config?.hideProfilePicture}
              src={image}
              fallbackComponent={
                <DefaultAvatar
                  className="block h-full w-full text-label-50"
                  data-testid="leaderboardItemExtended__default-image"
                />
              }
            />
          </div>
        </div>
      </div>

      {/* Bottom section / stats */}
      <div className="flex flex-col items-center pt-14 pb-2 bg-gradient-to-r from-background-start to-background-end rounded-t-sm flex-grow">
        <span className="text-lg font-bold mb-4 truncate w-full px-2 text-center">
          {username}
        </span>

        <div className="flex text-xs mt-2">
          <Ranks
            leaderboardName={leaderboardName}
            userRank={standing?.standing.position}
            userPercentage={standing?.standing.score}
            userPoints={standing?.standing?.totalPoints}
          />
        </div>

        {showDelete && (
          <Button
            data-testid="leaderboardItemExtended__button-delete"
            variant={ButtonVariant.THIN}
            className="mt-auto"
            onClick={handleDelete}
          >
            {t('friends.removeFromGroup')}
          </Button>
        )}
      </div>
      {outlet}
    </div>
  )
}

export default LeaderboardItemExtended
