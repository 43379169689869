import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { RoutePaths } from '@interfaces/navigation'
import { queryClient } from '@utils/reactQuery'
import { friendsGroupIdRouteRegex } from '@utils/friendsGroups'
import { FriendGroup } from '@interfaces/friendGroups'
import Confirmation from '@components/organisms/Confirmation'
import { deleteGroupById } from '@services/requests/friendGroups'
import { logError } from '@utils/log'
import { DeleteGroupProps } from './types'

const DeleteGroup = (props: DeleteGroupProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const regexResult = pathname.match(friendsGroupIdRouteRegex)
  const groupId = regexResult ? regexResult[1] : 'UNKNOWN'

  const handleDeleteGroup = async () => {
    try {
      await deleteGroupById(groupId)
      queryClient.invalidateQueries(['user-groups'])
      navigate(RoutePaths.FRIENDS_WELCOME)
    } catch (error) {
      logError(error)
    }
  }

  const handleClose = () => {
    navigate(`/friends/${groupId}/configuration`)
  }

  return (
    <Confirmation
      title={t('friends.deleteGroup')}
      text={t('friends.deleteGroupConfirmation')}
      closeText={t('general.cancel')}
      confirmText={t('general.delete')}
      onClose={handleClose}
      onConfirm={handleDeleteGroup}
    />
  )
}

export default DeleteGroup
