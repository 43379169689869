import { client } from '@services/requests/client'
import { StreamConfig } from '@interfaces/streamConfig'

export const getStreamConfig = async (
  streamId: string
): Promise<StreamConfig> => {
  const response = await client.get(`/stream-config/${streamId}`, {
    headers: {
      Accept: 'version=2.0.0',
    },
  })

  return response.data
}
