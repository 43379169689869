import { TFunction } from 'i18next'
import { object, string } from 'yup'

export const nameSchema = (t: TFunction) => {
  return object().shape({
    value: string()
      .required(t(`validation.minName`, { value: 3 }))
      .test(
        'minLength',
        t(`validation.minName`, { value: 3 }),
        (val?: string) => (val || '')?.trim()?.length >= 3
      )
      .test(
        'maxLength',
        t(`validation.maxName`, { value: 26 }),
        (val?: string) => (val || '')?.trim()?.length < 26
      ),
  })
}

export const emailSchema = (t: TFunction) => {
  return object().shape({
    value: string()
      .email(t(`validation.email`))
      .required(t(`validation.email`)),
  })
}
