import React, { FC, useRef, useEffect } from 'react'
import ReactCanvasConfetti from 'react-canvas-confetti'
import { useTranslation } from 'react-i18next'
import Image from '@components/atoms/Image'
import { ReactComponent as Banner } from '@assets/icons/stream-winners-banner.svg'
import { ReactComponent as PlayerRankStar } from '@assets/icons/stream-winners-rank-one-star.svg'
import { ReactComponent as PlayerRankTwo } from '@assets/icons/stream-winners-rank-two.svg'
import { ReactComponent as PlayerRankThree } from '@assets/icons/stream-winners-rank-three.svg'
import { ReactComponent as ProfilePlaceholder } from '@assets/icons/profile-placeholder.svg'
import { PlayerPositionProps } from './types'

const getRankIcon = (ranking?: number) => {
  if (ranking === 1) {
    return {
      Component: PlayerRankStar,
      width: 'w-4.5',
      bannerFillOne: '#81599C',
      bannerFillTwo: '#493A52',
      bannerFillThree: '#AA75CB',
    }
  }
  const width = 'w-4'
  if (ranking === 2) {
    return {
      Component: PlayerRankTwo,
      width,
      bannerFillOne: '#2B63A6',
      bannerFillTwo: '#2D4071',
      bannerFillThree: '#418ED6',
    }
  }
  if (ranking === 3) {
    return {
      Component: PlayerRankThree,
      width,
      bannerFillOne: '#983333',
      bannerFillTwo: '#552727',
      bannerFillThree: '#CE4444',
    }
  }
  return null
}

const PlayerPosition: FC<PlayerPositionProps> = ({
  ranking,
  name,
  points,
  profilePicture,
  displayLargeImage = false,
  displayConfetti = false,
  hideProfilePicture = false,
  className = '',
  'data-testid': testId = 'player-position',
}) => {
  const { t } = useTranslation()
  const confettiRef = useRef<any>()
  const borderMap = {
    1: 'border-[#f7c839]',
    2: 'border-[#cdcccc]',
    3: 'border-[#936013]',
    default: 'border-white',
  }

  const borderWidth =
    ranking !== undefined && ranking < 4 ? 'border-4' : 'border-2'

  const imageWidth = displayLargeImage ? 'w-16' : 'w-14'
  const imageBorderColor = borderMap.hasOwnProperty(ranking ?? 'NOT_DEFINED')
    ? // @ts-ignore
      borderMap[ranking]
    : borderMap.default

  useEffect(() => {
    if (!displayConfetti) return
    const fire = () => {
      const fireShot = (particleRatio: number, options: any) => {
        confettiRef.current &&
          confettiRef.current({
            ...options,
            origin: { y: 0.7 },
            particleCount: Math.floor(200 * particleRatio),
            scalar: 0.6,
          })
      }

      fireShot(0.25, {
        spread: 26,
        startVelocity: 55,
      })

      fireShot(0.2, {
        spread: 60,
      })

      fireShot(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8,
      })

      fireShot(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2,
      })

      fireShot(0.1, {
        spread: 120,
        startVelocity: 45,
      })

      setTimeout(() => {
        fireShot(0.25, {
          spread: 26,
          startVelocity: 55,
        })

        fireShot(0.2, {
          spread: 60,
        })

        fireShot(0.35, {
          spread: 100,
          decay: 0.91,
          scalar: 0.8,
        })

        fireShot(0.1, {
          spread: 120,
          startVelocity: 25,
          decay: 0.92,
          scalar: 1.2,
        })

        fireShot(0.1, {
          spread: 120,
          startVelocity: 45,
        })
      }, 4500)
    }

    fire()
  }, [confettiRef, displayConfetti])

  const pointsText =
    points > 1
      ? t('streamWinners.multiplePoints', { points })
      : t('streamWinners.singlePoint', { points })

  const iconSharedClasses =
    'absolute top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4'

  const rankIcon = getRankIcon(ranking)

  const bannerFillVars = !rankIcon
    ? undefined
    : {
        '--fill-1': rankIcon.bannerFillOne,
        '--fill-2': rankIcon.bannerFillTwo,
        '--fill-3': rankIcon.bannerFillThree,
      }

  return (
    <div
      className={`flex flex-col justify-center items-center px-2 ${className} text-xs relative`}
      id="stream-winner-player"
      data-testid={testId}
    >
      {displayConfetti && (
        <ReactCanvasConfetti
          className="absolute left-0 right-0 bottom-0 -top-1/4"
          refConfetti={(confetti) => {
            confettiRef.current = confetti
          }}
          style={{
            width: '100%',
            height: '125%',
            margin: 'auto',
          }}
        />
      )}
      <div
        className={`relative w-full ${imageWidth} before:pb-[100%] before:block`}
        data-testid={`${testId}__image-container`}
      >
        <div
          className={`absolute inset-0 overflow-hidden rounded-full ${borderWidth} ${imageBorderColor}`}
        >
          <Image
            src={profilePicture}
            className={`object-cover absolute inset-0`}
            forceFallback={hideProfilePicture}
            fallbackComponent={
              <ProfilePlaceholder className="absolute inset-0" />
            }
          />
        </div>

        {rankIcon && (
          <div className="absolute flex bottom-0 left-0 right-0 justify-center">
            <Banner className="w-8" style={bannerFillVars as any} />
            <rankIcon.Component
              className={`${rankIcon.width} ${iconSharedClasses}`}
            />
          </div>
        )}
      </div>
      <p
        className={`relative break-all w-full text-center text-label-50 font-secondary font-bold mt-1.5`}
      >
        <span className="line-clamp-1">{name}</span>
      </p>
      <span className={`px-1 text-label-50 text-center font-secondary`}>
        {pointsText}
      </span>
    </div>
  )
}

export default PlayerPosition
