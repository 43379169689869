import React, { FC } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { ModalPopupProps } from './types'

const childrenVariants = {
  animate: {
    y: '0%',
    transition: {
      duration: 0.3,
    },
  },
  initial: {
    y: '100%',
    transition: {
      duration: 0.3,
    },
  },
}

const backdropVariants = {
  animate: {
    opacity: 0.6,
    transition: {
      duration: 0.3,
    },
  },
  initial: {
    opacity: 0,
    transition: {
      duration: 0.3,
    },
  },
}

const ModalPopup: FC<ModalPopupProps> = ({
  children,
  isOpen,
  className,
  childrenClassName,
  backdropClassName,
  'data-testid': testId = 'modal-popup',
}) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className={`flex overflow-auto hide-scrollbar absolute inset-0 z-20 rounded-ts-md ${className}`}
          data-testid={testId}
        >
          <motion.div
            data-testid={`${testId}__backdrop`}
            variants={backdropVariants}
            initial="initial"
            animate="animate"
            exit="initial"
            className={`h-full w-full bg-base-50 z-30 ${backdropClassName}`}
          />
          <motion.div
            data-testid={`${testId}__children`}
            variants={childrenVariants}
            initial="initial"
            animate="animate"
            exit="initial"
            className={`flex bg-base-400 absolute bottom-0 z-40 w-full rounded-t ${childrenClassName}`}
          >
            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default ModalPopup
