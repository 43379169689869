import axios from 'axios'
import DOMPurify from 'dompurify'
import { useQuery } from 'react-query'

export enum HTMLFile {
  PRIVACY_POLICY = 'pp',
  TERMS_CONDITIONS = 'tos',
}

const fetchFile = async (url: string) => {
  const response = await axios.get<string>(url)
  const html = response.data

  const cleanHTML = DOMPurify.sanitize(html, {
    ALLOWED_TAGS: [
      'div',
      'p',
      'h1',
      'h2',
      'h3',
      'h4',
      'ol',
      'ul',
      'li',
      'span',
      'strong',
      'em',
      'table',
      'thead',
      'tbody',
      'tr',
      'th',
      'td',
    ],
  })

  return cleanHTML
}

export const useFetchHTML = (clientName: string, file: HTMLFile) => {
  return useQuery([file, clientName], async () => {
    const clientSpecificFile = `https://buffup-public.prod.buffup.net/assets/${clientName}/${file}.html`
    const fallbackFile = `https://buffup-public.prod.buffup.net/assets/${file}.html`

    return fetchFile(clientSpecificFile).catch((error) => {
      console.error(error)
      return fetchFile(fallbackFile)
    })
  })
}
