import React, { FC, MouseEvent, useContext } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { ConfigContext } from '@services/providers/ConfigProvider'
import Skeleton from '@components/atoms/Skeleton'
import Image from '@components/atoms/Image'
import { ReactComponent as PlayerRankStar } from '@assets/icons/stream-winners-rank-one-star.svg'
import { ReactComponent as PlayerRankTwo } from '@assets/icons/stream-winners-rank-two.svg'
import { ReactComponent as PlayerRankThree } from '@assets/icons/stream-winners-rank-three.svg'
import { ReactComponent as ProfilePlaceholder } from '@assets/icons/profile-placeholder.svg'
import { ReactComponent as TrashIcon } from '@assets/icons/icon_filled_trash.svg'
import { formatPercentage } from '@utils/leaderboard'
import { LeaderboardItemProps } from './types'

const LeaderboardItem: FC<LeaderboardItemProps> = ({
  id,
  rank,
  image,
  username,
  points,
  percentage,
  isActive,
  className,
  isGroupOwner = false,
  onClick,
  onDelete,
}) => {
  const { streamConfig } = useContext(ConfigContext)
  const { t } = useTranslation()
  const testId = 'leaderboard-item'

  const imageEl = (
    <Image
      data-testid="leaderboardItem__user-image"
      className="block w-full h-full object-cover"
      src={image}
      forceFallback={streamConfig?.config.hideProfilePicture}
      fallbackComponent={
        <ProfilePlaceholder
          className="block w-full h-full"
          data-testid="leaderboardItem__user-image"
        />
      }
    />
  )

  const handleClick = () => {
    onClick && onClick(id ?? '')
  }

  const handleDelete = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    if (!id) return
    onDelete?.(id)
  }

  let componentClassName =
    'flex relative justify-between items-center px-4 py-1 w-full text-sm leading-tight h-11.5'

  if (isActive) {
    componentClassName += ' rounded-md px-4'
  }

  if (className) {
    componentClassName += ` ${className}`
  }

  // const rankStarProps = undefined

  const renderRank = () => {
    if (rank === 1) {
      return (
        <PlayerRankStar
          className="w-6"
          data-testid="leaderboardItem__rank-1-icon"
        />
      )
    }
    if (rank === 2) {
      return (
        <PlayerRankTwo
          className="w-6"
          data-testid="leaderboardItem__rank-2-icon"
        />
      )
    }
    if (rank === 3) {
      return (
        <PlayerRankThree
          className="w-6"
          data-testid="leaderboardItem__rank-3-icon"
        />
      )
    }

    if (rank) {
      return <span className="font-semibold">{rank}</span>
    }

    if (percentage) {
      const formattedPercentage = formatPercentage(percentage)
      return (
        <p className="font-bold text-xs w-7 -ms-0.5 text-left">
          <Trans
            i18nKey="leaderboard.percentageText"
            values={{ percentage: formattedPercentage }}
            components={[
              <span key={0} className="block">
                $1
              </span>,
            ]}
          />
        </p>
      )
    }

    return (
      <p
        className="font-bold text-xs w-7 -ms-0.5 text-left"
        data-testid="leaderboardItem__no-position"
      >
        —
      </p>
    )
  }

  const renderUsername = () => {
    if (username == null) {
      return (
        <div
          className="flex-grow"
          data-testid="leaderboardItem__username-skeleton"
        >
          <Skeleton lineHeight="leading-tight" />
        </div>
      )
    }

    return (
      <span className={`truncate flex-shrink font-semibold`}>{username}</span>
    )
  }

  const renderPoints = () => {
    if (points == null) {
      return (
        <div data-testid="leaderboardItem__points-skeleton" className="w-16">
          <Skeleton lineHeight="leading-tight" />
        </div>
      )
    }

    const text =
      points === 1
        ? t('leaderboard.singlePoint', { points })
        : t('leaderboard.multiplePoints', { points })

    return <span className="opacity-80">{text}</span>
  }

  const listItemClassName = isActive ? 'sticky bottom-0 z-20' : ''
  const textColor = isActive ? 'text-label-800' : 'text-label-50'
  const widthClass = isGroupOwner ? 'w-12' : ''

  return (
    <li data-testid={testId} className={listItemClassName}>
      <button
        className={`p-0 border-none w-full cursor-pointer bg-transparent ${textColor}`}
        onClick={handleClick}
        type="button"
        data-testid={`${testId}__button`}
      >
        <div className={componentClassName}>
          {isActive && (
            <div
              className="absolute top-0 bottom-0 bg-primary center left-0 w-full"
              data-testid="leaderboard-item__active-bg"
            />
          )}
          <div className="text-center w-6 me-3 flex-shrink-0 text-base z-10">
            {renderRank()}
          </div>
          <div className={`z-10 truncate me-2 flex-grow text-left`}>
            {renderUsername()}
            <div className={`z-10 flex-shrink-0 text-xs mt-0.5`}>
              {renderPoints()}
            </div>
          </div>

          {/* Profile icon and delete button*/}
          <div
            className={`flex items-center gap-2 flex-shrink-0 z-10 ms-4 ${widthClass}`}
          >
            <div className="h-7 w-7 rounded-full overflow-hidden flex-shrink-0">
              {imageEl}
            </div>

            {onDelete && (
              <button onClick={handleDelete}>
                <TrashIcon className="w-4 h-4" fill="var(--color-label-50)" />
              </button>
            )}
          </div>
        </div>
      </button>
    </li>
  )
}

export default LeaderboardItem
