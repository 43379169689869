import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import Confirmation from '@components/organisms/Confirmation'
import { friendsGroupIdRouteRegex } from '@utils/friendsGroups'
import { ButtonBackground } from '@components/atoms/Button'
import { useMutateFriendGroupCode } from '@utils/hooks/useMutateFriendGroupCode'
import { queryClient } from '@utils/reactQuery'
import { RouteSubPaths } from '@interfaces/navigation'
import { logError } from '@utils/log'
import { RotateCodeProps } from './types'

const RotateCode = (props: RotateCodeProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const regexResult = pathname.match(friendsGroupIdRouteRegex)
  const friendGroupId = regexResult ? regexResult[1] : 'UNKNOWN'
  const mutateGroupCode = useMutateFriendGroupCode(friendGroupId)

  const handleClose = () => {
    navigate(`/friends/${friendGroupId}/${RouteSubPaths.CONFIGURATION}`)
  }

  const handleRefreshCode = async () => {
    try {
      const newGroup = await mutateGroupCode.mutateAsync()
      queryClient.setQueryData(['friends.info', friendGroupId], () => {
        return newGroup
      })

      navigate(`/friends/${friendGroupId}/${RouteSubPaths.CONFIGURATION}`)
    } catch (error) {
      logError(error)
    }
  }

  return (
    <Confirmation
      title={t('friends.refreshInviteCodeTitle')}
      text={t('friends.refreshInviteCodeDescription')}
      closeText={t('general.cancel')}
      confirmText={t('friends.refreshInviteCodeButton')}
      onClose={handleClose}
      onConfirm={handleRefreshCode}
      buttonBackground={ButtonBackground.PRIMARY}
      isLoading={mutateGroupCode.isLoading}
    />
  )
}

export default RotateCode
