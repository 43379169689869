import { useState, useRef, useCallback } from 'react'
import { ResizeObserver } from '@juggle/resize-observer'
import { useValueAsRef } from './useValueAsRef'

/**
 * A hook used to measure the dimensions of the component
 * @return {object} The width & height of element
 */
export function useMeasure() {
  const [bounds, set] = useState({ width: 0, height: 0 })
  const elementRef = useRef<HTMLElement | null>(null)
  const [ro] = useState(
    () =>
      new ResizeObserver(([entry]) => {
        const { inlineSize: width, blockSize: height } = entry.borderBoxSize[0]
        set({ width, height })
      })
  )

  const roRef = useValueAsRef(ro)

  const refCallback = useCallback(
    <T extends HTMLElement>(node: T | null) => {
      // Element has been removed
      if (node === null && elementRef.current !== null) {
        roRef.current.unobserve(elementRef.current)
      }

      // Element has changed
      if (node !== elementRef.current && elementRef.current !== null) {
        roRef.current.unobserve(elementRef.current)
      }

      if (node !== null) {
        roRef.current.observe(node)
      }

      elementRef.current = node
    },
    [roRef]
  )

  return { bounds, ref: refCallback, elementRef }
}
