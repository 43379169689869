import { setWith } from 'lodash'
import { ProviderTheme } from '@interfaces/theme'

const dynamicThemeMap: any = {
  backgroundEnd: '[background][end]',
  backgroundStart: '[background][start]',
  base50: '[base][50]',
  base100: '[base][100]',
  base200: '[base][200]',
  base300: '[base][300]',
  base400: '[base][400]',
  base500: '[base][500]',
  base600: '[base][600]',
  base700: '[base][700]',
  base800: '[base][800]',
  base900: '[base][900]',
  label50: '[label][50]',
  label100: '[label][100]',
  label200: '[label][200]',
  label400: '[label][400]',
  label500: '[label][500]',
  label600: '[label][600]',
  label700: '[label][700]',
  label800: '[label][800]',
  label900: '[label][900]',
  gradientStart: '[gradient][start]',
  gradientEnd: '[gradient][end]',
  gradientBackground: '[gradient][background]',
  primaryDefault: 'primary',
  primaryTint: 'primary-tint',
  primaryShade: 'primary-shade',
  secondaryDefault: 'secondary',
  secondaryTint: 'secondary-tint',
  secondaryShade: 'secondary-shade',
  bitsDefault: 'bits',
  bitsTint: 'bits-tint',
  bitsShade: 'bits-shade',
  successDefault: 'success',
  successTint: 'success-tint',
  successShade: 'success-shade',
  warningDefault: 'warning',
  warningTint: 'warning-tint',
  warningShade: 'warning-shade',
  errorDefault: 'error',
  errorTint: 'error-tint',
  errorShade: 'error-shade',
  goldDefault: 'gold',
  goldTint: 'gold-tint',
  goldShade: 'gold-shade',
  timeStart: '[timer][start]',
  timeMiddle: '[timer][middle]',
  timeEnd: '[timer][end]',
  shadeWhite: 'white',
  shadeBlack: 'black',
}

export const convertDynamicTheme = (theme: ProviderTheme) => {
  const mappedTheme: any = {}
  Object.keys(theme).forEach((key: any) => {
    // @ts-ignore
    setWith(mappedTheme, dynamicThemeMap[key], theme[key], Object)
  })
  return mappedTheme
}
