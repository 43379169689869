import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { ReactComponent as Checkmark } from '@assets/icons/checkmark.svg'
import { ReactComponent as Edit } from '@assets/icons/pencil.svg'
import { ReactComponent as Dismiss } from '@assets/icons/dismiss.svg'
import InfoText from '../InfoText'
import { EditInputProps } from './types'

const EditInput = ({
  value = '',
  disabled,
  error: errorProp,
  label,
  className,
  schema,
  loading,
  onChange,
  onSave,
  onIsEditing,
  ...inputProps
}: EditInputProps) => {
  const [isEditing, setIsEditing] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const [initialValue, setInitialValue] = useState(value)
  const [error, setError] = useState(errorProp)
  const inputRef = useRef<HTMLInputElement>(null)
  const timeoutRef = useRef<number>()

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  const handleChangeText = async (event: ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value
    onChange(val)
  }

  const handleEdit = () => {
    setIsEditing(true)
    setInitialValue(value)
    timeoutRef.current = window.setTimeout(() => {
      inputRef.current && inputRef.current.focus()
    }, 0)
  }

  const handleSave = () => {
    setIsEditing(false)
    setInitialValue(value)
    onSave(value?.toString(), initialValue?.toString())
  }

  const handleCancel = () => {
    onChange(initialValue.toString())
    setIsEditing(false)
  }

  useEffect(() => {
    const validate = async () => {
      try {
        schema &&
          (await schema.validateSync({
            value,
          }))
        setError(errorProp)
      } catch (error) {
        setError(isEditing ? error.message : errorProp)
      }
    }

    validate()
  }, [errorProp, value, isEditing])

  useEffect(() => {
    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current)
    }
  }, [])

  useEffect(() => {
    onIsEditing?.(isEditing)
  }, [isEditing, onIsEditing])

  const isValid = schema ? schema.isValidSync({ value }) : true

  const showError = (isEditing && !isValid) || !!errorProp

  const buttonClasses = `bg-secondary-tint rounded w-7 h-7 flex items-center justify-center flex-shrink-0 mx-1 ${
    loading && 'text-label-400'
  }`

  const iconFillColor = 'var(--color-label-800)'
  const disabledIconFillColor = 'var(--color-label-400)'

  const inputBorder = isFocused
    ? 'border-primary'
    : showError
    ? 'border-error'
    : 'border-transparent'

  return (
    <div
      data-testid="edit-input"
      className={`${className} flex flex-col w-full`}
    >
      {!!label && (
        <label
          data-testid="edit-input__label"
          className="text-label-50 text-sm font-semibold mb-1"
        >
          {label}
        </label>
      )}
      <div
        className={`flex flex-row items-center w-full rounded bg-[#fdf9fa1a] border-2 ${inputBorder}`}
      >
        <input
          ref={inputRef}
          value={value}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChangeText}
          disabled={disabled || !isEditing}
          data-testid="edit-input__input"
          className={`w-full h-9 !outline-[0px] text-label-50 text-base p-2 border-none !ring-transparent bg-transparent rounded`}
          {...inputProps}
        />
        {!disabled && (
          <>
            {!isEditing && (
              <button
                data-testid="edit-input__edit-button"
                disabled={loading}
                onClick={handleEdit}
                className={buttonClasses}
              >
                <Edit
                  width="70%"
                  fill={loading ? disabledIconFillColor : iconFillColor}
                />
              </button>
            )}

            {isEditing && (
              <>
                <button
                  disabled={!isValid}
                  data-testid="edit-input__save-button"
                  onClick={handleSave}
                  className={buttonClasses}
                >
                  <Checkmark
                    width="70%"
                    fill={!isValid ? disabledIconFillColor : iconFillColor}
                  />
                </button>
                <button
                  data-testid="edit-input__cancel-button"
                  onClick={handleCancel}
                  className={buttonClasses}
                >
                  <Dismiss width="70%" fill={iconFillColor} />
                </button>
              </>
            )}
          </>
        )}
      </div>
      {showError && <InfoText data-testid="edit-input__error" text={error} />}
    </div>
  )
}

export default EditInput
