import React, { createContext, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { RouteHistoryContextProps, RouteHistoryProviderProps } from './types'

export const RouteHistoryContext = createContext<RouteHistoryContextProps>(
  {} as RouteHistoryContextProps
)

export const RouteHistoryProvider = ({
  children,
}: RouteHistoryProviderProps) => {
  const [routePaths, setRoutePaths] = useState<string[]>([])
  const props: RouteHistoryContextProps = {
    routePaths,
  }
  const location = useLocation()

  useEffect(() => {
    setRoutePaths((paths) => {
      return [...paths, location.pathname]
    })
  }, [location.pathname])

  return (
    <RouteHistoryContext.Provider value={props}>
      {children}
    </RouteHistoryContext.Provider>
  )
}
