import React, { FC, useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { useTranslation, Trans } from 'react-i18next'
import Image from '@components/atoms/Image'
import { ReactComponent as PersonIcon } from '@assets/icons/person.svg'
import {
  getStatusStyles,
  statusesToDisplayStatsAfterTimeout,
  statusesToAlwaysDisplayStats,
  statusesToDisplayPoints,
} from '@utils/answerTile'
import PercentageBar, {
  PercentageBarVariant,
} from '@components/atoms/PercentageBar'
import { inactiveStatuses } from '@utils/answerTile'
import { HorizontalAnswerTileProps } from './types'

const convertBorderBgToVar = (borderBackground: string): any => {
  const name = borderBackground.replace('border-', '')
  return {
    '--horizontalAnswerTileBorder': `var(--color-${name}-rgb)`,
    '--horizontalAnswerTileArrow': `var(--color-${name})`,
  }
}

const HorizontalAnswerTile: FC<HorizontalAnswerTileProps> = ({
  text,
  image,
  bgColor,
  textColor,
  status,
  percentage,
  onClick,
  points,
  hasPoints,
  disabled = false,
  isLiveVotingEnabled,
  'data-testid': testId = 'horizontal-answer-tile',
}) => {
  const { t } = useTranslation()
  const statusStyles = getStatusStyles(t, status)

  const [showStatsOnTimeout, setShowStatsOnTimeout] = useState<boolean>(false)
  const [showTextOverlay, setShowTextOverlay] = useState<boolean>(
    Boolean(statusStyles.text)
  )
  const showStats =
    showStatsOnTimeout ||
    statusesToAlwaysDisplayStats.includes(status) ||
    isLiveVotingEnabled

  const cursorClass = disabled ? 'cursor-default' : 'cursor-pointer'
  const backgroundStyles = bgColor ? { background: bgColor } : undefined
  const textStyles = textColor ? { color: textColor } : undefined

  const borderClass = statusStyles.borderBg
  const insetBorder = convertBorderBgToVar(borderClass)

  const renderArrow = () => {
    if (showTextOverlay) return null

    if (statusStyles.displaySelectedArrow) {
      const showArrowOnRight = !statusStyles.Icon && !showStats
      if (showArrowOnRight) {
        return (
          <div className="absolute before:border-r-horizontalAnswerTileArrow flex items-center right-0 bottom-0 top-0 before:block before:border-t-answerTriangleY before:border-r-answerTriangleX before:border-b-answerTriangleY before:border-t-transparent before:border-b-transparent before:border-l-transparent" />
        )
      } else {
        return (
          <div className="absolute before:border-r-horizontalAnswerTileArrow flex items-center right-8.5 bottom-0 top-0 before:block before:border-t-answerTriangleY before:border-r-answerTriangleX before:border-b-answerTriangleY before:border-t-transparent before:border-b-transparent before:border-l-transparent" />
        )
      }
    }

    return null
  }

  const renderSidebar = () => {
    if (showTextOverlay) return null

    let barTextColor = statusStyles.textColour

    if (!barTextColor) {
      barTextColor = inactiveStatuses.includes(status)
        ? 'text-label-100'
        : undefined
    }

    if (showStats) {
      return (
        <PercentageBar
          displayBackground
          percentage={percentage ?? 0}
          barColor={statusStyles.progressBarColor ?? 'bg-primary'}
          barBackgroundVariant={statusStyles.barBackgroundVariant}
          variant={PercentageBarVariant.TALL}
          textColor={barTextColor}
        />
      )
    }

    if (statusStyles.Icon) {
      const { Icon, textColour, background } = statusStyles
      return (
        <div
          className={`${background} w-8.5 absolute right-0 top-0 bottom-0 flex items-center justify-center`}
        >
          <Icon className={`w-4 h-4 ${textColour}`} />
        </div>
      )
    }

    if (
      (Number(points) > 0 || hasPoints) &&
      statusesToDisplayPoints.includes(status)
    ) {
      const i18nKey =
        points !== 1
          ? 'answerTile.multiplePointsHorizontal'
          : 'answerTile.singlePointHorizontal'
      return (
        <div
          data-testid="answer-tile__points"
          className={`bg-base-800 text-label-100 w-8.5 text-xs absolute right-0 top-0 bottom-0 flex flex-col items-center justify-center font-bold font-secondary leading-none`}
        >
          <Trans
            i18nKey={i18nKey}
            components={[<span key={0}>{{ points: points ?? 0 }}</span>]}
          />
        </div>
      )
    }

    return null
  }

  const renderTextOverlay = () => {
    if (!statusStyles.text || !showTextOverlay) return null
    const { text, background, textColour } = statusStyles
    const variants = {
      initial: {
        x: '-100%',
        transition: { duration: 0.2, ease: 'easeInOut' },
      },
      animate: {
        x: '0%',
        transition: { ease: 'easeInOut', delay: 0.5 },
      },
      exit: {
        x: '100%',
        transition: { duration: 0.2, ease: 'easeInOut' },
      },
    }
    return (
      <motion.div
        variants={variants}
        initial="initial"
        animate="animate"
        exit="exit"
        className={`flex items-center justify-center absolute -inset-px text-xl font-semibold tracking-tighter ${background} ${textColour} z-10`}
      >
        <p>{text}</p>
      </motion.div>
    )
  }

  useEffect(() => {
    if (!statusStyles.text) return
    const timeout = window.setTimeout(() => {
      setShowTextOverlay(false)
    }, 3000)

    return () => {
      clearTimeout(timeout)
    }
  }, [statusStyles.text])

  useEffect(() => {
    if (!statusesToDisplayStatsAfterTimeout.includes(status)) {
      return
    }

    const timeout = window.setTimeout(() => {
      setShowStatsOnTimeout(true)
    }, 4000)

    return () => {
      clearTimeout(timeout)
      setShowStatsOnTimeout(false)
    }
  }, [status])

  // This is to offset inset the border so hardcoded as that is in pixels
  const buttonPadding = 'p-[2px]'

  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`text-left ${cursorClass} rounded-md overflow-hidden bg-base-200 ${buttonPadding} w-full ${borderClass} relative after:rounded-md after:shadow-horizontalAnswerTileBorder after:inset-0 after:absolute after:pointer-events-none`}
      style={{ ...backgroundStyles, ...insetBorder }}
      data-testid={testId}
    >
      <div className="grid grid-cols-shareCode pe-8.5 relative ps-10">
        <header className="flex-shrink-0 w-10 absolute left-0 bottom-0 top-0">
          <Image
            src={image}
            className={`object-scale-down w-full h-full absolute inset-0`}
            fallbackComponent={
              <div className="flex items-center justify-center absolute inset-0">
                <PersonIcon className={`text-base-700 w-6`} />
              </div>
            }
          />
        </header>
        <p className="flex flex-1 mx-1 items-center break-words min-w-0 min-h-horizontal-answer-tile">
          <span
            data-testid="answer-title"
            title={text}
            className="line-clamp-2 text-primaryText text-label-50 text-xs leading-tight font-semibold font-secondary"
            style={textStyles}
          >
            {text}
          </span>
        </p>
        {renderSidebar()}
        {renderArrow()}
        <AnimatePresence>{renderTextOverlay()}</AnimatePresence>
      </div>
    </button>
  )
}

export default HorizontalAnswerTile
