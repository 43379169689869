export enum LeaderboardActionTypes {
  ACTIVE_LEADERBOARD = 'active-leaderboard',
}

interface Action<T, P = undefined> {
  type: T
  payload?: P
}

interface ChangeActiveLeaderboardIdPayload {
  leaderboardId: string
}

type ChangeActiveLeaderboardId = Action<
  LeaderboardActionTypes.ACTIVE_LEADERBOARD,
  ChangeActiveLeaderboardIdPayload
>

export interface LeaderboardState {
  /**
   * Active leaderboard id
   */
  activeLeaderboardId: string
}

export type LeaderboardActions = ChangeActiveLeaderboardId

export const leaderboardReducer = (
  state: LeaderboardState,
  action: LeaderboardActions
): LeaderboardState => {
  switch (action.type) {
    case LeaderboardActionTypes.ACTIVE_LEADERBOARD:
      return {
        ...state,
        activeLeaderboardId: action.payload?.leaderboardId ?? '',
      }

    default:
      return state
  }
}

export const initialState: LeaderboardState = {
  activeLeaderboardId: '',
}
