import React, { FC } from 'react'
import { useMeasure } from '@utils/hooks/useMeasure'
import { WidgetLayout } from '@interfaces/widget'
import { IBuffSponsorContentProps } from './types'

const BuffSponsorContent: FC<IBuffSponsorContentProps> = ({
  linkUrl,
  imageUrl,
  widgetLayout,
  onSponsorClick,
}) => {
  const { bounds, ref } = useMeasure()

  const sharedProps = {
    href: linkUrl || undefined,
    target: '_blank',
    rel: 'noreferrer',
    onClick: onSponsorClick,
  }

  if (!imageUrl) return null
  if (widgetLayout === WidgetLayout.DESKTOP) {
    return (
      <a
        data-testid="sponsored-buff-content"
        ref={ref}
        className="flex-grow relative block flex-shrink-0 z-10 -mt-2 -mb-2 -me-12.5"
        style={{ width: `${bounds.height}px` }}
        {...sharedProps}
      >
        <img
          className="absolute w-full h-full inset-0 object-cover"
          src={imageUrl}
        />
      </a>
    )
  }

  if (
    widgetLayout === WidgetLayout.PORTRAIT_FULL_LEADERBOARD ||
    widgetLayout === WidgetLayout.PORTRAIT_TOGGLE_LEADERBOARD
  ) {
    return (
      <a
        data-testid="sponsored-buff-content--portrait"
        className="relative block w-26.5 h-26.5"
        {...sharedProps}
      >
        <img
          className="absolute w-full h-full inset-0 object-cover"
          src={imageUrl}
        />
      </a>
    )
  }

  return null
}

export default BuffSponsorContent
