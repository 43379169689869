import React from 'react'
import {
  RouteMainPaths,
  RoutePaths,
  RouteSubPaths,
} from '@interfaces/navigation'
import Error from '@components/views/Error'
import Settings from '@components/views/Settings'
import Leaderboard from '@components/views/Leaderboard'
import JoinFriends from '@components/views/JoinFriends'
import LeaveGroup from '@components/views/LeaveGroup'
import LeaveMyGroup from '@components/views/LeaveMyGroup'
import DeleteGroup from '@components/views/DeleteGroup'
import FriendsGroup from '@components/views/FriendsGroup'
import Languages from '@components/views/Languages'
import LinkTwitchProfile from '@components/views/LinkTwitchProfile'
import FriendsGroupSettings from '@components/views/FriendsGroupSettings'
import FriendsLeaderboard from '@components/organisms/FriendsLeaderboard'
import LeaderboardItemExtended from '@components/views/LeaderboardItemExtended'
import ChooseAuth from '@components/views/ChooseAuth'
import EmailLogin from '@components/views/EmailLogin'
import Passcode from '@components/views/Passcode'
import RotateCode from '@components/views/RotateCode'
import FriendsGroupWelcomeScreen from '@components/views/FriendsGroupWelcomeScreen'
import Profile from '@components/views/Profile'
import Welcome from '@components/views/Welcome'
import PrivacyPolicy from '@components/views/PrivacyPolicy'
import TermsOfService from '@components/views/TermsOfService'
import AssetSelection from '@components/views/AssetSelection'
import FriendsGroupChat from '@components/views/FriendsGroupChat'
import DeleteGroupUser from '@components/views/DeleteGroupUser'
import PurchaseSuccess from '@components/views/PurchaseSuccess'
import { RouteConfig } from '@interfaces/router'

// External routes sit over tabs navigation (on top of tab nav buttons)
export const getExternalRoutesConfig = (): RouteConfig[] => {
  return [
    {
      path: RoutePaths.PURCHASE,
      element: <PurchaseSuccess />,
    },
    {
      path: `${RoutePaths.AUTH}/*`,
      element: <ChooseAuth />,
      children: [
        {
          path: `${RouteSubPaths.EMAIL_LOGIN}/*`,
          exact: false,
          element: <EmailLogin />,
          children: [
            {
              path: RouteSubPaths.PASSCODE,
              element: <Passcode />,
            },
          ],
        },
        {
          path: `${RouteSubPaths.WELCOME}/*`,
          exact: false,
          element: <Welcome />,
          children: [
            {
              path: RouteSubPaths.ASSETS,
              element: <AssetSelection />,
            },
          ],
        },
        {
          path: RouteSubPaths.PRIVACY_POLICY,
          element: <PrivacyPolicy />,
        },
        { path: RouteSubPaths.TERMS_CONDITIONS, element: <TermsOfService /> },
      ],
    },
  ]
}

export const getRoutesConfig = (): RouteConfig[] => {
  return [
    {
      path: `${RouteMainPaths.LEADERBOARDS}/*`,
      element: <Leaderboard />,
      children: [
        {
          path: `${RouteSubPaths.EXTENDED}/:leaderboardId/:entityId`,
          element: <LeaderboardItemExtended />,
        },
      ],
    },
    {
      path: `${RouteMainPaths.FRIENDS}/*`,
      element: <FriendsGroup />,
      children: [
        {
          path: `${RouteSubPaths.WELCOME}/*`,
          exact: false,
          element: <FriendsGroupWelcomeScreen />,
          children: [
            {
              path: RouteSubPaths.JOIN,
              element: <JoinFriends />,
              exact: false,
              children: [
                {
                  path: RouteSubPaths.ERROR,
                  element: <Error />,
                },
              ],
            },
            {
              path: `:friendGroupId/${RouteSubPaths.ROTATE_CODE}`,
              element: <RotateCode />,
            },
            {
              path: `:friendGroupId/${RouteSubPaths.LEAVE}`,
              element: <LeaveMyGroup />,
            },
          ],
        },
        {
          path: ':friendGroupId',
          exact: false,
          element: <FriendsLeaderboard />,
          children: [
            {
              path: RouteSubPaths.LEAVE,
              // TODO: Replace with new friends group leave confirmation component
              element: <LeaveGroup />,
            },
            {
              path: RouteSubPaths.DELETE,
              element: <DeleteGroup />,
            },
            {
              path: RouteSubPaths.CONFIGURATION,
              exact: false,
              element: <FriendsGroupSettings />,
              children: [
                {
                  path: RouteSubPaths.ROTATE_CODE,
                  element: <RotateCode />,
                },
              ],
            },
            {
              path: `${RouteSubPaths.EXTENDED}/:leaderboardId/:entityId/*`,
              element: <LeaderboardItemExtended />,
              exact: false,
              children: [
                {
                  path: RouteSubPaths.DELETE,
                  element: <DeleteGroupUser />,
                },
              ],
            },
            {
              path: `${RouteSubPaths.DELETE}/:leaderboardId/:entityId`,
              element: <DeleteGroupUser />,
            },
            {
              path: RouteSubPaths.CHAT,
              element: <FriendsGroupChat />,
            },
          ],
        },
      ],
    },
    {
      path: `${RouteSubPaths.PROFILE}/*`,
      element: <Profile />,
      children: [
        {
          path: RouteSubPaths.ASSETS,
          element: <AssetSelection />,
        },
      ],
    },
    {
      path: `${RouteMainPaths.SETTINGS}/*`,
      element: <Settings />,
      children: [
        {
          path: RouteSubPaths.LANGUAGES,
          element: <Languages />,
        },
        {
          path: RouteSubPaths.LINK_TWITCH,
          element: <LinkTwitchProfile />,
        },
        {
          path: RouteSubPaths.PRIVACY_POLICY,
          element: <PrivacyPolicy />,
        },
        { path: RouteSubPaths.TERMS_CONDITIONS, element: <TermsOfService /> },
      ],
    },
  ]
}
