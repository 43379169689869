import React, { FC, useState, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { AnswerTileStatus } from '@interfaces/answerTile'
import { ReactComponent as PersonIcon } from '@assets/icons/person.svg'
import PercentageBar from '@components/atoms/PercentageBar'
import Image from '@components/atoms/Image'
import {
  getStatusStyles,
  statusesToAlwaysDisplayStats,
  statusesToDisplayStatsAfterTimeout,
  inactiveStatuses,
} from '@utils/answerTile'
import { VerticalAnswerTileProps } from './types'
import { ANSWER_SHOW_STATE_MS } from '../../../constants'

const convertBorderBgToVar = (borderBackground: string): any => {
  const name = borderBackground.replace('border-', '')
  return {
    '--verticalAnswerTileBorder': `var(--color-${name})`,
  }
}

const statusesToExpand = [
  AnswerTileStatus.SELECTED,
  AnswerTileStatus.CORRECT,
  AnswerTileStatus.SELECTED_CORRECT,
  AnswerTileStatus.WINNER,
  AnswerTileStatus.SELECTED_WINNER,
  AnswerTileStatus.YOUR_PICK,
  AnswerTileStatus.SELECTED_VOTE_PERCENTAGE,
  AnswerTileStatus.WRONG,
]

const VerticalAnswerTile: FC<VerticalAnswerTileProps> = ({
  disabled,
  onClick,
  status,
  text,
  image,
  percentage,
  textColor,
  bgColor,
  points,
  hasPoints,
  isLiveVotingEnabled,
  'data-testid': testId = 'vertical-answer-tile',
}) => {
  const { t } = useTranslation()
  const [showStatsOnTimeout, setShowStatsOnTimeout] = useState<boolean>(false)
  const showStats =
    showStatsOnTimeout ||
    statusesToAlwaysDisplayStats.includes(status) ||
    isLiveVotingEnabled
  const statusStyles = getStatusStyles(t, status)

  const transformClass = statusesToExpand.includes(status)
    ? 'transform scale-[1.05]'
    : ''

  const cursorClass = disabled ? 'cursor-default' : 'cursor-pointer'
  const backgroundStyles = bgColor ? { background: bgColor } : undefined
  const textStyles = textColor ? { color: textColor } : undefined

  const opacity = inactiveStatuses.includes(status) ? 'opacity-40' : ''

  const insetBorderVar = convertBorderBgToVar(statusStyles.borderBg)

  const renderFooter = () => {
    if (showStats) {
      let barTextColor = statusStyles.textColour

      if (!barTextColor) {
        barTextColor = inactiveStatuses.includes(status)
          ? 'text-label-100'
          : undefined
      }

      const displayBackground = !inactiveStatuses.includes(status)
      const footerBackground = displayBackground ? '' : 'bg-base-800'
      const percentageBarClassName = displayBackground ? '' : 'opacity-40'

      return (
        <footer
          className={`-mb-px -mx-px ${footerBackground} rounded-b overflow-hidden`}
        >
          <PercentageBar
            percentage={percentage ?? 0}
            barColor={statusStyles.progressBarColor ?? 'bg-primary'}
            textColor={barTextColor}
            displayBackground={displayBackground}
            barBackgroundVariant={statusStyles.barBackgroundVariant}
            className={percentageBarClassName}
          />
        </footer>
      )
    }

    let footerText: JSX.Element | string = ''
    let footerBackground
    let footerTextColour

    if (statusStyles.text) {
      footerText = statusStyles.text
      footerBackground = statusStyles.background
      footerTextColour = statusStyles.textColour
    } else {
      if (Number(points) > 0 || hasPoints) {
        const i18nKey =
          points !== 1
            ? 'answerTile.multiplePointsHorizontal'
            : 'answerTile.singlePointHorizontal'
        footerText = (
          <Trans
            i18nKey={i18nKey}
            components={[
              <span key={0} className="text-[calc(var(--spacing)*2)]">
                {{ points: points ?? 0 }}
              </span>,
            ]}
          />
        )
      }

      footerBackground = 'bg-base-800'
      footerTextColour = 'text-label-100'
    }

    if (footerBackground && footerTextColour) {
      return (
        <footer
          className={`flex items-center justify-center text-center h-4.5 -mx-px -mb-px rounded-b`}
        >
          <span
            className={`text-[calc(var(--spacing)*2.5)] font-bold font-secondary ${footerTextColour} ${opacity} leading-none tracking-tighter`}
          >
            {footerText}
          </span>
        </footer>
      )
    }

    return null
  }

  useEffect(() => {
    if (!statusesToDisplayStatsAfterTimeout.includes(status)) {
      return
    }

    const timeout = window.setTimeout(() => {
      setShowStatsOnTimeout(true)
    }, ANSWER_SHOW_STATE_MS)

    return () => {
      clearTimeout(timeout)
      setShowStatsOnTimeout(false)
    }
  }, [status])

  // This is to offset inset the border so hardcoded as that is in pixels
  const buttonPadding = 'p-[2px]'

  return (
    <button
      type="button"
      disabled={disabled}
      className={`flex flex-col focus:outline-none items-stretch relative w-full h-full bg-base-800 ${statusStyles.background} ${buttonPadding} rounded overflow-hidden ${transformClass} ${cursorClass} after:rounded after:shadow-verticalAnswerTileBorder after:inset-0 after:absolute after:pointer-events-none`}
      onClick={onClick}
      data-testid={testId}
      style={insetBorderVar}
    >
      <div
        className={`relative flex flex-col flex-1 flex-shrink-0 w-full bg-base-200`}
      >
        {statusStyles.displaySelectedArrow && (
          <div className="absolute z-20 left-2/4 -translate-x-2/4 flex items-center top-0 before:block before:border-t-verticalAnswerTileTriangleY before:border-r-verticalAnswerTileTriangleX before:border-l-answerTriangleX before:border-t-verticalAnswerTile before:border-r-transparent before:border-b-transparent before:border-l-transparent" />
        )}
        <header className="relative flex justify-center items-center overflow-hidden before:block before:pb-[72%]">
          <Image
            src={image}
            className={`object-cover w-full h-full absolute inset-0 z-10 ${opacity}`}
            fallbackComponent={
              <PersonIcon className={`text-base-700 w-9 ${opacity}`} />
            }
          />
        </header>
        <div
          className="absolute -rotate-45 h-[50%] w-[170%]"
          style={{
            top: '-5%',
            left: '-65%',
            background:
              'linear-gradient(269.93deg, rgba(var(--color-white-rgb), 0) 5%, rgba(var(--color-white-rgb), 0.168) 65%)',
          }}
        />
        <div
          data-testid={`${testId}_text-container`}
          style={backgroundStyles}
          className="relative flex flex-1 p-1 text-center items-center justify-center min-h-[calc(var(--spacing)*10)] bg-base-200"
        >
          <div
            className="absolute inset-0 w-[100%] h-[100%]"
            style={{
              background: 'rgba(var(--color-white-rgb), 0.12)',
            }}
          />
          <span
            data-testid="answer-title"
            title={text}
            className={`line-clamp-3 text-primaryText text-label-50 font-secondary font-semibold text-xs leading-tight tracking-tight break-words ${opacity}`}
            style={textStyles}
          >
            {text}
          </span>
        </div>
      </div>
      {renderFooter()}
    </button>
  )
}

export default VerticalAnswerTile
