import React, { FC, useContext } from 'react'
import { useInternalDeepLink } from '@utils/hooks/useInternalDeepLink'
import { BuffContext } from '@services/providers/BuffProvider'
import { BuffActionTypes } from '@services/providers/BuffProvider/state/buffReducer'
import { IAnnouncementProps } from './types'

const Announcement: FC<IAnnouncementProps> = ({ buff, activeLanguage }) => {
  const { dispatch } = useContext(BuffContext)
  const { isInternal, onHandleLink } = useInternalDeepLink()

  const { content } = buff

  const announcementContent = content?.localisations?.[activeLanguage]
  if (!announcementContent) return null

  const onInternalAnnouncementClick = () => {
    if (!announcementContent.linkUrl) return

    onHandleLink(announcementContent.linkUrl)
    dispatch({
      type: BuffActionTypes.ADD_ANNOUNCEMENT_TO_CLICKED_LIST,
      payload: { id: buff?.announcementId },
    })
  }

  const onExternalAnnouncementClick = () => {
    if (!announcementContent.linkUrl) return

    dispatch({
      type: BuffActionTypes.ADD_ANNOUNCEMENT_TO_CLICKED_LIST,
      payload: { id: buff?.announcementId },
    })
  }

  /**
   * announcementContent possibly can be undefined when used as PreviewComponent on dashboard
   * TODO: Update types to reflect this
   */
  const showAnnouncementChildren =
    announcementContent?.buttonText && announcementContent?.linkUrl

  const isInternalLink =
    announcementContent.linkUrl && isInternal(announcementContent.linkUrl)

  return showAnnouncementChildren ? (
    <div data-testid="announcement" className="flex h-full">
      {isInternalLink ? (
        <button
          data-testid="announcement__button"
          onClick={onInternalAnnouncementClick}
          className={`bg-secondary w-full z-10 px-1 py-1.5 rounded-md cursor-pointer`}
        >
          <span className="line-clamp-1 break-all text-label-800 font-bold">
            {announcementContent.buttonText}
          </span>
        </button>
      ) : (
        <a
          className={`inline-block text-center bg-secondary w-full z-10 px-1 py-1.5 rounded-md cursor-pointer`}
          data-testid="announcement__button"
          href={announcementContent.linkUrl}
          onClick={onExternalAnnouncementClick}
          target="_blank"
          rel="noreferrer"
        >
          <span className="line-clamp-1 break-all text-label-800 font-bold">
            {announcementContent.buttonText}
          </span>
        </a>
      )}
    </div>
  ) : null
}

export default Announcement
