import React, { FC } from 'react'

import { ISwitchProps } from './types'

import './Switch.css'

const Switch: FC<ISwitchProps> = ({
  isChecked,
  text,
  onToggle,
  onClick,
  readOnly = false,
  disabled = false,
  'data-testid': testId = 'switch',
  'data-active': dataActive = false,
}) => {
  return (
    <label className="switch text-sm">
      {text}
      <input
        readOnly={readOnly}
        onChange={onToggle}
        onClick={onClick}
        type="checkbox"
        checked={isChecked}
        disabled={disabled}
        className="appearance-none focus:outline-none focus:ring-0 focus:ring-offset-0"
        data-testid={testId}
        data-active={dataActive}
      />
    </label>
  )
}

export default Switch
