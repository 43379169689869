import { isLiveVotingLifecycle, isVoteable } from '@utils/buff'
import { Buff } from '@interfaces/buff'
import { PubSubEventType } from '@interfaces/pubSub'

interface UseLiveVotingReturn {
  useLiveVotingEnabled: boolean
}

interface UseLiveVotingArgs {
  buff?: Buff
  pubSubEventType?: PubSubEventType
  enabled?: boolean
}

export const useLiveVoting = ({
  buff,
  pubSubEventType,
  enabled,
}: UseLiveVotingArgs): UseLiveVotingReturn => {
  const useLiveVotingEnabled =
    !!enabled &&
    buff &&
    isVoteable(buff) &&
    isLiveVotingLifecycle(buff.lifecycle, buff.answerInterface) &&
    pubSubEventType === PubSubEventType.VOTEABLE_OPEN

  return {
    useLiveVotingEnabled: !!useLiveVotingEnabled,
  }
}
