export enum DeepLinkingURL {
  twitchLink = 'sportbuff://twitch',
  email = 'sportbuff://email',
  friends = 'sportbuff://friends',
  language = 'sportbuff://language',
  mute = 'sportbuff://mute',
  unmute = 'sportbuff://unmute',
  snooze = 'sportbuff://snooze',
  login = 'sportbuff://login',
}
