import React, {
  FC,
  ReactElement,
  useCallback,
  Fragment,
  ElementType,
  forwardRef,
  ForwardedRef,
  PropsWithChildren,
  HTMLProps,
  MouseEvent,
} from 'react'
import { Props, TabsProps } from './types'

/**
 * Tabs component
 * @param {Props<TTag, TabsProps>} props
 * @return {ReactElement}
 */
const Tabs = <TTag extends ElementType = typeof Fragment>(
  props: Props<TTag, TabsProps>
): ReactElement => {
  const { activeTab, onTabChange, children } = props
  const handleClick = useCallback(
    (tab) => () => onTabChange(tab),
    [onTabChange]
  )

  const renderTabList = useCallback(
    (child) => {
      let tab = 0

      return React.cloneElement(child, {
        children: React.Children.map(child?.props?.children, (childTab) => {
          if (childTab.type.displayName === 'Tab') {
            const _onClick = handleClick(tab)
            tab++
            return React.cloneElement(childTab, { _onClick })
          }

          return childTab
        }),
      })
    },
    [handleClick]
  )

  const renderChildren = useCallback(
    (children) => {
      let panel = 0

      return React.Children.map(children?.props?.children, (child) => {
        if (child.type.displayName === 'TabList') {
          return renderTabList(child)
        }

        if (child.type.displayName === 'TabPanel') {
          const _isActive = panel === activeTab
          panel++
          return _isActive ? React.cloneElement(child) : null
        }

        return child
      })
    },
    [activeTab, renderTabList]
  )

  const resolvedChildren = (
    typeof children === 'function'
      ? children({ ...props, selectedTab: activeTab })
      : children
  ) as ReactElement | ReactElement[]

  return (
    <div className={props.className}>{renderChildren(resolvedChildren)}</div>
  )
}

const TabList = forwardRef<
  HTMLUListElement,
  PropsWithChildren<HTMLProps<HTMLUListElement>>
>(({ children, className, ...rest }, ref: ForwardedRef<HTMLUListElement>) => {
  return (
    <ul {...rest} ref={ref} className={className}>
      {children}
    </ul>
  )
})

interface TabProps extends HTMLProps<HTMLLIElement> {
  _onClick?: () => void
}

const Tab = forwardRef<HTMLLIElement, PropsWithChildren<TabProps>>(
  (
    { _onClick, onClick, children, className, disabled = false, ...rest },
    ref: ForwardedRef<HTMLLIElement>
  ) => {
    const handleClick = (e: MouseEvent<HTMLLIElement>) => {
      if (disabled) return
      _onClick && _onClick()
      onClick && onClick(e)
    }

    return (
      <li {...rest} ref={ref} className={className} onClick={handleClick}>
        {children}
      </li>
    )
  }
)

interface TabPanelProps {
  className?: string
}
const TabPanel: FC<TabPanelProps> = ({ children, className }) => (
  <div className={className}>{children}</div>
)

TabPanel.displayName = 'TabPanel'
TabList.displayName = 'TabList'
Tab.displayName = 'Tab'

Tabs.List = TabList
Tabs.Tab = Tab
Tabs.Panel = TabPanel

export default Tabs
