export const secondsToTime = (time: number) => {
  const h = Math.floor(time / 3600)
    .toString()
    .padStart(2, '0')
  const m = Math.floor((time % 3600) / 60)
    .toString()
    .padStart(2, '0')
  const s = Math.floor(time % 60)
    .toString()
    .padStart(2, '0')

  return time >= 3600 ? `${h}:${m}:${s}` : `${m}:${s}`
}
