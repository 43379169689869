import { useRef, Dispatch, useContext } from 'react'
import { VodVoteable, Voteable } from '@interfaces/voteable'
import { BuffActionTypes, Actions } from '../state/buffReducer'
import { Buff } from '@interfaces/buff'
import { UserContext } from '@services/providers/UserProvider'
import { UseBuffQueueReturn } from '@utils/hooks/useBuffQueue'
import { ANSWER_TIMEOUT, getBuffId } from '@utils/buff'
import { StreamContext } from '@services/providers/StreamProvider'
import { logError } from '@utils/log'
import { ConfigContext } from '@services/providers/ConfigProvider'
import { useLiveVoting } from '@utils/hooks/useLiveVoting'
import { PubSubEventType } from '@interfaces/pubSub'

interface UseCastVoteableVoteArgs {
  queueHelpers: UseBuffQueueReturn
  activeBuff?: Buff
  removeActiveBuff: (delay: number) => void
  dispatch: Dispatch<Actions>
}

interface UseCastVoteableVoteObj {
  voteBuff: (voteable: Voteable | VodVoteable, answerId: string) => void
}

export const useCastVoteableVote = ({
  activeBuff,
  dispatch,
  removeActiveBuff,
  queueHelpers,
}: UseCastVoteableVoteArgs): UseCastVoteableVoteObj => {
  const { userId } = useContext(UserContext)
  const { streamConfig } = useContext(ConfigContext)
  const { games } = useContext(StreamContext)
  const { castVote, activeQueueItem } = queueHelpers

  const voteTimeoutRef = useRef<number>(0)
  const voteAnswerIdRef = useRef<string>()
  const voteChangesRef = useRef<number>(0)
  const answerSentRef = useRef<Record<string, boolean>>({})

  const { useLiveVotingEnabled } = useLiveVoting({
    buff: activeBuff,
    pubSubEventType: PubSubEventType.VOTEABLE_OPEN,
    enabled: streamConfig?.config?.useLiveVoting,
  })

  /**
   * A function that sends an answer for buff
   *
   * @param {Voteable | VodVoteable} voteable voteable
   * @param {string} answerId selected answer id
   */
  const voteBuff = (voteable: Voteable | VodVoteable, answerId: string) => {
    const buffId = getBuffId(voteable)
    const gameId = games?.[0].gameId
    const actuallyVoteForBuff = (answerId: string) => {
      if (!userId) {
        clearTimeout(voteTimeoutRef.current)
        voteTimeoutRef.current = 0
        removeActiveBuff(500)
        return
      }

      return castVote(gameId, buffId, answerId, userId ?? '')
        .catch((error) => {
          logError(error)
        })
        .finally(() => {
          answerSentRef.current[buffId] = true
          clearTimeout(voteTimeoutRef.current)
          voteTimeoutRef.current = 0
          if (!useLiveVotingEnabled) {
            removeActiveBuff(3000)
          }
        })
    }

    voteAnswerIdRef.current = answerId

    if (answerSentRef.current[buffId] === true) return

    if (!voteTimeoutRef.current) {
      voteChangesRef.current = 0
      voteTimeoutRef.current = window.setTimeout(() => {
        if (!activeBuff || !voteAnswerIdRef.current) return
        actuallyVoteForBuff(voteAnswerIdRef.current)
      }, ANSWER_TIMEOUT)
    } else {
      voteChangesRef.current = voteChangesRef.current + 1
    }

    if (activeQueueItem && !activeQueueItem.votedAt) {
      activeQueueItem.votedAt = Date.now()
    }

    dispatch({
      type: BuffActionTypes.SELECT_ANSWER,
      payload: [
        {
          answerId,
          voteableId: buffId,
        },
      ],
    })
  }

  return { voteBuff }
}
