import React, { FC, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { motion } from 'framer-motion'
import { RoutePaths } from '@interfaces/navigation'
import { UserContext } from '@services/providers/UserProvider'
import { useRouteHistory } from '@utils/hooks/useRouteHistory'

interface IVariants {
  opacity: number
  x: string | number
}
interface IPageVariants {
  initial: IVariants
  in: IVariants
  out: IVariants
}

interface IAnimatedView {
  className?: string
  'data-testid'?: string
  pageVariants?: IPageVariants
  viewPath: string
}

const tabsOrder: Record<string, number> = {
  [RoutePaths.LEADERBOARDS]: 0,
  [RoutePaths.FRIENDS]: 1,
  [RoutePaths.PROFILE]: 2,
  [RoutePaths.SETTINGS]: 3,
}

const AnimatedView: FC<IAnimatedView> = ({
  children,
  className = '',
  pageVariants,
  viewPath,
  'data-testid': testId,
}) => {
  const { pathname } = useLocation()
  const { getPreviousRoute } = useRouteHistory()

  const { writingMode } = useContext(UserContext)

  let animationXOffsetPositive = 100
  let animationXOffsetNegative = -100

  if (writingMode === 'rtl') {
    animationXOffsetNegative = animationXOffsetNegative * -1
    animationXOffsetPositive = animationXOffsetPositive * -1
  }

  const getExitXValue = (currentPathname: string) => {
    const pathname = currentPathname

    if (!pathname) return 0

    const currPathFirstRoute = `/${pathname.split('/')[1]}`

    // If they are the same don't animate
    if (currPathFirstRoute === viewPath) return 0

    // If going to auth page don't animate out
    if (currPathFirstRoute === RoutePaths.AUTH) return 0

    if (viewPath === RoutePaths.LEADERBOARDS) {
      return animationXOffsetNegative
    }

    if (viewPath === RoutePaths.SETTINGS) {
      return animationXOffsetPositive
    }

    const currRouteIndex = tabsOrder[currPathFirstRoute]
    const viewIndex = tabsOrder[viewPath]

    if (viewIndex > currRouteIndex) {
      return animationXOffsetPositive
    }

    return animationXOffsetNegative
  }

  const getInitialXValue = (currentPathname: string) => {
    const filter =
      viewPath === RoutePaths.FRIENDS ? /^((?!\/friends).)*$/ : undefined

    const lastHistoryEntry = getPreviousRoute(-1, filter)
    const prevPath = lastHistoryEntry ?? currentPathname

    const currPathFirstRoute = `/${pathname.split('/')[1]}`
    const prevPathFirstRoute = `/${prevPath.split('/')[1]}`

    // If they are the same don't animate
    if (currPathFirstRoute === prevPathFirstRoute) return 0

    // If going to auth page don't animate in
    if (prevPathFirstRoute === RoutePaths.AUTH) return 0

    // const isLtr = writingMode === 'ltr'

    const currRouteIndex = tabsOrder[currPathFirstRoute]
    const prevRouteIndex = tabsOrder[prevPathFirstRoute]

    if (prevRouteIndex < currRouteIndex) {
      return animationXOffsetPositive
    }

    return animationXOffsetNegative
  }

  const defaultPageVariants = {
    initial: (pathname: string) => {
      return {
        x: `${getInitialXValue(pathname)}%`,
      }
    },
    in: {
      x: 0,
    },
    out: (pathname: string) => {
      return {
        x: `${getExitXValue(pathname)}%`,
      }
    },
  }

  const defaultPageTransition = {
    duration: 0.3,
  }

  return (
    <motion.div
      key={testId}
      initial="initial"
      animate="in"
      // custom={pathname}
      exit="out"
      variants={defaultPageVariants}
      transition={defaultPageTransition}
      className={`${className} absolute inset-0`}
      data-testid={testId}
    >
      {children}
    </motion.div>
  )
}

export default AnimatedView
