import { LanguageEnum } from '@interfaces/languages'
import { LeaderboardMeta } from '@interfaces/leaderboard'

/**
 * Gets the tab name that should be rendered
 * @param {LanguageEnum} activeLanguage
 * @param {LeaderboardMeta} meta
 * @return {string}
 */
export const getLeaderboardName = (
  activeLanguage: LanguageEnum,
  meta?: LeaderboardMeta
) => {
  if (!meta) {
    return '...'
  }

  const activeLangTitle = meta?.title?.localisations?.[activeLanguage]?.text
  if (activeLangTitle) {
    return activeLangTitle
  }

  const englishLangTitle =
    meta?.title?.localisations?.[LanguageEnum.LANGUAGE_ENGLISH]?.text
  if (englishLangTitle) return englishLangTitle

  return meta.name
}
