import { captureException } from '@sentry/react'

/**
 * Used to log errors on the SDK. This is so we can capture the errors in sentry
 * @param {unknown} error
 */
export const logError = (error: unknown) => {
  console.error(error)
  captureException(error)
}
