import { useQuery } from 'react-query'
import { getLeaderboardMetaById } from '@services/requests/leaderboards'
import { LEADERBOARD_META_STALE_TIME } from '@utils/reactQuery'

export const useGetLeaderboardMeta = (leaderboardId?: string) => {
  return useQuery(
    `leaderboard.${leaderboardId}.meta`,
    () => {
      if (!leaderboardId) {
        return undefined
      }
      return getLeaderboardMetaById(leaderboardId)
    },
    { staleTime: LEADERBOARD_META_STALE_TIME }
  )
}
