import EventEmitter from 'eventemitter3'
import { WidgetConfig } from '@interfaces/widget'
import { VideoPlayer, VideoPlayerEvent } from '@interfaces/videoPlayer'
import { addDefaultUIHandlers } from '@utils/videoPlayer/helpers'

export const getYoutubeVideoPlayer = async ({
  containerQuerySelector,
}: WidgetConfig): Promise<VideoPlayer> => {
  const emitter = new EventEmitter()

  const videoWrapper = document.querySelector(containerQuerySelector ?? '')

  if (!videoWrapper) {
    throw new Error(
      `Could not find videoWrapper with selector ${containerQuerySelector}`
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const youtubePlayerInstance: any = await new Promise((resolve) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const win: any = window
    const youtubeIframeAPI = document.createElement('script')
    youtubeIframeAPI.src = 'https://www.youtube.com/iframe_api'

    const firstJSScript = document.getElementsByTagName('script')[0]
    firstJSScript?.parentNode?.insertBefore(youtubeIframeAPI, firstJSScript)

    const youtubeIframe = videoWrapper?.querySelector('iframe')

    win.onYouTubeIframeAPIReady = () => {
      const instance = new win.YT.Player(youtubeIframe)
      resolve(instance)
    }
  })

  const handleTimeUpdate = () => {
    emitter.emit(
      VideoPlayerEvent.TIME_UPDATE,
      youtubePlayerInstance.getCurrentTime()
    )
  }

  let sportBuffContainer: HTMLElement
  const youtubeTimeInterval: number = window.setInterval(handleTimeUpdate, 1000)

  const removeUIHandlers = addDefaultUIHandlers(videoWrapper, emitter)

  const appendContainer = (containerToAppend: HTMLElement) => {
    sportBuffContainer = containerToAppend
    videoWrapper.appendChild(containerToAppend)
  }

  // TODO: Should we remove youtube script injection as well?
  const destroy = () => {
    if (!sportBuffContainer) return
    videoWrapper.removeChild(sportBuffContainer)
    clearInterval(youtubeTimeInterval)
    removeUIHandlers()
  }

  return {
    on: emitter.on.bind(emitter),
    off: emitter.off.bind(emitter),
    emit: emitter.emit.bind(emitter),
    appendContainer,
    destroy,
  }
}
