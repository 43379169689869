import { client } from '@services/requests/client'
import {
  GetUserFriendGroupsResponse,
  FriendGroup,
  FriendGroupMember,
} from '@interfaces/friendGroups'
import { DecoratedLeaderboardStanding } from '@interfaces/leaderboard'
import { transformGQLFriendsGroupLeaderboards } from '@utils/friendsGroups'

/**
 * Get the groups a user is a part of
 * @param {string} userId
 * @return {Promise<FriendGroup[]>}
 */
export const getUserGroups = async (userId: string): Promise<FriendGroup[]> => {
  const response = await client.get<GetUserFriendGroupsResponse>(
    `/friend-groups?userId=${userId}`
  )

  return response.data.groups
}

const groupLeaderboardQuery = `
query Query($userId: ID, $leaderboardId: ID!) {
  user(id: $userId) {
    id
    friendGroups {
      id
      name
      leaderboard(leaderboardId: $leaderboardId) {
        standings {
          points
          position
          rank
          user {
            id
            displayName
            profilePicture
          }
        }
      }
    }
  }
}
`

/**
 * Get a group leaderboard
 * @param {string} leaderboardId  Id of the leaderboard to get
 * @param {string} userId Id of the user
 * @return {Promise}
 */
export const getGroupLeaderboard = async (
  leaderboardId?: string,
  userId?: string
): Promise<{
  standings: DecoratedLeaderboardStanding[]
}> => {
  if (!leaderboardId) return { standings: [] }

  const response = await client.post('/graph', {
    query: groupLeaderboardQuery,
    variables: { userId, leaderboardId },
  })

  if (response.data.data.user) {
    const standings = transformGQLFriendsGroupLeaderboards(
      leaderboardId,
      response.data
    )

    return { standings }
  }

  return response.data
}

interface JoinGroupByInviteCodeResponse {
  id: string
  name: string
  owner: string
}

export const joinGroupByInviteCode = async (inviteCode: string) => {
  const response = await client.post<JoinGroupByInviteCodeResponse>(
    '/friend-groups/join',
    {
      code: inviteCode,
    }
  )

  return response.data
}

/**
 * Gets a friend group by id
 * @param {string} groupId Id of the group
 * @return {Promise<FriendGroup>}
 */
export const getFriendGroupById = async (groupId: string) => {
  const response = await client.get<FriendGroup>(`/friend-groups/${groupId}`)
  return response.data
}

/**
 * Makes user leave a friend group
 * @param {string} groupId Id of the group
 * @return {Promise}
 */
export const leaveGroupById = async (groupId: string) => {
  const response = await client.post(`/friend-groups/${groupId}/leave`)
  return response.data
}

/**
 * Deletes a group
 * @param {string} groupId Id of the group
 * @return {Promise}
 */
export const deleteGroupById = async (groupId: string) => {
  const response = await client.delete(`/friend-groups/${groupId}`)
  return response.data
}

/**
 * Creates a new group
 * @return {Promise<FriendGroup>}
 */
export const createGroup = async () => {
  const response = await client.post<FriendGroup>(`/friend-groups`)
  return response.data
}

/**
 * Rotates the code to join a group. (Only group owner can use)
 * @param {string} groupId Id of the group
 * @return {Promise<FriendGroup>}
 */
export const rotateGroupCode = async (groupId: string) => {
  const response = await client.post<FriendGroup>(
    `/friend-groups/${groupId}/rotate-code`
  )

  return response.data
}

/**
 * Locks or unlocks a friend group
 * @param {string} groupId Id of the group
 * @param {boolean} locked If the group should be locked
 * @return {Promise<FriendGroup>}
 */
export const toggleGroupLock = async (groupId: string, locked: boolean) => {
  const response = await client.put<FriendGroup>(
    `/friend-groups/${groupId}/locked`,
    {
      locked,
    }
  )

  return response.data
}

interface GetFriendGroupMembersResponse {
  members: FriendGroupMember[]
}

/**
 * Gets a list of friend group members
 * @param {string} groupId Id of the group
 * @return {Promise<FriendGroupMember[]>}
 */
export const getFriendGroupMembers = async (groupId: string) => {
  const response = await client.get<GetFriendGroupMembersResponse>(
    `/friend-groups/${groupId}/members`
  )

  return response.data.members
}

/**
 * Fetch pubnub token
 * @return {Promise<string>}
 */
export const getPubnubToken = async () => {
  const response = await client.post(`/friend-groups/pubnub/token-exchange`)

  return response.data?.token
}

/**
 * Kick/Ban user from a group
 * @param {string} groupId Id of the group
 * @param {string} userId Id of the user
 * @param {boolean} ban Whether to ban or kick
 * @return {Promise<FriendGroup>}
 */
export const kickUser = async (
  groupId: string,
  userId: string,
  ban: boolean
) => {
  const response = await client.delete<FriendGroup>(
    `/friend-groups/${groupId}/members/${userId}?ban=${ban}`
  )

  return response.data
}
