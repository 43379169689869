import React, { FC, useState, useRef, useEffect } from 'react'
import { motion } from 'framer-motion'
import { ReactComponent as StarIcon } from '@assets/star.svg'
import { StarProps } from './types'
import './Star.css'

const Star: FC<StarProps> = ({
  fillAmount,
  disabled,
  animate = false,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  const [isAnimating, setIsAnimating] = useState(false)
  const animationRef = useRef<number>()
  const animatingStyle = isAnimating ? 'sparkler-light' : ''
  const sparkStyle = 'spark'
  const cursorStyle = disabled ? 'cursor-default' : 'cursor-pointer'
  const listItem = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  }

  // Remove timeout instances when unmounting
  useEffect(() => {
    return () => {
      if (!animationRef.current) return
      clearTimeout(animationRef.current)
    }
  }, [])

  const foregroundStarWidth = `${fillAmount}%`

  return (
    <motion.button
      data-testid="answer-star"
      variants={listItem}
      className={`relative w-full h-full ${cursorStyle} ${animatingStyle}`}
      onMouseEnter={onMouseEnter}
      disabled={disabled}
      onMouseLeave={onMouseLeave}
      onClick={() => {
        setIsAnimating(true)
        onClick && onClick()

        animationRef.current = window.setTimeout(() => {
          setIsAnimating(false)
        }, 700)
      }}
    >
      <div className="w-10 h-10 relative mx-auto">
        <div className="w-10 h-10">
          <StarIcon className="w-10 h-10" fill="var(--color-base-400)" />
        </div>
        <div
          className="w-10 h-10 absolute inset-0 overflow-hidden"
          style={{ width: foregroundStarWidth }}
        >
          <StarIcon className="w-10 h-10" />
        </div>
      </div>

      {isAnimating && animate && (
        <>
          <div className={`${sparkStyle} spark-1`} />
          <div className={`${sparkStyle} spark-2`} />
          <div className={`${sparkStyle} spark-3`} />
          <div className={`${sparkStyle} spark-4`} />
          <div className={`${sparkStyle} spark-5`} />
        </>
      )}
    </motion.button>
  )
}
export default Star
