import React, { useContext, FC, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import TabPages from '@components/atoms/TabPages'
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom'
import { BuffContext } from '@services/providers/BuffProvider'
import NavigationButton from '@components/molecules/NavigationButton'
import { ReactComponent as LeaderboardEmptyIcon } from '@assets/icons/leaderboard-empty.svg'
import { ReactComponent as LeaderboardFilledIcon } from '@assets/icons/leaderboard-filled.svg'
import { ReactComponent as SettingsEmptyIcon } from '@assets/icons/settings-empty.svg'
import { ReactComponent as SettingsFilledIcon } from '@assets/icons/settings-filled.svg'
import { ReactComponent as FriendsEmptyIcon } from '@assets/icons/friends-group-empty.svg'
import { ReactComponent as FriendsFilledIcon } from '@assets/icons/friends-group-filled.svg'
import { ReactComponent as ProfileEmptyIcon } from '@assets/icons/person-empty.svg'
import { ReactComponent as ProfileFilledIcon } from '@assets/icons/person-filled.svg'
import { RouteMainPaths } from '@interfaces/navigation'
import { AnimatedRouter } from '@components/atoms/SdkRouter'
import { useSDKFeatures } from '@utils/hooks/useSDKFeatures'
import { getExternalRoutesConfig, getRoutesConfig } from '@utils/routes'
import { UIFeatures } from '@interfaces/clientConfig'
import { FloatingPanelTabsLayout, FloatingPanelTabsProps } from './types'

const ENABLE_FRIENDS_GROUP = process.env.FRIENDS_GROUP === 'true'

interface Tab {
  key: string
  route: string
  icon: FC
  activeIcon: FC
  show?: boolean
}

const authRoutesConfig = getExternalRoutesConfig()
const mainRoutesConfig = getRoutesConfig()

const Content = ({
  height,
  allowGamePoints,
  features,
}: {
  height: string
  allowGamePoints: boolean
  features?: UIFeatures
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const showLeaderboardTab = allowGamePoints && !!features?.leaderboard?.enabled
  const showFriendsTab =
    allowGamePoints && !!features?.friends?.enabled && ENABLE_FRIENDS_GROUP
  const showProfileTab = !!features?.profile?.enabled

  const tabs: Tab[] = useMemo(() => {
    return [
      {
        key: t('general.leaderboard'),
        route: RouteMainPaths.LEADERBOARDS,
        icon: LeaderboardEmptyIcon,
        activeIcon: LeaderboardFilledIcon,
        show: showLeaderboardTab,
      },
      {
        key: t('general.friends'),
        route: RouteMainPaths.FRIENDS,
        icon: FriendsEmptyIcon,
        activeIcon: FriendsFilledIcon,
        show: showFriendsTab,
      },
      {
        key: t('settings.profile'),
        route: RouteMainPaths.PROFILE,
        icon: ProfileEmptyIcon,
        activeIcon: ProfileFilledIcon,
        show: showProfileTab,
      },
      {
        key: t('general.settings'),
        route: RouteMainPaths.SETTINGS,
        icon: SettingsEmptyIcon,
        activeIcon: SettingsFilledIcon,
        show: true,
      },
    ]
  }, [showFriendsTab, showLeaderboardTab, showProfileTab, t])

  const showableTabs = useMemo(() => {
    return tabs.filter((tab) => tab.show)
  }, [tabs])

  useEffect(() => {
    if (
      !allowGamePoints &&
      (pathname.includes(RouteMainPaths.LEADERBOARDS) ||
        pathname.includes(RouteMainPaths.FRIENDS))
    ) {
      navigate(RouteMainPaths.PROFILE)
    }
  }, [allowGamePoints, pathname, navigate])

  return (
    <>
      <TabPages>
        <div
          className={`w-full overflow-auto relative overflow-x-hidden ${height}`}
        >
          <AnimatedRouter
            routes={mainRoutesConfig}
            animateKeyFirstSlug={true}
          />
        </div>
      </TabPages>

      {showableTabs.length > 1 && (
        <div
          data-testid="tab-buttons-container"
          className="flex justify-center bg-base-100 rounded-md rounded-t-none"
        >
          {showableTabs.map((tab) => (
            <NavigationButton key={tab.key} tab={tab} to={tab.route}>
              {tab.key}
            </NavigationButton>
          ))}
        </div>
      )}

      <AnimatedRouter routes={authRoutesConfig} animateKeyFirstSlug={true} />
    </>
  )
}

const FloatingPanelTabs = ({
  layout = FloatingPanelTabsLayout.FIXED_HEIGHT,
}: FloatingPanelTabsProps) => {
  const { activeBuff } = useContext(BuffContext)
  const { allowGamePoints, features } = useSDKFeatures()

  const isFullHeightLayout = layout === FloatingPanelTabsLayout.FILL_SCREEN
  const mainRoutesHeight = isFullHeightLayout ? 'h-full' : 'h-72'

  // const externalRoutesConfig = getExternalRoutesConfig()
  // const routesConfig = getRoutesConfig()
  // const externalRouter = useRoutes(externalRoutesConfig)
  // const mainRoutes = useRoutes(routesConfig)

  const blur =
    layout === FloatingPanelTabsLayout.FILL_SCREEN && Boolean(activeBuff)
      ? 'blur-sm'
      : ''

  return (
    <div className={`overflow-hidden relative flex flex-col h-full ${blur}`}>
      <Routes>
        <Route
          path="/*"
          element={
            <Content
              allowGamePoints={!!allowGamePoints}
              features={features}
              height={mainRoutesHeight}
            />
          }
        />
      </Routes>
    </div>
  )
}

export default FloatingPanelTabs
