import EventEmitter from 'eventemitter3'
import { VideoPlayerEvent, VideoPlayerHandler } from '@interfaces/videoPlayer'

export const getTwitchWebVideoPlayer: VideoPlayerHandler = async () => {
  const emitter = new EventEmitter()

  // Twitch Web is not adding extension in same way as other types so fixing type errors with noops
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const noop = () => {}
  const appendContainer = noop
  const destroy = noop

  Twitch.ext.onContext((context) => {
    const { arePlayerControlsVisible } = context
    emitter.emit(
      arePlayerControlsVisible
        ? VideoPlayerEvent.SHOW_UI
        : VideoPlayerEvent.HIDE_UI
    )
  })

  return {
    on: emitter.on.bind(emitter),
    emit: emitter.emit.bind(emitter),
    off: emitter.off.bind(emitter),
    appendContainer,
    destroy,
  }
}
