import React, { useCallback, useContext, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { usePubNub } from 'pubnub-react'
import {
  EmojiPickerElementProps,
  MessageInput,
  MessageList,
  MessagePayload,
  MessageRendererProps,
} from '@pubnub/react-chat-components'
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import Modal from '@components/molecules/Modal'
import { friendsGroupIdRouteRegex } from '@utils/friendsGroups'
import { ReactComponent as SendIcon } from '@assets/icons/send.svg'
import { UserContext } from '@services/providers/UserProvider'
import { sendCounterEvent } from '@utils/metrics'
import { PROPERTY_EVENTS } from '@interfaces/metrics'
import { useUnreadCountStore } from '@utils/hooks/stores/useUnreadCountStore'

const HALF_MINUTE = 30 * 1000

const PickerAdapter = (props: EmojiPickerElementProps) => {
  const handleEmoji = (e: { native: string }) => {
    if (props.onSelect) props.onSelect({ native: e.native })
  }

  return (
    <Picker
      data={data}
      onEmojiSelect={handleEmoji}
      previewPosition="none"
      skinTonePosition="none"
    />
  )
}

const FriendsGroupChat = () => {
  const navigate = useNavigate()
  const { pathname, search } = useLocation()
  const { t } = useTranslation()
  const setUnreadCount = useUnreadCountStore((state) => state.setUnreadCount)

  const query = new URLSearchParams(search)

  const channel = query.get('channel')

  const { profile } = useContext(UserContext)
  const pubnub = usePubNub()

  const regexResult = pathname.match(friendsGroupIdRouteRegex)
  const groupId = regexResult ? regexResult[1] : 'UNKNOWN'

  const handleBack = () => {
    navigate(`/friends/${groupId}`)
  }

  const handleSetLastRead = useCallback(
    (channel: string) => {
      pubnub.objects.setMemberships({
        channels: [
          {
            id: channel,
            custom: {
              lastReadTimetoken: Date.now() * 10000,
            },
          },
        ],
      })
    },
    [pubnub.objects]
  )

  useEffect(() => {
    if (!channel) return

    const interval = window.setInterval(() => {
      handleSetLastRead(channel)
    }, HALF_MINUTE)

    return () => {
      handleSetLastRead(channel)
      clearTimeout(interval)
    }
  }, [channel, handleSetLastRead])

  useEffect(() => {
    return () => {
      setUnreadCount(0)
    }
  }, [setUnreadCount])

  const renderMessage = (props: MessageRendererProps) => {
    if (props.message?.message?.file) return <></>
    const messagePayload = props?.message?.message as MessagePayload
    if (!messagePayload?.text) return <></>
    const text = messagePayload.text.replace(/\\n/g, '\n')
    return (
      <div className="flex flex-col">
        <div className="flex flex-row items-center">
          <p className="sb-break-words line-clamp-1 font-bold text-xs">
            {messagePayload?.sender?.name}
          </p>
          {!!props.time && (
            <span className="ms-2 text-label-500 font-semibold flex-shrink-0 text-[10px]">
              {props.time}
            </span>
          )}
        </div>
        <p className="sb-break-words text-sm whitespace-pre-line">{text}</p>
      </div>
    )
  }

  const handleSend = () => {
    sendCounterEvent(PROPERTY_EVENTS.chatMessageSent)
  }

  return (
    <Modal
      title={t('friends.chat')}
      data-testid="friendsGroupChat"
      onGoBack={handleBack}
      centerTitle
      childrenClassName="overflow-hidden -my-2"
    >
      <div className="flex flex-1 flex-col w-full justify-between overflow-y-auto overflow-x-hidden">
        <MessageList
          messageRenderer={renderMessage}
          fetchMessages={10}
          onScroll={function noRefCheck() {}}
        />
        <MessageInput
          senderInfo
          placeholder={t('friends.chatInputPlaceholder')}
          emojiPicker={<PickerAdapter />}
          onBeforeSend={(value) => {
            if (value?.sender) {
              value.sender.name = profile?.displayName
              value.sender.profileUrl = profile?.profilePicture
            } else {
              value = {
                ...value,
                sender: {
                  name: profile?.displayName,
                  profileUrl: profile?.profilePicture,
                  id: profile?.id ?? '',
                  eTag: '',
                  updated: '',
                },
              }
            }
            return value
          }}
          onSend={handleSend}
          sendButton={
            <SendIcon className="w-5 h-5" fill="var(--color-label-50)" />
          }
        />
      </div>
    </Modal>
  )
}

export default FriendsGroupChat
