import React, { useState, useRef, useEffect, useContext } from 'react'
import { useNavigate, useLocation, Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ReactComponent as WarningIcon } from '@assets/icons/warning.svg'
import axios from 'axios'
import CodeInput from '@components/molecules/CodeInput'
import Modal from '@components/molecules/Modal'
// import { Errors } from '@interfaces/errors'
import { joinGroupByInviteCode } from '@services/requests/friendGroups'
import { RoutePaths, RouteSubPaths } from '@interfaces/navigation'
import { queryClient } from '@utils/reactQuery'
import { sendEvent } from '@utils/metrics'
import { METRIC_EVENTS } from '@interfaces/metrics'
import { isResponseError } from '@utils/error'
import { logError } from '@utils/log'
import { UserContext } from '@services/providers/UserProvider'

const JoinFriends = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [codeIsValid, setCodeIsValid] = useState<boolean>()
  const codeInputFormRef = useRef<HTMLFormElement>(null)
  const { userId } = useContext(UserContext)

  const handleJoinGroup = async (inviteCode: string) => {
    try {
      const { id } = await joinGroupByInviteCode(inviteCode)
      sendEvent(METRIC_EVENTS.privateGroupJoined, { id })
      queryClient.invalidateQueries(['user-groups', userId])
      navigate(`/friends/${id}`)
    } catch (error) {
      const titleKey = 'friends.unableToJoinGroup'
      const descLine2Key = 'general.tryAgain'
      let descLine1Key = 'error.somethingWentWrong'

      if (axios.isAxiosError(error)) {
        if (error.response?.status === 400) {
          setCodeIsValid(false)
          return
        }

        if (error.response?.status === 412) {
          const responseError = error.response.data

          if (isResponseError(responseError)) {
            switch (responseError.error.msg) {
              case 'group is locked':
                descLine1Key = 'friends.groupLockedError'
                break

              case "group has reached it's maximum member count":
                descLine1Key = 'friends.groupFullError'
                break

              default:
                break
            }
          }
        }
      } else {
        logError(error)
      }

      const query = new URLSearchParams({
        title: titleKey,
        descriptionLine1: descLine1Key,
        descriptionLine2: descLine2Key,
      })

      navigate(
        `${RoutePaths.FRIENDS_JOIN}/${RouteSubPaths.ERROR}?${query.toString()}`
      )
    }
  }

  const getCodeInputValidationProps = () => {
    if (codeIsValid === undefined) return

    if (codeIsValid === false) {
      const errorMessage = t('friends.codeValidationFailed')
      return { error: errorMessage }
    }

    return
  }

  const handleGoBack = () => {
    navigate(RoutePaths.FRIENDS)
  }

  // Resets form after coming back from error page
  useEffect(() => {
    codeInputFormRef.current?.reset()
  }, [pathname])

  return (
    <Modal
      title={t('friends.joinFriendsTitle')}
      data-testid="joinFriends"
      onGoBack={handleGoBack}
      centerTitle
      routerChildren={<Outlet />}
    >
      <div className="w-full">
        <div className="mb-2.5">
          <p className="text-xs text-label-50">
            {t('friends.joinFriendsInviteCode')}
          </p>
        </div>

        <CodeInput
          ref={codeInputFormRef}
          onSubmit={handleJoinGroup}
          onChange={() => setCodeIsValid(undefined)}
          onReset={() => setCodeIsValid(undefined)}
          {...getCodeInputValidationProps()}
        />
      </div>
    </Modal>
  )
}

export default JoinFriends
