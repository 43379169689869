import { FontItem, Fonts, Theme, DynamicTheme } from '@interfaces/theme'
import { noop } from '@utils/noop'

/**
 * Generates font faces from theme
 * @param {FontItem} fontItem
 * @return {Function} Destroy function to remove the created font
 */
function generateCustomFontFace(fontItem: FontItem): string | null {
  if (!fontItem?.regular?.url?.length) return null

  return `@font-face {
    font-family: "${fontItem.name}";
    src: url(${fontItem.regular.url});
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "${fontItem.name}";
    src: url(${fontItem.semiBold.url});
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "${fontItem.name}";
    src: url(${fontItem.bold.url});
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }`
}

/**
 * Creates a style tag with all font faces into the client's html head
 * @param {DynamicTheme} theme
 * @return {Function} Destroy function to remove the created font
 */
export function setupFontFaces(theme?: DynamicTheme): () => void {
  const font1 =
    (((theme as DynamicTheme)?.theme as Theme)?.fonts as Fonts)?.font1 ?? null
  const font2 =
    (((theme as DynamicTheme)?.theme as Theme)?.fonts as Fonts)?.font2 ?? null
  const customFontFace1 = generateCustomFontFace(font1)
  const customFontFace2 = generateCustomFontFace(font2)
  const customFontFaces = `${customFontFace1} \n ${customFontFace2}`
  const id = `sportbuff-font-faces-${theme?.theme.name}`

  const styleTag = document.getElementById(id)

  const removeStyleTag = (element: HTMLElement) => {
    const parent = element.parentElement
    parent?.removeChild(element)
  }

  if (styleTag) {
    return () => removeStyleTag(styleTag)
  }

  if (customFontFace1 || customFontFace2) {
    const style = document.createElement('style')
    style.id = id
    style.appendChild(document.createTextNode(customFontFaces))
    document.head.appendChild(style)

    return () => removeStyleTag(style)
  }

  return noop
}
