import React, { FC } from 'react'
import { ReactComponent as FullScreen } from '@assets/fullscreen.svg'
import { IFullScreenButtonProps } from './types'

const FullScreenButton: FC<IFullScreenButtonProps> = ({
  onClick,
  className = '',
}) => {
  return (
    <button
      onClick={onClick}
      data-testid="full-screen__button"
      className={`bg-transparent p-2 cursor-pointer pointer-events-auto m-1 absolute left-0 top-0 ${className}`}
    >
      <FullScreen className="block" />
    </button>
  )
}

export default FullScreenButton
