import { transformLocalisedContent } from '@utils/localisedContent'
import { LanguageEnum } from '@interfaces/languages'
import { RawLeaderboardMeta, LeaderboardMeta } from '@interfaces/leaderboard'

export const transformRawLeaderboardMetaToLeaderboardMeta = (
  rawMeta: RawLeaderboardMeta
): LeaderboardMeta => {
  const meta: LeaderboardMeta = {
    ...rawMeta,
    title: rawMeta.title
      ? {
          localisations: transformLocalisedContent(rawMeta.title.localisations),
        }
      : undefined,
    sponsor: rawMeta.sponsor
      ? {
          localisations: transformLocalisedContent(
            rawMeta.sponsor.localisations
          ),
        }
      : undefined,
  }

  return meta
}

export const getLeaderboardSponsorContent = (
  activeLanguage: LanguageEnum,
  meta?: LeaderboardMeta
) => {
  if (!meta) return undefined
  if (!meta.sponsor) return false
  const sponsorContent = meta.sponsor.localisations[activeLanguage]

  if (
    sponsorContent &&
    (sponsorContent.text ||
      sponsorContent?.imageUrl ||
      sponsorContent.linkTarget)
  )
    return sponsorContent
  return false
}

/**
 * Round a percentage to 1 decimal place max
 * @param {number}percentile
 * @return {number}
 */
export const formatPercentage = (percentile: number) => {
  return Math.round(percentile * 10) / 10
}
