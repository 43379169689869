import React, {
  FC,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'
import PubNub from 'pubnub'
import { PubNubProvider } from 'pubnub-react'
import { useNavigate, useLocation, useOutlet } from 'react-router-dom'
import AnimatedView from '@components/atoms/AnimatedView'
import { RoutePaths, RouteSubPaths } from '@interfaces/navigation'
import { useUserGroups } from '@utils/hooks/useUserGroups'
import { useGetFriendGroupMembers } from '@utils/hooks/useGetFriendGroupMembers'
import { UserContext } from '@services/providers/UserProvider'
import { isPubSubEvent, formatEvent } from '@utils/pubSub'
import { friendsGroupIdPrivateRouteRegex } from '@utils/friendsGroups'
import { PubSubEventType } from '@interfaces/pubSub'
import { queryClient } from '@utils/reactQuery'
import { useWebSocketClient } from '@utils/hooks/useWebSocketClient'
import { LeaderboardContext } from '@services/providers/LeaderboardProvider'

const getQueriesToInvalidate = (
  type: PubSubEventType,
  groupId: string,
  userId?: string,
  leaderboardId?: string
) => {
  switch (type) {
    case PubSubEventType.LOCK_FRIEND_GROUP:
    case PubSubEventType.UNLOCK_FRIEND_GROUP:
    case PubSubEventType.CODE_ROTATE_FRIEND_GROUP:
      return [['friends.info', groupId]]

    case PubSubEventType.JOIN_FRIEND_GROUP:
    case PubSubEventType.LEAVE_FRIEND_GROUP:
      return [
        ['friends', groupId, 'members'],
        ['friend.leaderboard', leaderboardId, userId, groupId],
      ]

    case PubSubEventType.REMOVE_FRIEND_GROUP:
      return [
        ['friends', groupId, 'members'],
        ['user-groups', userId],
        ['friend.leaderboard', leaderboardId, userId, groupId],
      ]

    case PubSubEventType.DELETE_FRIEND_GROUP:
      return [['user-groups', userId]]

    default:
      return null
  }
}

const FriendsGroup: FC = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const outlet = useOutlet()
  const { userId } = useContext(UserContext)
  const { leaderboardIds } = useContext(LeaderboardContext)
  const [pubnubClient, setPubnubClient] = useState<PubNub>(
    new PubNub({
      subscribeKey: process.env.PUBNUB_KEY ?? '',
      publishKey: process.env.PUBNUB_PUBLISH_KEY ?? '',
      uuid: userId ?? 'userId',
    })
  )
  const { data: friendGroups } = useUserGroups(userId)
  const { subscribe, unsubscribe } = useWebSocketClient()

  const firstGroupId = friendGroups?.[0]?.id

  const leaderboardId =
    leaderboardIds.length === 0 ? undefined : leaderboardIds[0]

  const { data: friendGroupMembers } = useGetFriendGroupMembers(firstGroupId)

  useLayoutEffect(() => {
    if (!!userId) {
      const client = new PubNub({
        subscribeKey: process.env.PUBNUB_KEY ?? '',
        publishKey: process.env.PUBNUB_PUBLISH_KEY ?? '',
        uuid: userId,
      })
      setPubnubClient(client)
    }
  }, [userId])

  useEffect(() => {
    if (pathname === RoutePaths.FRIENDS) {
      if (!friendGroupMembers || friendGroupMembers.length < 2) {
        navigate(RoutePaths.FRIENDS_WELCOME)
        return
      }
      if (firstGroupId && !!userId) {
        navigate(`/friends/${firstGroupId}`)
      } else {
        navigate(RoutePaths.FRIENDS_WELCOME)
      }
      return
    }

    if (
      pathname === RoutePaths.FRIENDS_WELCOME &&
      !!userId &&
      firstGroupId &&
      friendGroupMembers &&
      friendGroupMembers?.length >= 2
    ) {
      navigate(`/friends/${firstGroupId}`)
      return
    }

    // Anything matching regex should only display with group members present (if not leave route)
    const isProtectedRoute =
      friendsGroupIdPrivateRouteRegex.test(pathname) &&
      !pathname.includes(RouteSubPaths.LEAVE)

    if (
      isProtectedRoute &&
      (!friendGroupMembers || friendGroupMembers.length < 2)
    ) {
      navigate(RoutePaths.FRIENDS_WELCOME)
      return
    }
  }, [pathname, firstGroupId, friendGroupMembers, userId, navigate])

  useEffect(() => {
    if (!firstGroupId) return
    const channel = `friend-group.${firstGroupId}`
    const subscription = subscribe(channel, (message) => {
      const event = formatEvent(message)
      if (!isPubSubEvent(event)) return

      const queriesToInvalidate = getQueriesToInvalidate(
        event.name,
        firstGroupId,
        userId,
        leaderboardId
      )
      if (!!queriesToInvalidate?.length) {
        queriesToInvalidate.forEach((query) => {
          queryClient.invalidateQueries(query)
        })
      }
    })

    return () => {
      !!subscription && unsubscribe(subscription)
    }
  }, [subscribe, unsubscribe, firstGroupId, userId, leaderboardId])

  return (
    <AnimatedView data-testid="friends-content" viewPath={RoutePaths.FRIENDS}>
      {!!userId ? (
        <PubNubProvider client={pubnubClient}>{outlet}</PubNubProvider>
      ) : (
        <>{outlet}</>
      )}
    </AnimatedView>
  )
}

export default FriendsGroup
