import { useState, useEffect } from 'react'
import { WidgetConfig } from '@interfaces/widget'
import { VideoPlayerEvent } from '@interfaces/videoPlayer'

/**
 * A hook that will return if a component should be visible
 * based on user interaction with the video
 *
 * @param {WidgetConfig} config Widget config
 * @return {boolean} If a component should be visible
 */
export const useUserActivityVisible = ({
  videoPlayer,
}: WidgetConfig): boolean => {
  const [visible, setVisible] = useState<boolean>(true)

  useEffect(() => {
    if (!videoPlayer) return
    const handleHideUI = () => setVisible(false)
    const handleShowUI = () => setVisible(true)

    videoPlayer.on(VideoPlayerEvent.HIDE_UI, handleHideUI)
    videoPlayer.on(VideoPlayerEvent.SHOW_UI, handleShowUI)

    return () => {
      videoPlayer.off(VideoPlayerEvent.HIDE_UI, handleHideUI)
      videoPlayer.off(VideoPlayerEvent.SHOW_UI, handleShowUI)
    }
  }, [videoPlayer])

  return visible
}
