import { useParams } from 'react-router-dom'
import { useGetLeaderboardMeta } from '@utils/hooks/useGetLeaderboardMeta'
import { useGetLeaderboardUserStanding } from './useGetLeaderboardUserStanding'
import {
  DecoratedLeaderboardStanding,
  LeaderboardMeta,
} from '@interfaces/leaderboard'
import { useGetGroupLeaderboardStandings } from './useGetGroupLeaderboardStandings'

type ProfileParams = {
  entityId: string
  leaderboardId: string
}

interface UseProfileView {
  standing?: DecoratedLeaderboardStanding
  leaderboardMeta?: LeaderboardMeta
}

interface UseProfileViewArgs {
  groupId?: string
}

/**
 * A hook that will load a user from the entityId in the route path
 * @param {UseProfileViewArgs} props
 * @return {UseProfileView}
 */
export const useProfileView = (props?: UseProfileViewArgs): UseProfileView => {
  const { groupId } = props ?? {}
  const { entityId, leaderboardId } = useParams<ProfileParams>()

  const { data: leaderboardMeta } = useGetLeaderboardMeta(leaderboardId)
  const { data: standingData } = useGetLeaderboardUserStanding(
    leaderboardId,
    entityId
  )

  /* eslint-disable react-hooks/rules-of-hooks */
  const groupStandingData = !!groupId
    ? useGetGroupLeaderboardStandings(leaderboardId, entityId, groupId)
    : undefined
  /* eslint-enable react-hooks/rules-of-hooks */

  const groupUserStanding = groupStandingData?.data?.standings?.find(
    (user) => user?.entityId === entityId
  )

  const standing = !!groupId ? groupUserStanding : standingData?.userStanding

  return { standing, leaderboardMeta }
}
