import EventEmitter from 'eventemitter3'
import { WidgetConfig } from '@interfaces/widget'
import { VideoPlayer, VideoPlayerEvent } from '@interfaces/videoPlayer'
import { addDefaultUIHandlers } from '@utils/videoPlayer/helpers'

export const getDefaultVideoPlayer = async ({
  containerQuerySelector,
}: WidgetConfig): Promise<VideoPlayer> => {
  const emitter = new EventEmitter()

  const videoWrapper = document.querySelector(containerQuerySelector ?? '')

  if (!videoWrapper) {
    throw new Error(
      `Could not find videoWrapper with selector ${containerQuerySelector}`
    )
  }

  const videoPlayer = videoWrapper?.querySelector('video') as HTMLVideoElement
  let sportBuffContainer: HTMLElement

  const removeUIHandlers = addDefaultUIHandlers(videoWrapper, emitter)

  const appendContainer = (containerToAppend: HTMLElement) => {
    sportBuffContainer = containerToAppend
    videoWrapper.appendChild(containerToAppend)
  }

  const handleTimeUpdate = () => {
    emitter.emit(VideoPlayerEvent.TIME_UPDATE, videoPlayer.currentTime || 0)
  }

  const destroy = () => {
    if (!sportBuffContainer) return
    videoWrapper.removeChild(sportBuffContainer)
    videoPlayer.removeEventListener('timeupdate', handleTimeUpdate)
    removeUIHandlers()
  }

  videoPlayer.addEventListener('timeupdate', handleTimeUpdate)

  return {
    on: emitter.on.bind(emitter),
    emit: emitter.emit.bind(emitter),
    off: emitter.off.bind(emitter),
    appendContainer,
    destroy,
  }
}
