import { LocalisedContent } from '@interfaces/localisedContent'
import { LanguageEnum } from '@interfaces/languages'

export const transformLocalisedContent = <T extends LocalisedContent>(
  content: T[]
): Partial<Record<LanguageEnum, T>> => {
  if (!content) return content
  const accumulator: Partial<Record<LanguageEnum, T>> = {}
  return content.reduce((acc, content: T) => {
    acc[content.localisation] = content
    return acc
  }, accumulator)
}
