import React, { FC, useContext, useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import VerticalAnswerTile from '@components/molecules/VerticalAnswerTile'
import HorizontalAnswerTile from '@components/molecules/HorizontalAnswerTile'
import { getAnswerTileStatus } from '@utils/answerTile'
import { Engagement, VoteableLayout } from '@interfaces/voteable'
import { PubSubEventType } from '@interfaces/pubSub'
import {
  ANSWER_TIMEOUT,
  getBuffId,
  getBuffType,
  isLiveVotingLifecycle,
  isVoteable,
} from '@utils/buff'
import { BuffType } from '@interfaces/buff'
import { WidgetLayout } from '@interfaces/widget'
import { AnswerTileContainerProps } from './types'
import { getVoteableEngagementById } from '@services/requests/games'
import { StreamContext } from '@services/providers/StreamProvider'
import { useLiveVoting as useLiveVotingHook } from '@utils/hooks/useLiveVoting'

const voteablesToHidePoints = [BuffType.QUIZ, BuffType.POLL]

const getXMargin = (layout: WidgetLayout) => {
  switch (layout) {
    case WidgetLayout.DESKTOP:
      return '-mx-1'

    case WidgetLayout.PORTRAIT_FULL_LEADERBOARD:
    case WidgetLayout.PORTRAIT_TOGGLE_LEADERBOARD:
      return '-mx-3'
  }
}

const getVariantProps = (
  buff: AnswerTileContainerProps['buff'],
  widgetLayout: WidgetLayout
) => {
  if (buff.layout === VoteableLayout.VOTEABLE_LAYOUT_VERTICAL) {
    const containerClassName = 'flex px-1 flex-wrap justify-center -mb-8'
    const answerClassName = `px-0.5 w-2/4 mb-1`

    const containerAnimation = undefined
    const answerAnimation = undefined

    return {
      containerClassName,
      answerClassName,
      containerAnimation,
      answerAnimation,
      isHorizontalAnswerTile: true,
    }
  }

  if (buff.layout === VoteableLayout.VOTEABLE_LAYOUT_HORIZONTAL) {
    const xMargin = getXMargin(widgetLayout)
    const containerClassName = `flex justify-center -mb-8 mt-1 ${xMargin}`

    const maxWidth =
      buff.answers && buff.answers?.length > 3
        ? 'max-w-[20%]'
        : 'max-w-[33.33333%]'

    const answerClassName = `flex-1 px-1 ${maxWidth}`

    const answerAnimation = {
      enter: {
        y: 0,
        opacity: 1,
      },
      exit: {
        y: 100,
        opacity: 0,
      },
    }

    const containerAnimation = {
      enter: {
        transition: { staggerChildren: 0.07, delayChildren: 0.5 },
      },
      exit: {
        transition: { staggerChildren: 0.05, delayChildren: 0.5 },
      },
    }

    return {
      containerClassName,
      answerClassName,
      containerAnimation,
      answerAnimation,
      isHorizontalAnswerTile: false,
    }
  }

  throw new Error('Cannot find props for variant')
}

const AnswerTileContainer: FC<AnswerTileContainerProps> = ({
  buff,
  activeLanguage,
  pubSubEventType,
  selectedAnswerId,
  engagement,
  setSelectedAnswerId,
  votedAnswerId,
  widgetLayout,
  allowGamePoints,
  useLiveVoting,
  'data-testid': testId = 'answer-tile-container',
}) => {
  const { useLiveVotingEnabled } = useLiveVotingHook({
    buff,
    pubSubEventType,
    enabled: useLiveVoting,
  })
  const [isLiveVotingEnabled, setIsLiveVotingEnabled] = useState<boolean>(false)
  const [liveEngagement, setLiveEngagement] = useState<Engagement | undefined>(
    engagement
  )
  const { games } = useContext(StreamContext)
  const answers = buff.answers

  const disableAnswerTile =
    (pubSubEventType !== PubSubEventType.welcomeBuff &&
      pubSubEventType !== PubSubEventType.VOTEABLE_OPEN) ||
    !!votedAnswerId

  const {
    containerClassName,
    answerClassName,
    containerAnimation,
    answerAnimation,
    isHorizontalAnswerTile,
  } = getVariantProps(buff, widgetLayout)

  const hasAnswerPoint = answers?.findIndex((answer) => !!answer?.points) >= 0

  useEffect(() => {
    if (!!engagement) setLiveEngagement(engagement)
  }, [engagement])

  useEffect(() => {
    if (!buff) return

    let interval: ReturnType<typeof setInterval>
    if (isLiveVotingEnabled) {
      interval = setInterval(async () => {
        const currentEngagement = await getVoteableEngagementById(
          games[0].gameId,
          getBuffId(buff)
        )
        setLiveEngagement(currentEngagement)
      }, 1000)
    }

    return () => {
      clearInterval(interval)
    }
  }, [buff, games, isLiveVotingEnabled])

  useEffect(() => {
    if (
      !(isVoteable(buff) && useLiveVotingEnabled) ||
      !!buff.developer?.hideTimebar
    )
      return

    const checkDuration = () => {
      const now = new Date()
      const opensAt = new Date(buff.opensAt)
      const difference = now.getTime() - opensAt.getTime()
      const differenceInSeconds = Math.abs(difference / 1000)

      if (differenceInSeconds > buff.voteDurationSeconds / 2) {
        setIsLiveVotingEnabled(true)
      }
    }

    const interval = setInterval(() => checkDuration(), 1000)
    let timeout: ReturnType<typeof setTimeout>

    if (selectedAnswerId) {
      // ANSWER_TIMEOUT + 1000 avoids showing live voting during the 2s where users can "change their minds"
      timeout = setTimeout(() => {
        setIsLiveVotingEnabled(true)
        clearInterval(interval)
      }, ANSWER_TIMEOUT + 1000)
    }

    return () => {
      clearInterval(interval)
      clearTimeout(timeout)
    }
  }, [buff, selectedAnswerId, pubSubEventType, useLiveVotingEnabled])

  return (
    <motion.div
      variants={containerAnimation}
      className={containerClassName}
      data-testid={testId}
    >
      {answers &&
        answers.map((answer) => {
          const status = getAnswerTileStatus({
            buff,
            answer,
            pubSubEventType,
            selectedAnswerId: votedAnswerId ?? selectedAnswerId,
            useLiveVotingEnabled,
          })

          const buffType = getBuffType(buff)

          const points =
            allowGamePoints &&
            buffType &&
            !voteablesToHidePoints.includes(buffType)
              ? answer.points
              : undefined

          const hasPoints =
            allowGamePoints &&
            hasAnswerPoint &&
            !!buffType &&
            !voteablesToHidePoints.includes(buffType)

          const props = {
            status,
            text: answer?.localisations?.[activeLanguage]?.text ?? '',
            percentage: liveEngagement?.perAnswer?.[answer.id]?.percentage ?? 0,
            disabled: disableAnswerTile,
            image: answer?.localisations?.[activeLanguage]?.imageUrl,
            bgColor: answer?.localisations?.[activeLanguage]?.backgroundColor,
            textColor: answer.localisations?.[activeLanguage]?.textColor,
            points,
            hasPoints,
            isLiveVotingEnabled,
            onClick: () => {
              setSelectedAnswerId?.(answer.id)
            },
          }

          return (
            <motion.div
              variants={answerAnimation}
              key={answer.id}
              className={answerClassName}
            >
              {isHorizontalAnswerTile ? (
                <HorizontalAnswerTile {...props} />
              ) : (
                <VerticalAnswerTile {...props} />
              )}
            </motion.div>
          )
        })}
    </motion.div>
  )
}

export default AnswerTileContainer
