import EventEmitter from 'eventemitter3'
import { WidgetConfig } from '@interfaces/widget'
import { VideoPlayer, VideoPlayerEvent } from '@interfaces/videoPlayer'

export const getJWPlayerVideoPlayer = async ({
  playerInstance,
}: WidgetConfig): Promise<VideoPlayer> => {
  const emitter = new EventEmitter()
  const playerConfig = playerInstance.getConfig()

  // This seems to suggest the player has loaded so ready event won't fire
  const loaded = playerConfig.itemReady

  // wait for player to be ready if not already
  if (!loaded) {
    await new Promise((resolve) => {
      playerInstance.once('ready', resolve)
    })
  }

  let sportBuffContainer: HTMLElement

  const jwPlayerContainer = playerInstance.getContainer()

  const appendContainer = (containerToAppend: HTMLElement) => {
    sportBuffContainer = containerToAppend
    jwPlayerContainer.appendChild(containerToAppend)
  }

  // Rules based on when player controls show with default skin
  const shouldBeVisible = (container: HTMLElement) => {
    const { classList } = container
    if (classList.contains('jw-flag-user-inactive')) {
      const shouldHide =
        classList.contains('jw-state-playing') ||
        classList.contains('jw-state-buffering')

      return !shouldHide
    }

    return true
  }

  const observer = new MutationObserver((list) => {
    const container = list[0].target as HTMLElement
    const visible = shouldBeVisible(container)
    emitter.emit(visible ? VideoPlayerEvent.SHOW_UI : VideoPlayerEvent.HIDE_UI)
  })

  const handleTimeUpdate = (event: { position: number }) => {
    emitter.emit(VideoPlayerEvent.TIME_UPDATE, event.position)
  }

  const destroy = () => {
    if (!sportBuffContainer) return
    jwPlayerContainer.removeChild(sportBuffContainer)
    playerInstance.off('time', handleTimeUpdate)
    observer.disconnect()
  }

  playerInstance.on('time', handleTimeUpdate)
  observer.observe(jwPlayerContainer, { attributes: true })

  return {
    on: emitter.on.bind(emitter),
    emit: emitter.emit.bind(emitter),
    off: emitter.off.bind(emitter),
    appendContainer,
    destroy,
  }
}
