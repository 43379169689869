export interface Language {
  name: string
  id: number
  enum: LanguageEnum
}

export enum LanguageEnum {
  LANGUAGE_UNSPECIFIED = 'LANGUAGE_UNSPECIFIED',
  LANGUAGE_ENGLISH = 'LANGUAGE_ENGLISH',
  LANGUAGE_FRENCH = 'LANGUAGE_FRENCH',
  LANGUAGE_SPANISH = 'LANGUAGE_SPANISH',
  LANGUAGE_PORTUGUESE = 'LANGUAGE_PORTUGUESE',
  LANGUAGE_GERMAN = 'LANGUAGE_GERMAN',
  LANGUAGE_ITALIAN = 'LANGUAGE_ITALIAN',
  LANGUAGE_GREEK = 'LANGUAGE_GREEK',
  LANGUAGE_ARABIC = 'LANGUAGE_ARABIC',
  LANGUAGE_HEBREW = 'LANGUAGE_HEBREW',
  LANGUAGE_MALAYSIAN = 'LANGUAGE_MALAYSIAN',
  LANGUAGE_NORWEGIAN = 'LANGUAGE_NORWEGIAN',
  LANGUAGE_POLISH = 'LANGUAGE_POLISH',
  LANGUAGE_BULGARIAN = 'LANGUAGE_BULGARIAN',
  LANGUAGE_HUNGARIAN = 'LANGUAGE_HUNGARIAN',
  LANGUAGE_INDONESIAN = 'LANGUAGE_INDONESIAN',
  LANGUAGE_DUTCH = 'LANGUAGE_DUTCH',
  LANGUAGE_SERBIAN = 'LANGUAGE_SERBIAN',
}

export enum LanguageCode {
  en = 'en',
  fr = 'fr',
  es = 'es',
  ar = 'ar',
  pt = 'pt',
  de = 'de',
  it = 'it',
  el = 'el',
  he = 'he',
  ms = 'ms',
  no = 'no',
  pl = 'pl',
  bg = 'bg',
  hu = 'hu',
  id = 'id',
  nl = 'nl',
  sr = 'sr',
}
