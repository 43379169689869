import React, { FC, ReactElement } from 'react'
import { BuffType } from '@interfaces/buff'
import Poll from '@components/molecules/Poll'
import Quiz from '@components/molecules/Quiz'
import Prediction from '@components/molecules/Prediction'
import Announcement from '@components/molecules/Announcement'
import EmojiSlider from '@components/molecules/EmojiSlider'
import StreamWinners from '@components/molecules/StreamWinners'
import PopularVote from '@components/molecules/PopularVote'
import StarRating from '@components/organisms/StarsRating'
import { Voteable } from '@interfaces/voteable'
import { StreamWinner } from '@interfaces/streamWinner'
import { Announcement as AnnouncementType } from '@interfaces/announcement'
import {
  getBuffType,
  isVodVoteable,
  isVoteable,
  isWelcomeBuff,
} from '@utils/buff'
import { RenderBuffTypeObject, BuffSwitcherProps } from './types'

/**
 * Based on the buff type received it renders the correct component
 * and forwards the data to it.
 *
 * @param {number} type
 * @param {PubNubResponseBody} buff
 * @return {*}  {JSX.Element}
 */
function renderBuffType({
  type,
  buff,
  pubSubEvent,
  selectedAnswerId,
  votedAnswerId,
  activeLanguage,
  engagement,
  setSelectedAnswerId,
  widgetLayout,
  allowGamePoints,
  useLiveVoting,
}: RenderBuffTypeObject): ReactElement {
  const commonBuffProps = {
    engagement,
    buff: buff as Voteable,
    pubSubEventType: pubSubEvent,
    selectedAnswerId,
    votedAnswerId,
    setSelectedAnswerId,
    widgetLayout,
  }

  const commonBuffWithAnswerProps = {
    ...commonBuffProps,
    activeLanguage,
    allowGamePoints,
    useLiveVoting,
  }

  const types = {
    [BuffType.STREAM_WINNER]: (
      <StreamWinners buff={buff as StreamWinner} widgetLayout={widgetLayout} />
    ),
    [BuffType.PREDICTION]: <Prediction {...commonBuffWithAnswerProps} />,
    [BuffType.POPULAR_VOTE]: <PopularVote {...commonBuffWithAnswerProps} />,
    [BuffType.QUIZ]: <Quiz {...commonBuffWithAnswerProps} />,
    [BuffType.POLL]: <Poll {...commonBuffWithAnswerProps} />,
    [BuffType.EMOJI]: (
      <EmojiSlider {...commonBuffProps} activeLanguage={activeLanguage} />
    ),
    [BuffType.STAR]: <StarRating {...commonBuffProps} />,
    [BuffType.ANNOUNCEMENT]: (
      <Announcement
        activeLanguage={activeLanguage}
        buff={buff as AnnouncementType}
      />
    ),
  }

  // @ts-ignore
  return types[type] || null
}

const BuffSwitcher: FC<BuffSwitcherProps> = ({
  activeBuff,
  pubSubEvent,
  voteBuff,
  voteWelcomeBuff,
  selectedAnswerId,
  votedAnswerId,
  activeLanguage,
  engagement,
  widgetLayout,
  allowGamePoints,
  useLiveVoting,
}) => {
  const setSelectedAnswerId = (id: string) => {
    if (!activeBuff) return

    if (isVoteable(activeBuff) || isVodVoteable(activeBuff)) {
      voteBuff(activeBuff, id)
    }
    if (isWelcomeBuff(activeBuff)) {
      voteWelcomeBuff(activeBuff, id)
    }
  }

  if (activeBuff && pubSubEvent) {
    return renderBuffType({
      pubSubEvent,
      selectedAnswerId,
      votedAnswerId,
      setSelectedAnswerId,
      type: getBuffType(activeBuff) ?? undefined,
      buff: activeBuff,
      activeLanguage,
      engagement,
      widgetLayout,
      allowGamePoints,
      useLiveVoting,
    })
  }

  return null
}

export default BuffSwitcher
