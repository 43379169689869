import { useQuery, UseQueryOptions } from 'react-query'
import {
  getStreamById,
  getStreamBySourceId,
  getStreamByTwitchChannelId,
} from '@services/requests/stream'
import { Stream } from '@interfaces/stream'

export const getQueryKey = (
  streamId?: string,
  streamSourceId?: string,
  twitchChannelId?: string
) => {
  if (streamId) {
    return `streams.id.${streamId}`
  }

  if (streamSourceId) {
    return `streams.source-web.${streamSourceId}`
  }

  if (twitchChannelId) {
    return `streams.source-twitch.${twitchChannelId}`
  }

  throw new Error('Cannot find query key')
}

const ONE_SECOND_MS = 1000
const SIXTY_SECONDS_MS = ONE_SECOND_MS * 60

interface useActiveStreamArgs {
  /**
   * Id of the stream to find
   */
  streamId?: string
  /**
   * streamSourceId of the stream to find
   */
  streamSourceId?: string
  /**
   * Id of the twitch channel using the extension
   */
  twitchChannelId?: string

  options?: Omit<
    UseQueryOptions<Stream | undefined, unknown, Stream | undefined, string>,
    'queryKey' | 'queryFn' | 'retryDelay'
  >
}

/**
 * Gets the active stream from a given streamId and streamSourceId
 * @param {useActiveStreamArgs} args
 * @return {UseQueryResult<Stream | undefined>}
 */
export const useActiveStream = ({
  streamId,
  streamSourceId,
  twitchChannelId,
  options,
}: useActiveStreamArgs) => {
  const queryKey = getQueryKey(streamId, streamSourceId, twitchChannelId)

  return useQuery(
    queryKey,
    () => {
      if (streamId) {
        return getStreamById(streamId)
      } else if (streamSourceId) {
        return getStreamBySourceId(streamSourceId)
      } else if (twitchChannelId) {
        return getStreamByTwitchChannelId(twitchChannelId)
      }
    },
    {
      ...options,
      retryDelay: SIXTY_SECONDS_MS,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  )
}
