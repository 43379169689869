import { WidgetConfig } from '@interfaces/widget'
import { VideoPlayer } from '@interfaces/videoPlayer'
import { getVideoJSVideoPlayer } from '@utils/videoPlayer/videojs'
import { getJWPlayerVideoPlayer } from '@utils/videoPlayer/jwplayer'
import { getYoutubeVideoPlayer } from '@utils/videoPlayer/youtube'
import { getDefaultVideoPlayer } from '@utils/videoPlayer/default'
import { getTwitchWebVideoPlayer } from '@utils/videoPlayer/twitchWeb'
import { getCustomFunctionVideoPlayer } from './custom-functions'

export const getVideoPlayer = async (
  config: WidgetConfig
): Promise<VideoPlayer | undefined> => {
  if (config.videoPlayer && config.player === 'custom')
    return config.videoPlayer
  const { player } = config

  switch (player) {
    case 'videojs':
      return getVideoJSVideoPlayer(config)

    case 'custom-functions':
      return getCustomFunctionVideoPlayer(config)

    case 'jwplayer':
      return getJWPlayerVideoPlayer(config)

    case 'youtube':
      return getYoutubeVideoPlayer(config)

    case 'default':
      return getDefaultVideoPlayer(config)

    case 'twitch-web':
      return getTwitchWebVideoPlayer(config)

    case 'no-video':
    case 'twitch-mobile':
      return undefined

    case 'twitch-embed':
    default:
      throw new Error(`Not implemented ${player}`)
  }
}
