import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import IconButton from '@components/atoms/IconButton'
import { ReactComponent as SoundOnIcon } from '@assets/icons/sound-on.svg'
import { ReactComponent as SoundOffIcon } from '@assets/icons/sound-off.svg'
import { ReactComponent as SnoozeIcon } from '@assets/icons/snooze.svg'
import { ReactComponent as LineIcon } from '@assets/icons/line.svg'
import { ReactComponent as LanguageIcon } from '@assets/icons/language.svg'
import { IStreamSettingsProps } from './types'
import { languagesMap } from '@utils/language'

const StreamSettings: FC<IStreamSettingsProps> = ({
  muted,
  showMute = true,
  snoozed,
  locale,
  onLanguageClick,
  onMuteClick,
  onSnoozeClick,
  disabled = false,
  disabledSounds = false,
}) => {
  const { t } = useTranslation()

  const handleSnoozeClick = () => {
    onSnoozeClick && onSnoozeClick(!snoozed)
  }

  const handleMuteClick = () => {
    onMuteClick && onMuteClick(!muted)
  }

  const handleLanguageClick = () => {
    onLanguageClick && onLanguageClick()
  }

  return (
    <div className="flex flex-col justify-between mt-3 rounded-md">
      <IconButton
        className="text-left mb-3"
        active={snoozed}
        content={
          <SnoozeIcon className="w-4 h-4" fill={'var(--color-label-50)'} />
        }
        disabled={disabled}
        text={t('settings.snooze')}
        data-testid="stream-settings__snooze"
        onClick={handleSnoozeClick}
      />
      {showMute && (
        <IconButton
          className="text-left mb-3"
          active={!muted}
          disabled={disabled || disabledSounds}
          content={
            muted ? (
              <SoundOffIcon
                className="w-4 h-4"
                fill={'var(--color-label-50)'}
                data-testid="stream-settings__sound-off"
              />
            ) : (
              <SoundOnIcon
                className="w-4 h-4"
                fill={'var(--color-label-50)'}
                data-testid="stream-settings__sound-on"
              />
            )
          }
          text={t('settings.sound')}
          onClick={handleMuteClick}
          data-testid="stream-settings__mute"
        />
      )}

      <IconButton
        fullRowClickable
        className="text-left mb-3"
        active={false}
        content={
          <LanguageIcon fill={'var(--color-label-50)'} className="w-4 h-4" />
        }
        disabled={disabled}
        text={languagesMap[locale].name}
        rightActionIcon={
          <LineIcon
            stroke={'var(--color-label-50)'}
            className="w-4 h-4 modal__back-button"
          />
        }
        onClick={handleLanguageClick}
        data-testid="stream-settings__language"
      />
    </div>
  )
}

export default StreamSettings
