import { useContext, CSSProperties } from 'react'
import { ConfigContext } from '@services/providers/ConfigProvider'
import { Orientation, Position } from '@interfaces/streamConfig'
import { WidgetLayout } from '@interfaces/widget'
import { useLocalPosition } from '@utils/hooks/useLocalPosition'
import getPlatform from '@utils/getPlatform'

export enum StreamConfigTarget {
  LEADERBOARD = 'leaderboard',
  VOTEABLE = 'voteable',
}

export const useStreamConfigPosition = (
  target: StreamConfigTarget
): CSSProperties => {
  const { streamConfig, widgetConfig } = useContext(ConfigContext)
  const desiredPlatform = getPlatform(widgetConfig)
  const layout = widgetConfig?.layout
  // TODO: Handle correctly on portrait task
  const orientation =
    layout === WidgetLayout.DESKTOP
      ? Orientation.orientationLandscape
      : Orientation.orientationPortrait

  const { get } = useLocalPosition()

  const localPosition = get()

  // Check local position only for leaderboard
  const localPositionConfig =
    target === StreamConfigTarget.LEADERBOARD
      ? localPosition?.find(({ platform }) => {
          return platform === desiredPlatform
        })
      : undefined

  const positionConfig =
    localPositionConfig ??
    streamConfig?.positionConfig.find(({ platform }) => {
      return platform === desiredPlatform
    })

  if (!positionConfig) return {}

  const positioning = positionConfig?.[orientation]

  if (!positioning?.[target]) return {}

  const horizontalPosition = positioning[target].hAlign
  const verticalPosition = positioning[target].vAlign
  const horizontalOffset = positioning[target].hOffset
  const verticalOffset = positioning[target].vOffset

  const xAxis =
    horizontalPosition === Position.LEFT
      ? { left: `${horizontalOffset}%` }
      : { right: `${horizontalOffset}%` }

  const yAxis =
    verticalPosition === Position.TOP
      ? { top: `${verticalOffset}%` }
      : { bottom: `${verticalOffset}%` }

  let yTransform = {}

  if (
    target === StreamConfigTarget.VOTEABLE &&
    orientation === Orientation.orientationPortrait
  ) {
    const multiplier = verticalPosition === Position.TOP ? -1 : 1
    const value = multiplier * verticalOffset
    yTransform = {
      transform: `translateY(${value}%)`,
    }
  }

  return {
    ...yAxis,
    ...xAxis,
    ...yTransform,
  }
}
