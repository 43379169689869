export interface TokenPayload {
  aud: string
  cid: number
  exp: number
  iat: number
  iss: string
  role: number
  sub: string
  uid: number

  /**
   * Users id?
   */
  uuid: string
}

export interface LoginResponse {
  token: string
}

export enum OIDCProvider {
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
}
