import React, { FC } from 'react'
import { ButtonProps, ButtonBackground, ButtonVariant } from './types'

const disabledBackgroundMap: Partial<
  Record<ButtonBackground, ButtonBackground>
> = {
  [ButtonBackground.PRIMARY]: ButtonBackground.PRIMARY_DISABLED,
}

const Button: FC<ButtonProps> = ({
  children,
  type = 'button',
  disabled = false,
  keepBgOnDisabled = false,
  className = '',
  background = ButtonBackground.DEFAULT,
  textSize = 'text-sm',
  variant = ButtonVariant.DEFAULT,
  ...otherProps
}) => {
  const backgroundMap: Partial<Record<ButtonBackground, string>> = {
    [ButtonBackground.DEFAULT]: 'bg-base-100',
    [ButtonBackground.PRIMARY]: 'bg-primary',
    [ButtonBackground.SECONDARY]: 'bg-error',
    [ButtonBackground.ERROR]: 'bg-error-shade',
    [ButtonBackground.NONE]: '',

    [ButtonBackground.PRIMARY_DISABLED]: 'bg-primary-tint',
  }

  const selectedBackground: ButtonBackground =
    disabled && !keepBgOnDisabled
      ? disabledBackgroundMap?.[background] ?? ButtonBackground.DEFAULT
      : background

  const bgColor = backgroundMap[selectedBackground]

  const textColor =
    selectedBackground === ButtonBackground.DEFAULT ||
    selectedBackground === ButtonBackground.NONE
      ? 'text-label-50'
      : selectedBackground === ButtonBackground.ERROR
      ? 'text-white'
      : 'text-label-800'

  const cursor = disabled ? 'cursor-default' : 'cursor-pointer'
  const opacity = disabled ? 'opacity-60' : 'opacity-100'

  const paddingY = variant === ButtonVariant.THIN ? 'py-2' : 'py-3'
  const paddingX = variant === ButtonVariant.WIDE ? 'px-5' : 'px-3'

  const width = variant === ButtonVariant.FULL_WIDTH ? 'w-full' : ''

  return (
    <button
      type={type}
      className={`inline-block flex-shrink-0 ${paddingX} ${paddingY} font-semibold rounded leading-none ${cursor} ${textColor} ${bgColor} ${width} ${textSize} ${className} ${opacity}`}
      disabled={disabled}
      {...otherProps}
    >
      {children}
    </button>
  )
}

export default Button
