import { ResponseError } from '@interfaces/errors'

/**
 * Class to hold labeled errors
 */
export class NamedException {
  public id: string

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public error: any

  /**
   * @param {string} id Name of the error
   * @param {any} error The error
   */
  public constructor(id: string, error: unknown) {
    this.id = id
    this.error = error
  }
}

/**
 * Helper that wraps promises to error with useful ids
 * @param {string} id
 * @param {any} method The promise to wrap
 * @return {Promise}
 */
export const withNamedError = async <T>(
  id: string,
  method: () => Promise<T>
): Promise<T> => {
  try {
    return await method()
  } catch (error) {
    throw new NamedException(id, error)
  }
}

export const isResponseError = (error?: unknown): error is ResponseError => {
  const obj = error as ResponseError
  return (
    typeof obj === 'object' &&
    obj.hasOwnProperty('error') &&
    obj.error.hasOwnProperty('code') &&
    obj.error.hasOwnProperty('msg')
  )
}
