import React, { useState } from 'react'
import Modal from '@components/molecules/Modal'
import { ConfirmationProps } from './types'
import Button, {
  ButtonBackground,
  ButtonVariant,
} from '@components/atoms/Button'

const Confirmation = ({
  text,
  title,
  closeText,
  confirmText,
  onClose,
  onConfirm,
  isLoading = false,
  centerTitle = true,
  buttonBackground = ButtonBackground.ERROR,
}: ConfirmationProps) => {
  return (
    <Modal onGoBack={onClose} title={title} centerTitle={centerTitle}>
      <div className="flex justify-center items-center flex-col px-7 w-full">
        <p className="mb-2 text-center text-sm">{text}</p>
        <Button
          data-testid="confirm-button"
          className="my-2"
          background={buttonBackground}
          variant={ButtonVariant.FULL_WIDTH}
          onClick={onConfirm}
          disabled={isLoading}
        >
          {confirmText}
        </Button>
        <Button
          data-testid="close-button"
          className="my-2"
          variant={ButtonVariant.FULL_WIDTH}
          onClick={onClose}
          disabled={isLoading}
        >
          {closeText}
        </Button>
      </div>
    </Modal>
  )
}

export default Confirmation
