import React, { useContext, useEffect, useState, useRef } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { Trans } from 'react-i18next'
import QR from 'qrcode'
import { ThemeContext } from '@services/providers/ThemeProvider'
import { logError } from '@utils/log'
import { QRCodeProps } from './types'

const TEXT_ANIMATION_DURATION = 2500

const textVariants = {
  enter: () => {
    return {
      y: '150%',
      transition: {
        duration: 0.5,
      },
    }
  },
  center: {
    y: 0,
  },
  exit: () => {
    return {
      y: '-200%',
      transition: {
        duration: 0.3,
      },
    }
  },
}

const variants = {
  enter: () => {
    return {
      y: 0,
      opacity: 1,
      transition: {
        delay: 0.1,
        type: 'spring',
        damping: 10,
        duration: 5,
      },
    }
  },
  exit: () => {
    return {
      y: 100,
      opacity: 0,
      transition: {
        type: 'spring',
        damping: 10,
        duration: 5,
      },
    }
  },
}

const textTransition = {
  y: { type: 'spring', stiffness: 250, damping: 25 },
}

const DEFAULT_TEXTS = [
  <Trans
    key="key_1"
    i18nKey="qr.scanToPlay"
    components={[
      <span key="index_1" className="text-primary font-bold"></span>,
    ]}
  />,
  <Trans
    key="key_2"
    i18nKey="qr.playAlong"
    components={[
      <span key="index_2" className="text-primary font-bold"></span>,
    ]}
  />,
  <Trans
    key="key_3"
    i18nKey="qr.winPrizes"
    components={[
      <span key="index_3" className="text-primary font-bold"></span>,
    ]}
  />,
]

const QRCode = ({ style, url, descriptions = DEFAULT_TEXTS }: QRCodeProps) => {
  const { theme, buffTheme } = useContext(ThemeContext)
  const activeTheme = buffTheme ?? theme

  const qrCanvasRef = useRef<HTMLCanvasElement>(null)

  const hasGradientBackground =
    activeTheme?.background.start && activeTheme?.background.end

  const bgStyles = hasGradientBackground
    ? 'bg-gradient-to-r'
    : 'bg-opacity-75 bg-background-start'

  const gradientStyles = hasGradientBackground
    ? 'bg-gradient-to-r from-background-start to-background-end'
    : ''

  const [index, setIndex] = useState(0)

  useEffect(() => {
    const timeout = setTimeout(() => {
      let next = index + 1
      if (next >= descriptions?.length) {
        next = 0
      }
      setIndex(next)
    }, TEXT_ANIMATION_DURATION)

    return () => clearTimeout(timeout)
  }, [index, setIndex, descriptions?.length])

  useEffect(() => {
    const element = qrCanvasRef.current
    QR.toCanvas(
      element,
      url,
      { errorCorrectionLevel: 'M', width: element?.clientWidth },
      (error) => {
        if (error) logError(error)
      }
    )
  }, [url])

  return (
    <motion.div
      className={`flex flex-col items-start absolute z-10 overflow-y-hidden pointer-events-auto`}
      data-testid="qr-code-container"
      style={style}
      variants={variants}
      initial="exit"
      animate="enter"
      exit="exit"
    >
      <div className={`flex relative rounded ${gradientStyles}`}>
        <div
          className={`flex ${bgStyles} rounded relative justify-center items-center flex-col p-2`}
          data-testid="qr-code__content"
        >
          <div className="w-30 h-30 bg-white z-10 rounded">
            <canvas
              id="qr-canvas"
              className="!w-full !h-full"
              ref={qrCanvasRef}
            />
          </div>
          {!!descriptions?.length && (
            <div className="mb-5 mt-7 w-full text-label-50 text-xs uppercase font-semibold flex items-center justify-center">
              <AnimatePresence initial={false}>
                <motion.p
                  variants={textVariants}
                  key={index}
                  initial="enter"
                  animate="center"
                  exit="exit"
                  className="absolute"
                  transition={textTransition}
                >
                  {descriptions[index]}
                </motion.p>
              </AnimatePresence>
            </div>
          )}
        </div>
      </div>
    </motion.div>
  )
}

export default QRCode
