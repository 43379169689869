import { useRef, useEffect } from 'react'

/**
 * A hook that gets the previous state or props value
 *
 * @see https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 * @param {T} value
 * @return {T | undefined}
 */
const usePreviousValue = <T,>(value: T): T | undefined => {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export default usePreviousValue
