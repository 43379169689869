import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Image from '@components/atoms/Image'
import { ReactComponent as PersonIcon } from '@assets/icons/person.svg'
import { AuthorContainerProps } from './types'

const AuthorContainer: FC<AuthorContainerProps> = ({
  authorName,
  authorPicture,
  text,
}) => {
  const { t } = useTranslation()
  const testId = 'author-container'

  return (
    <div className="flex justify-between relative" data-testid={testId}>
      <div className="flex">
        {/* Image */}
        <div className="w-10 me-2 flex items-center flex-shrink-0">
          <div className="aspect-w-4 aspect-h-5 relative w-full rounded-md overflow-hidden">
            <Image
              src={authorPicture}
              data-testid={`${testId}__image`}
              className="absolute inset-0 object-cover"
              alt={t('buff.authorAltText')}
              fallbackComponent={
                <div
                  data-testid={`${testId}__placeholder-image`}
                  className={`absolute inset-0 flex items-center justify-center bg-[#D1D6DE]`}
                >
                  <PersonIcon
                    className="text-[#323A46] w-7"
                    data-testid="profile-selection__placeholder"
                  />
                </div>
              }
            />
          </div>
        </div>
        {/* Author/text */}
        <div className="font-secondary">
          <div className="mt-1 flex items-center justify-between">
            <span className="text-xs font-semibold text-primaryText text-label-50 break-all line-clamp-2">
              {authorName}
            </span>
          </div>
          <p className="text-sm leading-tight font-bold text-label-50 sb-break-words">
            {text}
          </p>
        </div>
      </div>
    </div>
  )
}

export default AuthorContainer
