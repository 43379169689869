import React, {
  useState,
  FC,
  FormEvent,
  ReactNode,
  useRef,
  forwardRef,
  ForwardRefRenderFunction,
} from 'react'
import { useTranslation } from 'react-i18next'
import Button, { ButtonBackground } from '@components/atoms/Button'
import { ReactComponent as Cross } from '@assets/icons/cross.svg'
import { ReactComponent as Tick } from '@assets/icons/tick.svg'
import { CodeInputProps } from './types'
import MaskedInput from '../MaskedInput'
import { CustomChangeEvent, MaskedInputRef } from '../MaskedInput/types'

const CodeInputWithRef: ForwardRefRenderFunction<
  HTMLFormElement,
  CodeInputProps
> = ({ error, onSubmit, onReset, success, loading = false }, ref) => {
  const { t } = useTranslation()
  const [inputValue, setInputValue] = useState('')
  const maskedInputRef = useRef<MaskedInputRef>(null)

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
    onSubmit &&
      onSubmit(inputValue.toUpperCase().replace(/\s/g, ''), Boolean(success))
  }

  const handleReset = (event: FormEvent) => {
    event.preventDefault()
    maskedInputRef.current?.clear()
    setInputValue('')
    onReset && onReset()
  }

  let buttonText = t('friends.joinGroup')
  if (error) buttonText = t('general.reset')

  const buttonType = error ? 'reset' : 'submit'

  const buttonDisabled = !(
    inputValue?.length === 8 ||
    inputValue?.length === 12 ||
    error ||
    success
  )
  const buttonBackground = error
    ? ButtonBackground.ERROR
    : ButtonBackground.PRIMARY

  const descriptionFontSize = error || success ? 'text-sm' : 'text-xs'

  let description: ReactNode = ''
  if (error) {
    description = (
      <>
        <Cross
          className="me-2 inline-block"
          data-testid="code-input__error-icon"
        />
        {error}
      </>
    )
  } else if (success) {
    description = (
      <>
        <Tick
          className="me-2 inline-block text-primary"
          data-testid="code-input__success-icon"
        />
        {success}
      </>
    )
  }

  let inputBorderColor = 'border-base-200'
  if (error) {
    inputBorderColor = 'border-error'
  } else if (success) {
    inputBorderColor = 'border-success'
  }

  const handleChange = (event: CustomChangeEvent<HTMLInputElement>) =>
    setInputValue(event.target.rawValue)

  return (
    <form onSubmit={handleSubmit} onReset={handleReset} ref={ref}>
      <div className="flex flex-col bg-base-100 mb-1.5">
        <div className="p-2 text-sm font-bold">{t('friends.inviteCode')}</div>
        <div className="mb-2 mx-2">
          <MaskedInput
            ref={maskedInputRef}
            className={`text-2xl w-full p-0 text-center text-[#000000] py-2 border rounded ${inputBorderColor}`}
            blocks={[4, 4, 4]}
            uppercase
            value={inputValue}
            onChange={handleChange}
            data-testid="code-input__input"
          />
        </div>
      </div>

      <div className="flex items-center justify-between">
        <p className={`flex items-center leading-none ${descriptionFontSize}`}>
          {description}
        </p>

        <Button
          type={buttonType}
          className="min-w-24"
          disabled={buttonDisabled || loading}
          background={buttonBackground}
          data-testid="code-input__submit"
        >
          {buttonText}
        </Button>
      </div>
    </form>
  )
}

const CodeInput = forwardRef(CodeInputWithRef)

export default CodeInput
