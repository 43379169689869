import { useContext, useState } from 'react'
import {
  Orientation,
  Platform,
  Position,
  PositionConfig,
  Positioning,
} from '@interfaces/streamConfig'
import { WidgetLayout } from '@interfaces/widget'
import { ConfigContext } from '@services/providers/ConfigProvider'
import storage from '@utils/storage'

export const KEY = 'local-position'

/**
 * A hook used to store dragged position of an element
 * @return {object}
 */
export function useLocalPosition() {
  const [_, setUpdateCount] = useState(0)
  const { widgetConfig } = useContext(ConfigContext)
  const isTwitchMobile = widgetConfig?.player === 'twitch-mobile'
  const isTwitch = isTwitchMobile || widgetConfig?.player === 'twitch-web'
  const desiredPlatform = isTwitch ? Platform.TWITCH : Platform.WEB
  const layout = widgetConfig?.layout
  const orientation =
    layout === WidgetLayout.DESKTOP
      ? Orientation.orientationLandscape
      : Orientation.orientationPortrait

  const save = (position: Partial<Positioning>) => {
    const positioning: Partial<PositionConfig>[] = [
      {
        platform: desiredPlatform,
        [orientation]: {
          leaderboard: {
            ...position,
            hAlign: Position.LEFT,
            vAlign: Position.TOP,
          },
        },
      },
    ]

    storage.setItem(`${KEY}`, JSON.stringify(positioning))
    setUpdateCount((value) => value + 1)
  }

  const get = (): PositionConfig[] | null => {
    const item = storage.getItem(`${KEY}`)
    return item ? JSON.parse(item) : null
  }

  const reset = () => {
    storage.removeItem(KEY)
  }

  return { save, get, reset }
}
