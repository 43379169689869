import React, {
  ChangeEvent,
  FC,
  HTMLProps,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { IRangeProps } from './types'
import './Range.css'

const Range: FC<IRangeProps> = ({
  onChange,
  onClick,
  onMouseDown,
  id,
  value,
  min,
  image,
  max,
  disabled,
  step,
  thumbSize,
  trackSize,
  thumbScale,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const [isChanging, setIsChanging] = useState(false)

  const getPercent = useMemo(() => {
    return (value: number) => ((value - min) / (max - min)) * 100
  }, [max, min])

  const changeInputProgressPercentStyle = useCallback(() => {
    inputRef?.current?.style.setProperty(
      '--webkitProgressPercent',
      `${getPercent(Number(value))}%`
    )
  }, [getPercent, value])

  useEffect(() => {
    changeInputProgressPercentStyle()
    const inputElement = inputRef.current

    const handleUpAndLeave = () => setIsChanging(false)
    const handleDown = () => setIsChanging(true)

    inputElement?.addEventListener(
      'pointermove',
      changeInputProgressPercentStyle
    )
    inputElement?.addEventListener('pointerdown', handleDown)
    inputElement?.addEventListener('pointerup', handleUpAndLeave)
    inputElement?.addEventListener('pointerleave', handleUpAndLeave)
    return () => {
      inputElement?.removeEventListener(
        'pointermove',
        changeInputProgressPercentStyle
      )
      inputElement?.removeEventListener('pointerdown', handleDown)
      inputElement?.removeEventListener('pointerup', handleUpAndLeave)
      inputElement?.removeEventListener('pointerleave', handleUpAndLeave)
    }
  }, [isChanging, changeInputProgressPercentStyle])

  useEffect(() => {
    if (!inputRef?.current) return
    changeInputProgressPercentStyle()
  }, [inputRef, changeInputProgressPercentStyle])

  useEffect(() => {
    inputRef?.current?.style.setProperty('--image', image ?? '')

    if (!!image) {
      inputRef?.current?.style.setProperty('--thumbBg', 'transparent')
      inputRef?.current?.style.setProperty('--boxShadow', '0')
    }

    if (!image) {
      inputRef?.current?.style.setProperty('--thumbBg', 'var(--color-white)')
      inputRef?.current?.style.setProperty(
        '--boxShadow',
        'var(--defaultBoxShadow)'
      )
    }
  }, [image])

  useEffect(() => {
    if (thumbSize) {
      inputRef?.current?.style.setProperty('--thumbSize', `${thumbSize}`)
    }
  }, [thumbSize])

  useEffect(() => {
    if (trackSize) {
      inputRef?.current?.style.setProperty('--trackSize', `${trackSize}`)
    }
  }, [trackSize])

  useEffect(() => {
    if (thumbScale) {
      inputRef?.current?.style.setProperty('--scale', `${thumbScale}`)
    }
  }, [thumbScale])

  const onRangeChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e)
  }

  const onRangeClick = () => {
    return onClick && onClick()
  }

  const onRangeTouchEnd = () => {
    return onClick && onClick()
  }

  const onRangeClickDown = () => {
    return onMouseDown && onMouseDown()
  }

  const inputProps: HTMLProps<HTMLInputElement> = {
    id,
    min,
    max,
    step,
    value,
    disabled,
    name: id,
    type: 'range',
    onClick: onRangeClick,
    onPointerDown: onRangeClickDown,
    onChange: onRangeChange,
    onTouchEnd: onRangeTouchEnd,
  }

  return (
    <div className={`relative w-full range`}>
      <input data-testid="range" dir="ltr" ref={inputRef} {...inputProps} />
    </div>
  )
}

export default Range
