import React, { useContext } from 'react'
import FloatingPanelTabs, {
  FloatingPanelTabsLayout,
} from '@components/molecules/FloatingPanelTabs'
import FloatingPanel from '@components/molecules/FloatingPanel'
import { WidgetLayout } from '@interfaces/widget'
import { ConfigContext } from '@services/providers/ConfigProvider'
import { FloatingPanelWrapperProps } from './types'

const FloatingPanelWrapper = ({
  sdkContainer,
  playerControlsVisible,
}: FloatingPanelWrapperProps) => {
  const { widgetConfig } = useContext(ConfigContext)
  if (widgetConfig?.layout === WidgetLayout.PORTRAIT_FULL_LEADERBOARD) {
    return (
      <div className="pointer-events-auto antialiased h-full bg-gradient-to-r from-background-start to-background-end">
        <FloatingPanelTabs layout={FloatingPanelTabsLayout.FILL_SCREEN} />
      </div>
    )
  }

  return (
    <FloatingPanel
      playerControlsVisible={playerControlsVisible}
      sdkContainer={sdkContainer}
    >
      <FloatingPanelTabs />
    </FloatingPanel>
  )
}

export default FloatingPanelWrapper
