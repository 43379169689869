import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Trans } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { ListenerParameters } from 'pubnub'
import { usePubNub } from 'pubnub-react'
import { AnimatePresence } from 'framer-motion'
import LeaderboardList from '@components/organisms/LeaderboardList'
import Button from '@components/atoms/Button'
import { UserContext } from '@services/providers/UserProvider'
import { LeaderboardContext } from '@services/providers/LeaderboardProvider'
import { ReactComponent as SettingsIcon } from '@assets/icons/friends-group-settings.svg'
import { ReactComponent as ChatIcon } from '@assets/icons/chat.svg'
import { useGetFriendGroup } from '@utils/hooks/useGetFriendGroup'
import { RoutePaths, RouteSubPaths } from '@interfaces/navigation'
import StaticOutlet from '@components/atoms/StaticOutlet'
import { getSlugByIndex } from '@utils/router'
import { friendsGroupIdPrivateRouteRegex } from '@utils/friendsGroups'
import { useGetFriendGroupMembers } from '@utils/hooks/useGetFriendGroupMembers'
import {
  Chat,
  MessageEnvelope,
  MessagePayload,
} from '@pubnub/react-chat-components'
import { useUnreadCountStore } from '@utils/hooks/stores/useUnreadCountStore'
import NotificationDot from '@components/atoms/NotificationDot'
import { useMutatePubnubToken } from '@utils/hooks/useMutatePubnubToken'
import { logError } from '@utils/log'
import { useSDKFeatures } from '@utils/hooks/useSDKFeatures'
import './FriendsLeaderboard.styles.css'

const ENABLE_CHAT = process.env.ENABLE_CHAT === 'true'

const FriendsLeaderboard = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const { unreadCount, setUnreadCount, incrementUnreadCount } =
    useUnreadCountStore()
  const [isInitializedChat, setIsInitializedChat] = useState(false)

  const { leaderboardIds } = useContext(LeaderboardContext)
  const { userId, userLanguage } = useContext(UserContext)

  const { getRedirectPath, features } = useSDKFeatures()

  const regexResult = location.pathname.match(friendsGroupIdPrivateRouteRegex)
  const groupId = regexResult ? regexResult[1] : 'UNKNOWN'

  const { mutateAsync: mutatePubnubToken } = useMutatePubnubToken()
  const { data: friendGroup } = useGetFriendGroup(groupId)
  const { data: friendGroupMembers } = useGetFriendGroupMembers(groupId)

  const timeoutRef = useRef<number>()

  const pubnub = usePubNub()

  const enableChat =
    ENABLE_CHAT && !!features?.chat?.enabled && !!features?.friends?.enabled

  const groupName = friendGroup?.name
  const membersCount = friendGroupMembers?.length ?? 1
  const groupChannel = friendGroup?.channelId

  const isGroupOwner = userId && userId === friendGroup?.owner

  const leaderboardId =
    leaderboardIds.length === 0 ? undefined : leaderboardIds[0]

  const handleLeaderboardItemClick = (entityId: string) => {
    if (entityId === userId) {
      navigate(getRedirectPath(RoutePaths.PROFILE, 'profile'))
      return
    }

    navigate(
      `/friends/${groupId}/${RouteSubPaths.EXTENDED}/${leaderboardId}/${entityId}`,
      { state: { userId, showDelete: !!isGroupOwner } }
    )
  }

  const handleLeaderboardItemDelete = (entityId: string) => {
    navigate(
      `/friends/${groupId}/${RouteSubPaths.DELETE}/${leaderboardId}/${entityId}`,
      { state: { userId } }
    )
  }

  const onSettingsClick = () => {
    navigate(`/friends/${groupId}/configuration`)
  }

  const onChatClick = () => {
    navigate(`/friends/${groupId}/chat?channel=${groupChannel}`)
  }

  const handleMessage = useCallback(
    (event: MessageEnvelope) => {
      if ((event?.message as MessagePayload)?.sender?.id !== userId) {
        incrementUnreadCount()
      }
    },
    [userId, incrementUnreadCount]
  )

  useEffect(() => {
    if (!enableChat || !pubnub) return
    const getToken = async (timeoutMS: number) => {
      timeoutRef?.current && clearTimeout(timeoutRef.current)
      timeoutRef.current = window.setTimeout(async () => {
        try {
          const token = await mutatePubnubToken()
          pubnub.setToken(token)
          const parsedToken = pubnub.parseToken(token)
          if (parsedToken.authorized_uuid) {
            pubnub.setUUID(parsedToken.authorized_uuid)
          }
          setIsInitializedChat(true)
          const expireTimeMS = parsedToken.ttl * 60 * 1000

          // Refresh token after 80% of expire time has passed
          const refreshTokenTime = expireTimeMS * 0.8
          getToken(refreshTokenTime)
        } catch (error) {
          logError(error)
        }
      }, timeoutMS)
    }

    const listener: ListenerParameters = {
      status: (statusEvent: any) => {
        if (
          statusEvent?.error &&
          statusEvent?.operation === 'PNSubscribeOperation' &&
          statusEvent?.errorData?.message === 'Token is expired.'
        ) {
          getToken(0)
        }
      },
    }

    pubnub.addListener(listener)

    getToken(0)

    return () => {
      clearTimeout(timeoutRef.current)
      pubnub.removeListener(listener)
    }
  }, [pubnub, mutatePubnubToken, enableChat])

  useEffect(() => {
    if (!groupChannel || !enableChat || !isInitializedChat || !pubnub) return
    ;(async () => {
      pubnub.subscribe({ channels: [groupChannel] })

      try {
        await pubnub.objects.setMemberships({
          channels: [
            {
              id: groupChannel,
            },
          ],
        })

        const membership = await pubnub.objects.getMemberships({
          include: {
            customFields: true,
          },
        })

        const channelData = membership?.data?.find(
          (c) => c?.channel?.id === groupChannel
        )

        const timestamp =
          (channelData?.custom?.lastReadTimetoken as number) ??
          Date.now() * 10000

        pubnub.messageCounts(
          {
            channels: [groupChannel],
            channelTimetokens: [`${timestamp}`],
          },
          (_, response) => {
            setUnreadCount(response?.channels?.[groupChannel])
          }
        )
      } catch (error) {
        logError(error)
      }
    })()

    return () => {
      pubnub.unsubscribe({ channels: [groupChannel] })
    }
  }, [pubnub, groupChannel, isInitializedChat, enableChat, setUnreadCount])

  const showChat = enableChat && !!groupChannel && isInitializedChat

  const groupNameKey = 'friends.myGroupName'

  const children = (
    <AnimatePresence>
      <StaticOutlet key={getSlugByIndex(location.pathname, -1)} />
    </AnimatePresence>
  )

  return (
    <>
      <div data-testid="group-leaderboard" className="flex flex-col h-full">
        <div
          data-testid="title"
          className="flex items-center justify-between mb-2.5 p-4 flex-shrink-0"
        >
          <span
            className="text-lg text-label-50 truncate font-bold"
            data-testid="friends-leaderboard__group-name"
          >
            <Trans
              i18nKey={groupNameKey}
              values={{ groupName, membersCount }}
            />
          </span>
          <div className="flex items-center">
            {showChat && (
              <Button
                data-testid="chat-button"
                onClick={onChatClick}
                className="inline-flex relative items-center px-2.5 h-8 me-2"
              >
                {!!unreadCount && <NotificationDot />}
                <ChatIcon className="w-5 h-5" fill="var(--color-label-50)" />
              </Button>
            )}
            <Button
              data-testid="settings-button"
              onClick={onSettingsClick}
              className="inline-flex items-center px-2.5 h-8"
            >
              <SettingsIcon className="w-5 h-5" fill="var(--color-label-50)" />
            </Button>
          </div>
        </div>
        <div
          data-testid="group_leaderboard"
          className={`m-0 relative overflow-auto hide-scrollbar flex-grow`}
        >
          {leaderboardId && (
            <LeaderboardList
              leaderboardId={leaderboardId}
              userId={userId}
              groupId={groupId}
              isGroupLeaderboard={true}
              isGroupOwner={!!isGroupOwner}
              userLanguage={userLanguage}
              onLeaderboardItemDelete={
                isGroupOwner ? handleLeaderboardItemDelete : undefined
              }
              onLeaderboardItemClick={handleLeaderboardItemClick}
              className="absolute inset-0"
            />
          )}
        </div>

        {!showChat && <>{children}</>}

        {showChat && (
          <Chat
            onMessage={handleMessage}
            currentChannel={groupChannel}
            theme="dark"
          >
            {children}
          </Chat>
        )}
      </div>
    </>
  )
}

export default FriendsLeaderboard
