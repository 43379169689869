import { useContext } from 'react'
import { ConfigContext } from '@services/providers/ConfigProvider'
import { RoutePaths } from '@interfaces/navigation'
import { UIFeatures } from '@interfaces/clientConfig'

type Feature = 'friends' | 'profile' | 'leaderboard'

interface useSDKFeaturesReturn {
  allowGamePoints?: boolean
  features?: UIFeatures
  getRedirectPath: (path: string, feature: Feature) => string
}

type useSDKFeatures = () => useSDKFeaturesReturn

export const useSDKFeatures: useSDKFeatures = () => {
  const { streamConfig, clientConfig } = useContext(ConfigContext)

  const isProfileTabEnabled = !!clientConfig?.sdk?.features?.profile?.enabled
  const isLeaderboardTabEnabled =
    !!clientConfig?.sdk?.features?.leaderboard?.enabled &&
    !!streamConfig?.config?.allowGamePoints
  const isFriendsTabEnabled =
    !!clientConfig?.sdk?.features?.friends?.enabled &&
    !!streamConfig?.config?.allowGamePoints

  const checks = {
    profile: isProfileTabEnabled,
    leaderboard: isLeaderboardTabEnabled,
    friends: isFriendsTabEnabled,
  }

  const getRedirectPath = (path: string, feature: Feature) => {
    if (checks[feature]) {
      return path
    }

    return isProfileTabEnabled ? RoutePaths.PROFILE : RoutePaths.SETTINGS
  }

  return {
    allowGamePoints: streamConfig?.config?.allowGamePoints,
    features: clientConfig?.sdk?.features,
    getRedirectPath,
  }
}
