import axios, { HeadersDefaults } from 'axios'
import { changeCaseInterceptor } from '@services/requestClient'
import storage from '@utils/storage'
import { BUFFUP_SDK_REFRESH_TOKEN, BUFFUP_SDK_TOKEN } from '../../constants'
import { refreshJwtToken } from './auth'
import { authTwitchUser } from './twitch'

interface CommonHeaderProperties extends HeadersDefaults {
  'X-Buffup-Broadcaster-ID': string
}

export const client = axios.create()

export let twitchToken = ''
export let clientName = ''

const accountName = process.env.ACCOUNT_NAME

client.defaults.headers = {
  'X-Buffup-Broadcaster-ID': accountName,
} as CommonHeaderProperties

export const updateBaseURL = (clientName: string) => {
  client.defaults.baseURL = `https://api.${clientName}.buffup.net`
}

export const updateTwitchCredentials = (token: string, client: string) => {
  twitchToken = token
  clientName = client
}

client.interceptors.request.use(
  (request) => {
    const jwtToken = storage.getItem(BUFFUP_SDK_TOKEN)
    if (request?.headers && !!jwtToken) {
      request.headers.Authorization = `Bearer ${jwtToken}`
    }

    return request
  },
  (error) => {
    return Promise.reject(error)
  }
)

// Used for users batch endpoint
client.interceptors.response.use(
  changeCaseInterceptor.responseFulfilled,
  changeCaseInterceptor.responseRejected
)

// Used for users batch endpoint
client.interceptors.response.use(
  changeCaseInterceptor.responseFulfilled,
  async (error) => {
    const config = error?.config

    if (error?.response?.status === 401 && !config?.sent) {
      config.sent = true

      if (error?.config?.headers?.bypass401) {
        storage.removeItem(BUFFUP_SDK_TOKEN)
        storage.removeItem(BUFFUP_SDK_REFRESH_TOKEN)
        window.dispatchEvent(new Event('expiredRefreshToken'))

        return Promise.reject(error)
      }

      const refreshToken = storage.getItem(BUFFUP_SDK_REFRESH_TOKEN)
      if (!refreshToken && !!twitchToken && !!clientName) {
        const details = await authTwitchUser(clientName, twitchToken)
        storage.setItem(BUFFUP_SDK_REFRESH_TOKEN, details.refreshToken)
      }

      const jwtToken = await refreshJwtToken()

      if (jwtToken) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${jwtToken}`,
        }
      }

      return client(config)
    }
    return Promise.reject(error)
  }
)
