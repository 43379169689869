/**
 * Function that dynamically imports polyfills when .init is called
 */
export const loadPolyfills = async () => {
  if (typeof IntersectionObserver === 'undefined') {
    // @ts-ignore
    await import('intersection-observer')
  }

  if (typeof Promise.allSettled === 'undefined') {
    // @ts-ignore
    const allSettled = await import('promise.allsettled')
    allSettled.default.shim()
  }
}
