import { transformLocalisedContent } from '@utils/localisedContent'
import { LanguageEnum } from '@interfaces/languages'
import { Buff, BuffType } from '@interfaces/buff'
import {
  RawVoteable,
  RawVodVoteable,
  VoteableAnswer,
  Voteable,
  VoteableAnswerInterface,
  VoteableLifecycle,
  Engagement,
  RawEngagement,
  EngagementAnswer,
  RawEngagementAnswer,
  VodVoteable,
} from '@interfaces/voteable'
import {
  Announcement,
  RawAnnouncement,
  VodAnnouncement,
} from '@interfaces/announcement'
import { RawWelcomeBuff, WelcomeBuff } from '@interfaces/welcomeBuff'
import { PubSubEventType } from '@interfaces/pubSub'
import { StreamWinner } from '@interfaces/streamWinner'

const cloneDeep = <T>(obj: T): T => {
  return JSON.parse(JSON.stringify(obj))
}

const STREAM_WINNERS_DURATION = 15
export const ANSWER_TIMEOUT = 2000

/**
 * Gets display duration for buff in seconds
 * @param {Buff | undefined} buff
 * @param {PubSubEventType | undefined} event
 * @return {number} Display duration in seconds
 */
export const getDisplayDuration = (
  buff?: Buff,
  event?: PubSubEventType
): number => {
  if (!buff) return 0
  if (isStreamWinner(buff)) {
    return buff?.displayDurationSeconds ?? STREAM_WINNERS_DURATION
  }
  if (isWelcomeBuff(buff)) return buff.displayDurationSeconds
  if (isAnnouncement(buff)) return buff.displayDurationSeconds
  if (isVoteable(buff) || isVodVoteable(buff)) {
    switch (event) {
      case PubSubEventType.VOTEABLE_CLOSE:
        return buff.summaryDurationSeconds ?? 0
      case PubSubEventType.VOTEABLE_RESOLVE:
        return buff.resultsDurationSeconds
      default:
        return buff.voteDurationSeconds
    }
  }
  return 0
}

export const getBuffType = (buff?: Buff): BuffType | null => {
  if (!buff) return null
  if (isAnnouncement(buff)) return BuffType.ANNOUNCEMENT
  if (isStreamWinner(buff)) return BuffType.STREAM_WINNER

  if (isWelcomeBuff(buff)) {
    switch (buff.answerInterface) {
      case VoteableAnswerInterface.ANSWER_INTERFACE_DISCRETE_SLIDER:
        return BuffType.EMOJI

      case VoteableAnswerInterface.ANSWER_INTERFACE_STAR_SELECT:
        return BuffType.STAR

      default:
        return BuffType.POLL
    }
  }

  if (!isVoteable(buff) && !isVodVoteable(buff)) return null

  if (
    buff.answerInterface ===
    VoteableAnswerInterface.ANSWER_INTERFACE_DISCRETE_SLIDER
  ) {
    return BuffType.EMOJI
  }

  if (
    buff.answerInterface ===
    VoteableAnswerInterface.ANSWER_INTERFACE_STAR_SELECT
  ) {
    return BuffType.STAR
  }

  if (buff.lifecycle === VoteableLifecycle.VOTEABLE_LIFECYCLE_POLL) {
    return BuffType.POLL
  }
  if (buff.lifecycle === VoteableLifecycle.VOTEABLE_LIFECYCLE_PREDICTION) {
    return BuffType.PREDICTION
  }
  if (buff.lifecycle === VoteableLifecycle.VOTEABLE_LIFECYCLE_QUIZ) {
    return BuffType.QUIZ
  }
  if (buff.lifecycle === VoteableLifecycle.VOTEABLE_LIFECYCLE_POPULAR_VOTE) {
    return BuffType.POPULAR_VOTE
  }

  return null
}

export const isVoteable = (buff: Buff): buff is Voteable => {
  return (buff as Voteable).voteableId !== undefined
}

export const isAnnouncement = (buff: Buff): buff is Announcement => {
  return (buff as Announcement).announcementId !== undefined
}

export const isWelcomeBuff = (buff: Buff): buff is WelcomeBuff => {
  return (buff as WelcomeBuff).welcomeBuffId !== undefined
}

export const isStreamWinner = (buff: Buff): buff is StreamWinner => {
  return (buff as StreamWinner).streamWinnerId !== undefined
}

export const isVodVoteable = (buff: Buff): buff is VodVoteable => {
  return (buff as VodVoteable).id !== undefined
}

export const isVodAnnouncement = (buff: Buff): buff is VodAnnouncement => {
  return (
    (buff as VodAnnouncement)?.announcementId !== undefined &&
    (buff as VodAnnouncement)?.openedAtSeconds !== undefined
  )
}

/**
 * Gets an id from the shared type Buff, which can be a Voteable, Announcement or WelcomeBuff
 * @param {Buff} buff A buff
 * @return {string} The id of the buff
 */
export const getBuffId = (buff: Buff): string => {
  if (isVoteable(buff)) return buff.voteableId
  if (isAnnouncement(buff)) return buff.announcementId
  if (isWelcomeBuff(buff)) return buff.welcomeBuffId
  if (isStreamWinner(buff)) return buff.streamWinnerId
  if (isVodVoteable(buff)) return buff.id

  throw new Error('Cannot find buff id')
}

export const transformRawVoteableToVoteable = (
  originalVoteable: RawVoteable
): Voteable => {
  const clone = cloneDeep(originalVoteable)

  const answers: VoteableAnswer[] = clone.answers.map((answer) => {
    const localisations = transformLocalisedContent(answer.localisations)
    return {
      ...answer,
      localisations,
    }
  })

  const author = {
    localisations: transformLocalisedContent(clone.author.localisations),
  }

  const question = {
    localisations: transformLocalisedContent(clone.question.localisations),
  }

  const sponsor = clone.sponsor
    ? { localisations: transformLocalisedContent(clone.sponsor.localisations) }
    : undefined

  const voteable: Voteable | VodVoteable = {
    ...clone,
    answers,
    author,
    question,
    sponsor,
  }

  return voteable
}

export const transformRawVodVoteableToVodVoteable = (
  originalVoteable: RawVodVoteable
): VodVoteable => {
  const clone = cloneDeep(originalVoteable)

  const answers: VoteableAnswer[] = clone.answers.map((answer) => {
    const localisations = transformLocalisedContent(answer.localisations)
    return {
      ...answer,
      localisations,
    }
  })

  const author = {
    localisations: transformLocalisedContent(clone.author.localisations),
  }

  const question = {
    localisations: transformLocalisedContent(clone.question.localisations),
  }

  const sponsor = clone.sponsor
    ? { localisations: transformLocalisedContent(clone.sponsor.localisations) }
    : undefined

  const voteable: VodVoteable = {
    ...clone,
    answers,
    author,
    question,
    sponsor,
  }

  return voteable
}

export const transformRawWelcomeBuffToWelcomeBuff = (
  originalVoteable: RawWelcomeBuff
): WelcomeBuff => {
  const clone = cloneDeep(originalVoteable)

  const answers: VoteableAnswer[] = clone.answers.map((answer) => {
    const localisations = transformLocalisedContent(answer.localisations)
    return {
      ...answer,
      localisations,
    }
  })

  const author = {
    localisations: transformLocalisedContent(clone.author.localisations),
  }

  const question = {
    localisations: transformLocalisedContent(clone.question.localisations),
  }

  const sponsor = clone.sponsor
    ? { localisations: transformLocalisedContent(clone.sponsor.localisations) }
    : undefined

  const voteable: WelcomeBuff = {
    ...clone,
    answers,
    author,
    question,
    sponsor,
  }

  return voteable
}

export const transformRawAnnouncementToAnnouncement = (
  originalAnnouncement: RawAnnouncement
): Announcement => {
  const clone = cloneDeep(originalAnnouncement)

  const author = {
    localisations: transformLocalisedContent(clone.author.localisations),
  }

  const content = {
    localisations: transformLocalisedContent(clone.content.localisations),
  }

  const sponsor = clone.sponsor
    ? { localisations: transformLocalisedContent(clone.sponsor.localisations) }
    : undefined

  const announcement: Announcement = {
    ...clone,
    author,
    content,
    sponsor,
  }

  return announcement
}

export const transformRawEngagementToEngagement = (
  originalEngagement: RawEngagement
): Engagement => {
  const clone = cloneDeep(originalEngagement)

  const perAnswer = clone.perAnswer.reduce(
    (acc: Record<string, EngagementAnswer>, content: RawEngagementAnswer) => {
      const totalVotes = originalEngagement.votesCounted ?? 0
      const percentage = ((content?.votesCounted ?? 0) / totalVotes) * 100
      const roundedPercentage = Math.round((percentage || 0) * 100) / 100

      acc[content.answerId] = {
        ...content,
        percentage: roundedPercentage,
      }
      return acc
    },
    {}
  )

  const engagement: Engagement = {
    ...clone,
    perAnswer,
  }

  return engagement
}

export const getBuffTitleText = (
  activeLanguage: LanguageEnum,
  buff?: Buff
): string => {
  if (!buff) return ''
  if (isStreamWinner(buff)) return ''
  if (isAnnouncement(buff) || isVodAnnouncement(buff)) {
    return buff?.content?.localisations?.[activeLanguage]?.text ?? ''
  }

  return buff.question?.localisations?.[activeLanguage]?.text ?? ''
}

export const getAuthorContent = (activeLanguage: LanguageEnum, buff?: Buff) => {
  const content =
    buff && !isStreamWinner(buff)
      ? buff?.author?.localisations?.[activeLanguage]
      : undefined

  const authorName = content ? `${content?.text}` : undefined
  const authorPicture = content?.imageUrl ?? undefined

  return {
    authorName,
    authorPicture,
  }
}

export const getSponsorContent = (
  activeLanguage: LanguageEnum,
  buff?: Buff
) => {
  const sponsorBuff = buff as
    | Voteable
    | WelcomeBuff
    | VodVoteable
    | Announcement
  const hasContent =
    sponsorBuff &&
    sponsorBuff.sponsor?.localisations?.[activeLanguage]?.imageUrl

  return hasContent
    ? sponsorBuff.sponsor?.localisations?.[activeLanguage]
    : undefined
}

export const getLateralImage = (activeLanguage: LanguageEnum, buff?: Buff) => {
  if (!buff || !isAnnouncement(buff)) return null
  return buff?.content?.localisations?.[activeLanguage]?.imageUrl || null
}

export const isLiveVotingLifecycle = (
  lifecycle: VoteableLifecycle,
  answerInterface: VoteableAnswerInterface
) => {
  return (
    [
      VoteableLifecycle.VOTEABLE_LIFECYCLE_POLL,
      VoteableLifecycle.VOTEABLE_LIFECYCLE_PREDICTION,
      VoteableLifecycle.VOTEABLE_LIFECYCLE_POPULAR_VOTE,
    ].indexOf(lifecycle) > -1 &&
    answerInterface === VoteableAnswerInterface.ANSWER_INTERFACE_PICK_ONE
  )
}
