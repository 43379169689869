import React, { FC, useContext, useEffect, useRef, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import Modal from '@components/molecules/Modal'
import PasscodeInput, {
  PasscodeInputRef,
} from '@components/atoms/PasscodeInput'
import { RoutePaths } from '@interfaces/navigation'
import { loginWithOTP, sendOTP, updateUserEmail } from '@services/requests/auth'
import InfoText from '@components/atoms/InfoText'
import { UserContext } from '@services/providers/UserProvider'
import { ReactComponent as Tick } from '@assets/icons/answer-tick.svg'

const SHOW_EMAIL_SENT_MS = 5000

const Passcode: FC = () => {
  const { setToken } = useContext(UserContext)
  const navigate = useNavigate()
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const email = decodeURIComponent(params.get('email') ?? '')
  const { t } = useTranslation()
  const onSuccess = () => {
    navigate(RoutePaths.WELCOME)
  }

  const { userId } = useContext(UserContext)

  const [loadingOTP, setLoadingOTP] = useState(false)
  const [loadingResend, setLoadingResend] = useState(false)
  const [error, setError] = useState('')
  const [showEmailSend, setShowEmailSend] = useState(false)

  const emailSentRef = useRef<number>()
  const passcodeInputRef = useRef<PasscodeInputRef>(null)

  const resetError = () => setError('')

  const setEmailSendTimeout = () => {
    setShowEmailSend(true)
    emailSentRef?.current && clearTimeout(emailSentRef.current)
    emailSentRef.current = window.setTimeout(() => {
      setShowEmailSend(false)
    }, SHOW_EMAIL_SENT_MS)
  }

  const handleSubmit = async (code: string) => {
    try {
      resetError()
      setShowEmailSend(false)
      setLoadingOTP(true)

      if (userId) {
        await updateUserEmail(userId, email, code)
        onSuccess()
      } else {
        const token = await loginWithOTP(code, email)
        setToken(token)
        onSuccess()
      }
    } catch (err) {
      const errMessage =
        err?.response?.data?.error?.code === 401
          ? t('auth.errors.invalidPasscode')
          : t('error.unknown')
      setError(errMessage)
    } finally {
      setLoadingOTP(false)
    }
  }

  const handleResend = async () => {
    try {
      resetError()
      setLoadingResend(true)
      await sendOTP(email)
      passcodeInputRef?.current?.clear()
      setEmailSendTimeout()
    } catch (err) {
      const errMessage = t('error.unknown')
      setError(errMessage)
    } finally {
      setLoadingResend(false)
    }
  }

  const handleGoBack = () => {
    if (loading) return
    navigate(-1)
  }

  useEffect(() => {
    return () => {
      emailSentRef?.current && clearTimeout(emailSentRef.current)
    }
  }, [])

  const loading = loadingOTP || loadingResend
  const disableResend = loading || showEmailSend
  const resendOpacity = disableResend ? 'opacity-60' : 'opacity-100'

  return (
    <Modal
      centerTitle
      titleClassName="normal-case"
      title={t('auth.continueWithEmail')}
      onGoBack={handleGoBack}
      data-testid="passcode"
    >
      <div className="flex flex-col">
        <div className="flex flex-col flex-1 items-center">
          <p className="mt-2 font-bold text-xl mb-1">
            {t('auth.enterPasscode')}
          </p>
          <p className="text-sm text-center mb-1 sb-break-words">
            <Trans
              i18nKey="auth.sentOTP"
              components={[<strong key={0}>{{ email }}</strong>]}
            />
          </p>
          <PasscodeInput
            ref={passcodeInputRef}
            loading={loading}
            error={error}
            onSubmit={handleSubmit}
          />
          {loadingOTP && (
            <p className="text-xs text-primary">{t('auth.checkingPasscode')}</p>
          )}
          <InfoText text={error} />
          <InfoText
            color="primary"
            Icon={Tick}
            text={showEmailSend ? t('auth.emailSent') : undefined}
          />
          <button
            disabled={disableResend}
            className="text-center mt-2"
            onClick={handleResend}
          >
            <span
              className={`text-primary underline font-bold text-sm ${resendOpacity}`}
            >
              {t('auth.resendPasscode')}
            </span>
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default Passcode
