import { BatchUser } from '@interfaces/user'
import {
  LocalisedSponsorContent,
  LocalisedContent,
} from '@interfaces/localisedContent'
import { LanguageEnum } from '@interfaces/languages'

export interface Leaderboard {
  id: string
  gameIds: string[]
  restrictEntityIds: string[]
  createdAt: string
  updatedAt?: string
  deletedAt?: string
}

export enum LeaderboardStandingMethod {
  RANKED = 'RANKED',
  PERCENTILE = 'PERCENTILE',
  NOT_PRESENT = 'NOT_PRESENT',
  REQUIRES_PAYMENT = 'REQUIRES_PAYMENT',
}

export interface LeaderboardStanding {
  leaderboardId: string
  entityId: string
  // rank: number
  // position: number

  method: LeaderboardStandingMethod
  standing: {
    totalPoints: number
    position?: number
    rank?: number
    score?: number
  }
}

export interface LeaderboardStandingResponse {
  standings: LeaderboardStanding[]
  pagination?: {
    nextPageToken: string
  }
}

export interface EntityLeaderboardStandingResponse {
  standing: LeaderboardStanding
}

export type DecoratedLeaderboardStanding = LeaderboardStanding &
  Partial<Pick<BatchUser, 'displayName' | 'profilePicture'>>

export enum PaymentCurrency {
  TWITCH_BITS = 'TWITCH_BITS',
}

export interface PaymentOptions {
  currency: PaymentCurrency
  cost: number
  sku: string
}

export interface RawLeaderboardMeta {
  id: string
  name: string
  gameIds: string[]
  restrictEntityIds: string[]
  sponsor?: {
    localisations: LocalisedSponsorContent[]
  }
  title?: {
    localisations: LocalisedContent[]
  }
  createdAt: string
  updatedAt?: string
  deletedAt?: string
  requirePayment?: {
    options: PaymentOptions[]
  }
}

export type LeaderboardMeta = Omit<RawLeaderboardMeta, 'sponsor' | 'title'> & {
  sponsor?: {
    localisations: Partial<Record<LanguageEnum, LocalisedSponsorContent>>
  }
  title?: {
    localisations: Partial<Record<LanguageEnum, LocalisedContent>>
  }
}
