import React, { FC } from 'react'

import { IInfoPill } from './types'

const InfoPill: FC<IInfoPill> = ({
  bgColor,
  shadeColor,
  text,
  textColor,
  className = '',
  deg = '180deg',
}) => {
  return (
    <span
      style={{
        color: textColor,
        background: `linear-gradient(${deg}, ${bgColor}, ${shadeColor})`,
        boxShadow: `inset 0 -10px 10px -10px #00000030`,
      }}
      className={`rounded-2xl px-2.5 py-0.5 font-semibold text-xs leading-normal ${className}`}
    >
      {text}
    </span>
  )
}

export default InfoPill
