import { useContext, useEffect, useRef, Dispatch } from 'react'
import { PubSubEventType } from '@interfaces/pubSub'
import { StreamContext } from '@services/providers/StreamProvider'
import { UserContext } from '@services/providers/UserProvider'
import { ConfigContext } from '@services/providers/ConfigProvider'
import {
  checkIfUserOnboarded,
  getWelcomeBuffById,
  onboardUser,
} from '@services/requests/onboarding'
import { useValueAsRef } from '@utils/hooks/useValueAsRef'
import usePreviousValue from '@utils/hooks/usePreviousValue'
import { UseBuffQueueReturn } from '@utils/hooks/useBuffQueue'
import { Buff } from '@interfaces/buff'
import { ANSWER_TIMEOUT, isWelcomeBuff } from '@utils/buff'
import { WelcomeBuff } from '@interfaces/welcomeBuff'
import {
  BuffState,
  BuffActionTypes,
  Actions,
} from '@services/providers/BuffProvider/state/buffReducer'
import { sendEvent } from '@utils/metrics'
import { METRIC_EVENTS } from '@interfaces/metrics'
import { logError } from '@utils/log'

export interface UseWelcomeBuffArgs {
  activeBuff?: Buff
  pubSubEventType?: PubSubEventType
  removeActiveBuff: (delay: number) => void
  queueHelpers: UseBuffQueueReturn
  dispatch: Dispatch<Actions>
  state: BuffState
}

interface UseWelcomeBuffsObj {
  voteWelcomeBuff: (buff: WelcomeBuff, answerId: string) => void
}

export const useWelcomeBuff = ({
  dispatch,
  activeBuff,
  pubSubEventType,
  removeActiveBuff,
  state,
  queueHelpers,
}: UseWelcomeBuffArgs): UseWelcomeBuffsObj => {
  const { stream } = useContext(StreamContext)
  const { streamConfig } = useContext(ConfigContext)
  const { userId } = useContext(UserContext)

  const voteTimeoutRef = useRef<number>()

  const { addQueueItem } = queueHelpers

  const { selectedAnswers, dismissedBuffs } = state
  const prevPubSubEvent = usePreviousValue(pubSubEventType)
  const prevActiveBuff = usePreviousValue(activeBuff)

  const selectedAnswersRef = useValueAsRef(selectedAnswers)
  const dismissedBuffsRef = useValueAsRef(dismissedBuffs)

  const streamId = stream?.id
  const welcomeBuffId = streamConfig?.config?.welcomeBuffId

  /**
   * A function that sends an answer for welcome buff
   *
   * @param {WelcomeBuff} buff buff
   * @param {string} answerId selected answer id
   */
  const voteWelcomeBuff = (buff: WelcomeBuff, answerId: string) => {
    const actuallyVoteForBuff = () => {
      removeActiveBuff(500)

      clearTimeout(voteTimeoutRef.current)
      voteTimeoutRef.current = 0
      return
    }

    if (!voteTimeoutRef.current) {
      voteTimeoutRef.current = window.setTimeout(() => {
        if (!activeBuff) return
        actuallyVoteForBuff()
      }, ANSWER_TIMEOUT)
    }

    dispatch({
      type: BuffActionTypes.SELECT_ANSWER,
      payload: [
        {
          answerId,
          voteableId: buff.welcomeBuffId,
        },
      ],
    })
  }

  /**
   * Checks to see if welcome buff should be added to queue
   */
  useEffect(() => {
    if (!welcomeBuffId || !streamId || !userId) return
    ;(async () => {
      try {
        const onboarded = await checkIfUserOnboarded(streamId, userId)
        if (onboarded) return

        const welcomeBuff = await getWelcomeBuffById(welcomeBuffId)

        addQueueItem({
          buff: welcomeBuff,
          visibleAt: new Date(),

          // TODO: Should change addBuff type to be a different interface, not hacking on back of PubSubEventType
          eventType: PubSubEventType.welcomeBuff,
        })
      } catch (error) {
        logError(error)
      }
    })()
  }, [addQueueItem, welcomeBuffId, streamId, userId])

  /**
   * Marks user as onboarded once welcome buff is hidden
   */
  useEffect(() => {
    ;(async () => {
      if (
        prevPubSubEvent === PubSubEventType.welcomeBuff &&
        prevActiveBuff &&
        isWelcomeBuff(prevActiveBuff) &&
        prevActiveBuff !== activeBuff &&
        userId &&
        streamId
      ) {
        const hasVoted = Boolean(
          selectedAnswersRef.current[prevActiveBuff.welcomeBuffId]
        )

        const dismissed = dismissedBuffsRef.current?.includes(
          prevActiveBuff.welcomeBuffId
        )

        sendEvent(METRIC_EVENTS.onboardingDisplayed, {
          ignore: !hasVoted && !dismissed,
          dismiss: dismissed,
        })

        // Remove welcome buff from local storage so when user log ins with new user, he can vote again
        if (hasVoted) {
          dispatch({
            type: BuffActionTypes.DELETE_ANSWER,
            payload: {
              voteableId: prevActiveBuff.welcomeBuffId,
            },
          })
        }

        await onboardUser(streamId, userId, prevActiveBuff.welcomeBuffId)
      }
    })()
  }, [
    activeBuff,
    pubSubEventType,
    streamId,
    selectedAnswersRef,
    dismissedBuffsRef,
    prevActiveBuff,
    prevPubSubEvent,
    userId,
  ])

  return {
    voteWelcomeBuff,
  }
}
