import React, { FC } from 'react'
import { SignupBannerProps } from './types'

export const SignupBanner: FC<SignupBannerProps> = ({
  text,
  buttonText,
  className,
  onClick,
}) => {
  return (
    <div
      data-testid="signup-banner"
      className={`flex flex-col p-4 bg-base-300 rounded border border-base-800 items-center text-center ${className}`}
    >
      <p className="text-label-50 text-sm">{text}</p>
      <button
        onClick={onClick}
        data-testid="signup-button"
        className="flex outline-none cursor-pointer mt-4 border-none justify-center rounded bg-primary px-4 py-2"
      >
        <span className="text-label-800 font-semibold text-sm">
          {buttonText}
        </span>
      </button>
    </div>
  )
}

export const SmallSignupBanner: FC<SignupBannerProps> = ({
  text,
  buttonText,
  className,
  onClick,
}) => {
  return (
    <div
      data-testid="signup-banner"
      className={`flex flex-row w-full py-1 pe-2 ps-4 bg-primary items-center justify-between ${className}`}
    >
      <p className="text-label-800 text-xs font-semibold">{text}</p>
      <button
        onClick={onClick}
        data-testid="signup-button"
        className="outline-none cursor-pointer flex-shrink-0 ms-2 flex border-none justify-center rounded bg-label-800 px-1.5 py-1"
      >
        <span className="text-label-700 text-xs font-semibold">
          {buttonText}
        </span>
      </button>
    </div>
  )
}
