import React, { FC } from 'react'
import { ITabPagesProps } from './types'

const TabPages: FC<ITabPagesProps> = ({ children, className = '' }) => {
  return (
    <div className={`flex flex-1 w-full overflow-hidden flex-col ${className}`}>
      <div className="flex flex-col w-full self-stretch justify-start h-full overflow-hidden flex-shrink-0 outline-none">
        {children}
      </div>
    </div>
  )
}

export default TabPages
