import React, {
  createContext,
  FC,
  useEffect,
  useContext,
  useReducer,
  Dispatch,
} from 'react'
import { StreamContext } from '@services/providers/StreamProvider'
import {
  leaderboardReducer,
  initialState,
  LeaderboardActionTypes,
  LeaderboardActions,
} from './leaderboardReducer'

export interface LeaderboardContextProps {
  leaderboardIds: string[]
  /**
   * Active leaderboard id
   */
  activeLeaderboardId: string

  /**
   * Dispatcher
   */
  dispatch: Dispatch<LeaderboardActions>
}

export const LeaderboardContext = createContext<LeaderboardContextProps>(
  {} as LeaderboardContextProps
)

const emptyArray: string[] = []

export const LeaderboardProvider: FC = ({ children }) => {
  const { stream } = useContext(StreamContext)

  const [state, dispatch] = useReducer(leaderboardReducer, initialState)

  const { activeLeaderboardId } = state
  const leaderboardIds = stream?.leaderboardIds ?? emptyArray

  const props = {
    leaderboardIds,
    activeLeaderboardId,
    dispatch,
  }

  useEffect(() => {
    if (
      !leaderboardIds.includes(activeLeaderboardId) &&
      leaderboardIds.length !== 0
    ) {
      dispatch({
        type: LeaderboardActionTypes.ACTIVE_LEADERBOARD,
        payload: {
          leaderboardId: leaderboardIds[0],
        },
      })
    }
  }, [leaderboardIds, activeLeaderboardId])

  return (
    <LeaderboardContext.Provider value={props}>
      {children}
    </LeaderboardContext.Provider>
  )
}
