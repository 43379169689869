import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Switch from '@components/atoms/Switch'
import Button, {
  ButtonBackground,
  ButtonVariant,
} from '@components/atoms/Button'
import { IGroupManagementProps } from './types'

const GroupManagement: FC<IGroupManagementProps> = ({
  onDelete,
  onLeave,
  isOwner,
  isLocked = false,
  isLoading = false,
  onLockToggle,
}) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col bg-base-200 p-2 rounded">
      {isOwner ? (
        <>
          <span className="mb-4 text-sm font-bold">
            {t('friends.manageGroupTitle')}
          </span>
          <div className="mb-2.5">
            <Switch
              isChecked={isLocked}
              text={
                isLocked ? t('friends.groupUnlock') : t('friends.groupLock')
              }
              onToggle={() => {
                onLockToggle && onLockToggle(!isLocked)
              }}
              disabled={isLoading}
            />
          </div>
          <div className="flex items-center justify-between text-sm">
            <span>{t('friends.deleteGroup')}</span>
            <Button
              onClick={onDelete}
              variant={ButtonVariant.WIDE}
              disabled={isLoading}
              background={ButtonBackground.ERROR}
              data-testid="groupManagement__button-delete"
            >
              {t('general.delete')}
            </Button>
          </div>
        </>
      ) : (
        <>
          <span className="mb-2 text-sm font-bold">
            {t('friends.groupMembershipTitle')}
          </span>
          <div className="flex items-center justify-between text-sm">
            <span>{t('friends.joinAnotherGroup')}</span>
            <Button
              onClick={onLeave}
              disabled={isLoading}
              background={ButtonBackground.ERROR}
              variant={ButtonVariant.WIDE}
              data-testid="groupManagement__button-leave"
            >
              {t('friends.leave')}
            </Button>
          </div>
        </>
      )}
    </div>
  )
}

export default GroupManagement
