import { Blocks } from './types'

export const getMaxLength = (blocks: Blocks): number =>
  blocks.reduce((previous, current) => previous + current, 0)

export const getFormattedValue = (
  value: string,
  blocks: Blocks,
  blocksLength: number
): string => {
  let result = ''
  const currentDelimiter = ' '

  // no options, normal input
  if (blocksLength === 0) return value

  blocks.forEach((length: number, index: number) => {
    if (value.length > 0) {
      const sub = value.slice(0, length)
      const rest = value.slice(length)

      result += sub

      if (sub.length === length && index < blocksLength - 1) {
        result += currentDelimiter
      }

      // update remaining string
      value = rest
    }
  })

  return result
}

export const headStr = (str: string, length: number): string => {
  return str.slice(0, length)
}

export const getPostDelimiter = (value: string, delimiter: string): string => {
  if (!value) return ''
  return value.slice(-delimiter.length) === delimiter ? delimiter : ''
}

export const getNextCursorPosition = (
  prevPos: number,
  oldValue: string,
  newValue: string,
  delimiter: string
): number => {
  // If cursor was at the end of value, just place it back.
  // Because new value could contain additional chars.
  if (oldValue.length === prevPos) {
    return newValue.length
  }

  return prevPos + getPositionOffset(prevPos, oldValue, newValue, delimiter)
}

export const getPositionOffset = (
  prevPos: number,
  oldValue: string,
  newValue: string,
  delimiter: string
): number => {
  const oldRawValue = stripDelimiters(oldValue.slice(0, prevPos), delimiter)
  const newRawValue = stripDelimiters(newValue.slice(0, prevPos), delimiter)
  const lengthOffset = oldRawValue.length - newRawValue.length

  return lengthOffset !== 0 ? lengthOffset / Math.abs(lengthOffset) : 0
}

export const stripDelimiters = (value: string, delimiter: string): string => {
  const delimiterRE = delimiter ? getDelimiterREByDelimiter(delimiter) : ''
  return value.replace(delimiterRE, '')
}

export const getDelimiterREByDelimiter = (delimiter: string): RegExp => {
  return new RegExp(delimiter.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1'), 'g')
}

export const setSelection = (
  element: any,
  position: any,
  doc: any
): unknown => {
  if (element !== getActiveElement(doc)) {
    return
  }

  // cursor is already in the end
  if (element && element.value.length <= position) {
    return
  }

  if (element.createTextRange) {
    const range = element.createTextRange()

    range.move('character', position)
    range.select()
  } else {
    try {
      element.setSelectionRange(position, position)
    } catch (e) {
      console.warn('The input element type does not support selection')
    }
  }
}

export const getActiveElement = (parent: any): unknown => {
  const activeElement = parent.activeElement
  if (activeElement && activeElement.shadowRoot) {
    return getActiveElement(activeElement.shadowRoot)
  }
  return activeElement
}

export const fixPrefixCursor = (
  el: HTMLInputElement,
  prefix: string,
  delimiter: string
): void => {
  if (!el) {
    return
  }

  const val = el.value
  const appendix = delimiter || ' '

  if (
    !el.setSelectionRange ||
    !prefix ||
    prefix.length + appendix.length <= val.length
  ) {
    return
  }

  const len = val.length * 2

  // set timeout to avoid blink
  setTimeout(function () {
    el.setSelectionRange(len, len)
  }, 1)
}

export const getPrefixStrippedValue = (
  value: string,
  prefix: string,
  prefixLength: number,
  prevResult: string,
  delimiter: string,
  noImmediatePrefix: any,
  tailPrefix: any,
  signBeforePrefix: any
): string => {
  // No prefix
  if (prefixLength === 0) {
    return value
  }

  // Value is prefix
  if (value === prefix && value !== '') {
    return ''
  }

  if (signBeforePrefix && value.slice(0, 1) == '-') {
    const prev =
      prevResult.slice(0, 1) == '-' ? prevResult.slice(1) : prevResult
    return (
      '-' +
      getPrefixStrippedValue(
        value.slice(1),
        prefix,
        prefixLength,
        prev,
        delimiter,
        noImmediatePrefix,
        tailPrefix,
        signBeforePrefix
      )
    )
  }

  // Pre result prefix string does not match pre-defined prefix
  if (prevResult.slice(0, prefixLength) !== prefix && !tailPrefix) {
    // Check if the first time user entered something
    if (noImmediatePrefix && !prevResult && value) return value
    return ''
  } else if (prevResult.slice(-prefixLength) !== prefix && tailPrefix) {
    // Check if the first time user entered something
    if (noImmediatePrefix && !prevResult && value) return value
    return ''
  }

  const prevValue = stripDelimiters(prevResult, delimiter)

  // New value has issue, someone typed in between prefix letters
  // Revert to pre value
  if (value.slice(0, prefixLength) !== prefix && !tailPrefix) {
    return prevValue.slice(prefixLength)
  } else if (value.slice(-prefixLength) !== prefix && tailPrefix) {
    return prevValue.slice(0, -prefixLength - 1)
  }

  // No issue, strip prefix for new value
  return tailPrefix ? value.slice(0, -prefixLength) : value.slice(prefixLength)
}

export const DefaultProperties = {
  assign: (target: any, opts: any) => {
    target = target || {}
    opts = opts || {}

    target.uppercase = !!opts.uppercase
    target.lowercase = !!opts.lowercase

    target.prefix = target.creditCard || target.date ? '' : opts.prefix || ''
    target.noImmediatePrefix = !!opts.noImmediatePrefix
    target.prefixLength = target.prefix.length
    target.rawValueTrimPrefix = !!opts.rawValueTrimPrefix
    target.copyDelimiter = !!opts.copyDelimiter

    target.initValue =
      opts.initValue !== undefined && opts.initValue !== null
        ? opts.initValue.toString()
        : ''

    target.delimiter =
      opts.delimiter || opts.delimiter === '' ? opts.delimiter : ' '
    target.delimiterLength = target.delimiter.length
    target.delimiterLazyShow = !!opts.delimiterLazyShow
    target.delimiters = opts.delimiters || []

    target.blocks = opts.blocks || []
    target.blocksLength = target.blocks.length

    target.root = typeof global === 'object' && global ? global : window
    target.document = opts.document || target.root.document

    target.maxLength = 0

    target.backspace = false
    target.result = ''

    target.onValueChanged = opts.onValueChanged || function () {}

    return target
  },
}
