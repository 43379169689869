import { convertKeyNames } from '@utils/object'
import { camelCase } from 'change-case'
import {
  PubSubEvent,
  GamePubSubEvent,
  StreamPubSubEvent,
  ProviderPubSubEvent,
  StreamWinnersPubSubEvent,
  PubSubEventType,
} from '@interfaces/pubSub'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const formatEvent = <T = PubSubEvent>(message: any): T => {
  const messageInfo = convertKeyNames(message, camelCase)
  return messageInfo.data
}

export const isPubSubEvent = (event: PubSubEvent): event is PubSubEvent => {
  return (event as PubSubEvent)?.id !== undefined
}

export const isGamePubSubEvent = (
  event: PubSubEvent
): event is GamePubSubEvent => {
  return (event as GamePubSubEvent)?.body?.gameId !== undefined
}

export const isStreamPubSubEvent = (
  event: PubSubEvent
): event is StreamPubSubEvent => {
  return (event as StreamPubSubEvent)?.body?.streamId !== undefined
}

export const isProviderPubSubEvent = (
  event: PubSubEvent
): event is ProviderPubSubEvent => {
  const eventNames = [
    PubSubEventType.PROVIDER_UPDATE,
    PubSubEventType.PROVIDER_REMOVE,
  ]
  return eventNames.includes(event.name)
}

export const formatEventObject = (message: unknown): PubSubEvent | false => {
  if (typeof message === 'object' && (message as any)?.data) {
    return convertKeyNames(message, camelCase).data
  }

  return false
}

export const isStreamWinnerPubSubEvent = (
  event: PubSubEvent
): event is StreamWinnersPubSubEvent => {
  return event.name === PubSubEventType.ANNOUNCE_WINNER
}
