import { MouseEvent } from 'react'

export enum ButtonBackground {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  ERROR = 'error',
  NONE = 'none',

  PRIMARY_DISABLED = 'primary-disabled',
}

export enum ButtonVariant {
  THIN = 'thin',
  DEFAULT = 'default',
  WIDE = 'wide',
  FULL_WIDTH = 'full-width',
}

export interface ButtonProps {
  /**
   * The button type
   *
   * @default 'button'
   */
  type?: 'submit' | 'reset' | 'button'

  /**
   * The button variant
   * @default ButtonVariant.DEFAULT
   */
  variant?: ButtonVariant

  /**
   * Whether the button is disabled
   *
   * @default false
   */
  disabled?: boolean

  /**
   * Whether to fallback on DEFAULT bg color when button is disabled
   *
   * @default false
   */
  keepBgOnDisabled?: boolean

  /**
   * The background colour of the button
   * @default ButtonBackground['DEFAULT']
   */
  background?: ButtonBackground

  /**
   * Custom class name
   */
  className?: string

  /**
   * Handler that will be called when button is clicked
   */
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void

  /**
   * Selection ID for test purposes.
   */
  'data-testid'?: string

  /**
   * Size of the text
   */
  textSize?: string
}
