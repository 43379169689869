import { AxiosError } from 'axios'
import { client } from '@services/requests/client'
import { RawWelcomeBuff, WelcomeBuff } from '@interfaces/welcomeBuff'
import { transformRawWelcomeBuffToWelcomeBuff } from '@utils/buff'

/**
 * Onboard user to stream and on successful onboarding add welcome buff points to user
 * @param {string} streamId
 * @param {string} userId
 * @param {string} welcomeBuffId
 * @return {Promise}
 */
export const onboardUser = (
  streamId: string,
  userId: string,
  welcomeBuffId: string
): Promise<void> => {
  return client.post(`/onboarding/onboard-user`, {
    streamId,
    userId,
    welcomeBuffId,
  })
}

/**
 * Checks to see if the user is onboarded
 * @param {string} streamId
 * @param {string} userId
 * @return {Promise<boolean>} Returns true when user is onboarded
 */
export const checkIfUserOnboarded = (
  streamId: string,
  userId: string
): Promise<boolean> => {
  return client
    .get(`/onboarding/stream/${streamId}/onboarded/${userId}`)
    .then(() => {
      return true
    })
    .catch((err: AxiosError) => {
      if (err.response?.status === 404) return false
      throw err
    })
}

/**
 * Gets a welcome buff by it's id
 * @param {string} welcomeBuffId
 * @return {Promise<WelcomeBuff>}
 */
export const getWelcomeBuffById = async (
  welcomeBuffId: string
): Promise<WelcomeBuff> => {
  const response = await client.get(`/onboarding/welcome-buff/${welcomeBuffId}`)
  const buff: RawWelcomeBuff = response.data.welcomeBuff
  return transformRawWelcomeBuffToWelcomeBuff(buff)
}
