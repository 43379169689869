import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

export const initSentry = () => {
  Sentry.init({
    dsn: process.env.SENTRY_DNS_KEY,
    integrations: [
      new BrowserTracing({
        tracePropagationTargets: [/^https:\/\/api\.\w+\.buffup\.net/],
      }),
      new Sentry.Integrations.GlobalHandlers({
        onerror: false,
        onunhandledrejection: false,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: parseInt(process.env.SENTRY_TRACKING_SAMPLE || '0.25'),
    allowUrls: [
      /https\:\/\/core-buffup-web-sdk\.s3\.eu-west-1\.amazonaws\.com/,
    ],
  })
}
