import React, { useState } from 'react'
import { useOutlet } from 'react-router-dom'
import { StaticOutletProps } from './types'

/**
 * @see https://github.com/remix-run/react-router/discussions/8008#discussioncomment-1280897
 * @param {StaticOutletProps} props
 * @return {ReactNode}
 */
const StaticOutlet = (props: StaticOutletProps) => {
  const [outlet] = useState(useOutlet())
  return outlet
}

export default StaticOutlet
