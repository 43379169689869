import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { DeepLinkingURL } from '@interfaces/deepLink'
import { ConfigContext } from '@services/providers/ConfigProvider'
import { BuffContext } from '@services/providers/BuffProvider'
import { UserContext } from '@services/providers/UserProvider'
import { RoutePaths } from '@interfaces/navigation'
import { useFloatingTabsContext } from '@services/providers/FloatingTabsProvider'
import { AudioContext } from '@services/providers/AudioProvider'
import { useSDKFeatures } from '@utils/hooks/useSDKFeatures'

/**
 * A hook used to handle announcement deep link  actions
 * @return {*}
 */
export function useInternalDeepLink() {
  const { setOpen, isOpen } = useFloatingTabsContext()
  const { isLinkedTwitchUser, token } = useContext(UserContext)
  const { widgetConfig } = useContext(ConfigContext)
  const { soundOn, toggleSound } = useContext(AudioContext)
  const { updateSnooze } = useContext(BuffContext)
  const navigate = useNavigate()
  const { getRedirectPath } = useSDKFeatures()

  const isTwitch =
    widgetConfig?.player === 'twitch-mobile' ||
    widgetConfig?.player === 'twitch-web'

  const isInternal = (link: string) => {
    const internalLinks = Object.values(DeepLinkingURL)
    return internalLinks.includes(link as DeepLinkingURL)
  }

  const onLinkTwitch = () => {
    if (isLinkedTwitchUser || !isTwitch) return
    const twitchExt = window.Twitch.ext
    twitchExt.actions.requestIdShare()
  }

  const onLogin = () => {
    if (!!token) return

    if (!isOpen) {
      setOpen(true)
    }
    navigate(RoutePaths.AUTH, {
      state: {
        onGoBack: () =>
          navigate(getRedirectPath(RoutePaths.LEADERBOARDS, 'leaderboard')),
      },
    })
  }

  const onGoBack = () => navigate(RoutePaths.SETTINGS)

  const onHandleInternalLink = (link: string) => {
    switch (link) {
      case DeepLinkingURL.twitchLink: {
        onLinkTwitch()
        return
      }

      case DeepLinkingURL.email: {
        navigate(getRedirectPath(RoutePaths.PROFILE, 'profile'))
        setOpen(true)
        return
      }

      case DeepLinkingURL.language: {
        navigate(RoutePaths.SETTINGS_LANGUAGES)
        setOpen(true)
        return
      }

      case DeepLinkingURL.friends: {
        navigate(getRedirectPath(RoutePaths.FRIENDS, 'friends')), setOpen(true)
        return
      }

      case DeepLinkingURL.mute: {
        if (soundOn) {
          toggleSound()
        }
        return
      }

      case DeepLinkingURL.unmute: {
        if (!soundOn) {
          toggleSound()
        }
        return
      }

      case DeepLinkingURL.snooze: {
        updateSnooze(true)
        return
      }

      case DeepLinkingURL.login: {
        onLogin()
        return
      }

      default:
        return
    }
  }

  const onHandleLink = (link: string) => {
    if (isInternal(link)) {
      onHandleInternalLink(link)
      return
    }

    window.open(link, '_blank')
  }

  return { isInternal, onHandleLink }
}
