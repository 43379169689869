import { useState, useEffect } from 'react'
import { LanguageEnum } from '@interfaces/languages'
import { CentrifugoEvent } from '@interfaces/pubSub'
import { BuffQueue } from './buffQueue'
import {
  UseBuffQueueArgs,
  BuffQueueType,
  BuffQueueEventName,
  BuffQueueItem,
  UseBuffQueueReturn,
  VoteState,
} from './types'

const queue = new BuffQueue({
  type: BuffQueueType.LIVE,
  snooze: false,
  language: LanguageEnum.LANGUAGE_ENGLISH,
  gameEnded: false,
})

export const useBuffQueue = ({
  type,
  snooze,
  language,
  userId,
  gameEnded,
  gameId,
  preStreamBuffFrequency,
}: UseBuffQueueArgs): UseBuffQueueReturn => {
  const [activeQueueItem, setActiveQueueItem] = useState<BuffQueueItem>()
  const [activeQueueMessage, setActiveQueueMessage] =
    useState<CentrifugoEvent>()
  const [voteState, setVoteState] = useState<VoteState>({})

  useEffect(() => {
    const handleActiveBuffChange = (queueItem: BuffQueueItem) => {
      setActiveQueueItem(queueItem)

      if (queueItem?.eventId) {
        const message = queue.queueMessages[queueItem.eventId]
        setActiveQueueMessage(message)
      }
    }

    const handleVoteStateChange = (votes: VoteState) => {
      setVoteState({ ...votes })
    }

    queue.on(BuffQueueEventName.ACTIVE_QUEUE_CHANGE, handleActiveBuffChange)
    queue.on(BuffQueueEventName.VOTE_STATE_CHANGE, handleVoteStateChange)

    // If initial active item
    if (queue.activeQueueItem) {
      setActiveQueueItem(queue.activeQueueItem)
    }

    return () => {
      queue.off(BuffQueueEventName.ACTIVE_QUEUE_CHANGE, handleActiveBuffChange)
      queue.off(BuffQueueEventName.VOTE_STATE_CHANGE, handleVoteStateChange)
    }
  }, [])

  useEffect(() => {
    queue.queueType = type
  }, [type])

  useEffect(() => {
    if (preStreamBuffFrequency) {
      queue.preStreamBuffFrequency = preStreamBuffFrequency
    }
  }, [preStreamBuffFrequency])

  useEffect(() => {
    queue.setSnooze(snooze)
  }, [snooze])

  useEffect(() => {
    queue.setLanguage(language)
  }, [language])

  useEffect(() => {
    queue.setGameEnded(gameEnded)
  }, [gameEnded])

  useEffect(() => {
    queue.setUserId(userId)
  }, [userId])

  useEffect(() => {
    queue.setGameId(gameId)
  }, [gameId])

  return {
    activeQueueItem,
    activeQueueMessage,
    voteState,
    removeActiveQueueItem: queue.removeActiveQueueItem,
    addQueueItem: queue.addQueueItem,
    clearQueue: queue.clearQueue,
    addQueueItems: queue.addQueueItems,
    updateGameTime: queue.updateGameTime,
    handleStreamPubSubMessage: queue.handleStreamPubSubMessage,
    getEngagement: queue.getEngagement,
    handleVODBuffs: queue.handleVODBuffs,
    handlePreStreamBuffs: queue.handlePreStreamBuffs,
    castVote: queue.castVote,
  }
}
