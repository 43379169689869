import { LanguageCode, LanguageEnum } from '@interfaces/languages'

interface LanguageMapValue {
  flag: string
  name: string
  locale: string
  enum: LanguageEnum
}

export const languagesMap: Record<LanguageEnum, LanguageMapValue> = {
  [LanguageEnum.LANGUAGE_ENGLISH]: {
    flag: '🇬🇧',
    name: 'English',
    locale: 'en',
    enum: LanguageEnum.LANGUAGE_ENGLISH,
  },
  [LanguageEnum.LANGUAGE_FRENCH]: {
    flag: '🇫🇷',
    name: 'Français',
    locale: 'fr',
    enum: LanguageEnum.LANGUAGE_FRENCH,
  },
  [LanguageEnum.LANGUAGE_SPANISH]: {
    flag: '🇪🇸',
    name: 'Español',
    locale: 'es',
    enum: LanguageEnum.LANGUAGE_SPANISH,
  },
  [LanguageEnum.LANGUAGE_PORTUGUESE]: {
    flag: '🇵🇹',
    name: 'Português',
    locale: 'pt',
    enum: LanguageEnum.LANGUAGE_PORTUGUESE,
  },
  [LanguageEnum.LANGUAGE_GERMAN]: {
    flag: '🇩🇪',
    name: 'Deutsch',
    locale: 'de',
    enum: LanguageEnum.LANGUAGE_GERMAN,
  },
  [LanguageEnum.LANGUAGE_ITALIAN]: {
    flag: '🇮🇹',
    name: 'Italiano',
    locale: 'it',
    enum: LanguageEnum.LANGUAGE_ITALIAN,
  },
  [LanguageEnum.LANGUAGE_GREEK]: {
    flag: '🇬🇷',
    name: 'Ελληνικά',
    locale: 'el',
    enum: LanguageEnum.LANGUAGE_GREEK,
  },
  [LanguageEnum.LANGUAGE_ARABIC]: {
    flag: '🇦🇪',
    name: 'عربي',
    locale: 'ar',
    enum: LanguageEnum.LANGUAGE_ARABIC,
  },
  [LanguageEnum.LANGUAGE_MALAYSIAN]: {
    flag: '',
    name: 'هاس ملايو',
    locale: 'ms',
    enum: LanguageEnum.LANGUAGE_MALAYSIAN,
  },
  [LanguageEnum.LANGUAGE_NORWEGIAN]: {
    flag: '',
    name: 'Norsk',
    locale: 'no',
    enum: LanguageEnum.LANGUAGE_NORWEGIAN,
  },
  [LanguageEnum.LANGUAGE_POLISH]: {
    flag: '',
    name: 'język polski',
    locale: 'po',
    enum: LanguageEnum.LANGUAGE_POLISH,
  },
  [LanguageEnum.LANGUAGE_HEBREW]: {
    flag: '',
    name: 'עברית',
    locale: 'he',
    enum: LanguageEnum.LANGUAGE_HEBREW,
  },
  [LanguageEnum.LANGUAGE_BULGARIAN]: {
    name: 'български',
    locale: 'bg',
    flag: '',
    enum: LanguageEnum.LANGUAGE_BULGARIAN,
  },
  [LanguageEnum.LANGUAGE_HUNGARIAN]: {
    name: 'Magyar',
    locale: 'hu',
    flag: '',
    enum: LanguageEnum.LANGUAGE_HUNGARIAN,
  },
  [LanguageEnum.LANGUAGE_INDONESIAN]: {
    name: 'Indonesian',
    locale: 'id',
    flag: '',
    enum: LanguageEnum.LANGUAGE_INDONESIAN,
  },
  [LanguageEnum.LANGUAGE_DUTCH]: {
    name: 'Dutch',
    locale: 'nl',
    flag: '',
    enum: LanguageEnum.LANGUAGE_DUTCH,
  },
  [LanguageEnum.LANGUAGE_SERBIAN]: {
    name: 'Srpski',
    locale: 'sr',
    flag: '',
    enum: LanguageEnum.LANGUAGE_SERBIAN,
  },
  [LanguageEnum.LANGUAGE_UNSPECIFIED]: {
    flag: '',
    name: '',
    enum: LanguageEnum.LANGUAGE_UNSPECIFIED,
    locale: 'en',
  },
}

export const transformLanguageToCode = (
  language: LanguageEnum
): LanguageCode => {
  return (languagesMap[language]?.locale ?? '') as LanguageCode
}

export const transformLanguageCodeToLanguage = (
  language: LanguageCode
): LanguageEnum => {
  return (
    Object.values(languagesMap).find((lang) => lang.locale === language)
      ?.enum ?? LanguageEnum.LANGUAGE_ENGLISH
  )
}
