import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Confirmation from '@components/organisms/Confirmation'
import { logError } from '@utils/log'
import { useMutateDeleteGroupUser } from '@utils/hooks/useMutateDeleteGroupUser'
import { queryClient } from '@utils/reactQuery'

const DeleteGroupUser = () => {
  const { entityId, leaderboardId, friendGroupId } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const { userId, navigateBackCount } = location?.state ?? {}

  const { mutateAsync: deleteUser } = useMutateDeleteGroupUser(
    friendGroupId ?? '',
    entityId ?? ''
  )

  const handleDeleteGroup = async () => {
    try {
      await deleteUser(false)
      queryClient.invalidateQueries([`friends.${friendGroupId}.members`])
      queryClient.invalidateQueries([
        'friend.leaderboard',
        leaderboardId,
        userId,
        friendGroupId,
      ])
      navigate(navigateBackCount ?? -1)
    } catch (error) {
      logError(error)
    }
  }

  const handleClose = () => {
    navigate(-1)
  }

  return (
    <Confirmation
      title={t('friends.removeUser')}
      text={t('friends.removeUserDescription')}
      closeText={t('general.cancel')}
      confirmText={t('friends.removeUser')}
      onClose={handleClose}
      onConfirm={handleDeleteGroup}
    />
  )
}

export default DeleteGroupUser
