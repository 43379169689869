import React, { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import PlayerPosition from '@components/atoms/PlayerPosition'
import { getPlayerPositionProps } from '@utils/streamWinners'
import { ConfigContext } from '@services/providers/ConfigProvider'
import { WidgetLayout } from '@interfaces/widget'
import { StreamWinnersProps } from './types'

const Placeholder = () => <div className="w-1/3" />

const getLayoutStyles = (layout: WidgetLayout) => {
  switch (layout) {
    case WidgetLayout.PORTRAIT_FULL_LEADERBOARD:
    case WidgetLayout.PORTRAIT_TOGGLE_LEADERBOARD:
      return {
        titleFontSize: 'text-sm',
        playerPositionClassName: 'w-1/3 !px-1',
        containerMargin: '-mx-1',
      }

    case WidgetLayout.DESKTOP:
    default:
      return {
        titleFontSize: 'text-md',
        playerPositionClassName: 'w-1/3',
        containerMargin: '',
      }
  }
}

const StreamWinners: FC<StreamWinnersProps> = ({ buff, widgetLayout }) => {
  const { t } = useTranslation()
  const { streamConfig } = useContext(ConfigContext)

  const { standings, userStanding } = buff

  const firstPlaceProps = getPlayerPositionProps(
    widgetLayout,
    userStanding?.entityId,
    standings?.[0],
    streamConfig?.config.hideProfilePicture
  )

  const secondPlaceProps = getPlayerPositionProps(
    widgetLayout,
    userStanding?.entityId,
    standings?.[1],
    streamConfig?.config.hideProfilePicture
  )

  const thirdPlaceProps = getPlayerPositionProps(
    widgetLayout,
    userStanding?.entityId,
    standings?.[2],
    streamConfig?.config.hideProfilePicture
  )

  const title = t('streamWinners.hereAreWinners')

  const { titleFontSize, playerPositionClassName, containerMargin } =
    getLayoutStyles(widgetLayout)

  return (
    <div data-testid="stream-winners">
      <h2
        className={`text-center font-medium ${titleFontSize} mb-3 w-full font-secondary text-label-50`}
      >
        {title}
      </h2>

      <div className={`flex ${containerMargin}`}>
        {firstPlaceProps ? (
          <PlayerPosition
            {...firstPlaceProps}
            className={playerPositionClassName}
          />
        ) : (
          <Placeholder />
        )}
        {secondPlaceProps ? (
          <PlayerPosition
            {...secondPlaceProps}
            className={playerPositionClassName}
          />
        ) : (
          <Placeholder />
        )}
        {thirdPlaceProps ? (
          <PlayerPosition
            {...thirdPlaceProps}
            className={playerPositionClassName}
          />
        ) : (
          <Placeholder />
        )}
      </div>
    </div>
  )
}

export default StreamWinners
