import { useQuery } from 'react-query'
import { getUserGroups } from '@services/requests/friendGroups'

export const useUserGroups = (userId?: string) => {
  return useQuery(
    ['user-groups', userId],
    () => {
      if (userId) return getUserGroups(userId)
      return []
    },
    {
      enabled: !!userId,
    }
  )
}
