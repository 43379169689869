import React, { FC, useContext, useEffect } from 'react'
import { ConfigContext } from '@services/providers/ConfigProvider'
import { StreamContext } from '@services/providers/StreamProvider'

const ExtensionWrapper: FC = ({ children }) => {
  const { streamConfig, widgetConfig } = useContext(ConfigContext)
  const { stream } = useContext(StreamContext)

  useEffect(() => {
    if (streamConfig?.config.renderSportBuffUi === undefined) return

    if (
      (streamConfig?.config.renderSportBuffUi === false || !stream) &&
      widgetConfig.onBuffUiHidden
    )
      widgetConfig.onBuffUiHidden()

    if (
      streamConfig?.config.renderSportBuffUi === true &&
      widgetConfig.onBuffUiVisible
    )
      widgetConfig.onBuffUiVisible()
  }, [stream, streamConfig?.config.renderSportBuffUi, widgetConfig])

  if (!streamConfig?.config.renderSportBuffUi) {
    return <></>
  }

  return <>{children}</>
}

export default ExtensionWrapper
