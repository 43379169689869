import { useQuery } from 'react-query'
import { getGroupLeaderboard } from '@services/requests/friendGroups'
import { LEADERBOARD_STANDING_STALE_TIME } from '@utils/reactQuery'

export const useGetGroupLeaderboardStandings = (
  leaderboardId?: string,
  userId?: string,
  groupId?: string
) => {
  return useQuery(
    ['friend.leaderboard', leaderboardId, userId, groupId],
    () => {
      return getGroupLeaderboard(leaderboardId, userId)
    },
    {
      enabled: Boolean(leaderboardId),
      staleTime: LEADERBOARD_STANDING_STALE_TIME,
      refetchInterval: LEADERBOARD_STANDING_STALE_TIME,
    }
  )
}
