import React, {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { Game } from '@interfaces/game'
import { Stream } from '@interfaces/stream'

export interface IDebug {
  stream?: Stream
  clientId?: number
  oldApiUrl?: string
  apiUrl?: string
  // events?: any[]
  games?: Game[]
}

export interface DebugContextProps {
  debugInfo: IDebug
  setDebugInfo: Dispatch<SetStateAction<IDebug>>
}

export const DebugContext = createContext<DebugContextProps>(
  {} as DebugContextProps
)

export const DebugProvider: FC = ({ children }) => {
  const [debugInfo, setDebugInfo] = useState<IDebug>({})

  useEffect(() => {
    setDebugInfo({
      games: [],
    })
  }, [])

  const defaultContext = {
    debugInfo,
    setDebugInfo,
  }

  return (
    <DebugContext.Provider value={defaultContext}>
      {children}
    </DebugContext.Provider>
  )
}
