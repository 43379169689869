import { Platform } from '@interfaces/streamConfig'
import { WidgetConfig } from '@interfaces/widget'

const getPlatform = (widgetConfig: WidgetConfig) => {
  if (
    widgetConfig?.player === 'twitch-mobile' ||
    widgetConfig?.player === 'twitch-web'
  ) {
    return Platform.TWITCH
  }

  if (!!widgetConfig?.tvDeviceId) {
    return Platform.TizenTV
  }

  return Platform.WEB
}

export default getPlatform
