// We can not access localStorage in incognito mode in Twitch, it throws an error.
// So we need to check if it is accessible before using it. If it is accessible, we use localStorage. If not we mock it.
// This is a workaround to use localStorage in twitch incognito mode.
let storageSupported = undefined

try {
  storageSupported = !!localStorage
} catch (err) {
  storageSupported = false
}

let storage: Record<string, string> = {}

export default storageSupported
  ? localStorage
  : {
      getItem: function (sKey: string) {
        return storage[sKey]
      },
      setItem: function (sKey: string, sValue: string) {
        return (storage[sKey] = sValue)
      },
      removeItem: function (sKey: string) {
        delete storage[sKey]
      },
      clear: function () {
        storage = {}
      },
    }
