import React, { FC } from 'react'
import { ISkeletonProps } from './types'

const Skeleton: FC<ISkeletonProps> = ({
  className = '',
  lineHeight = 'leading-none',
  ...other
}) => {
  return (
    <div
      className={`w-full bg-blue-100 rounded-sm animate-pulse ${lineHeight} ${className}`}
      {...other}
    >
      &nbsp;
    </div>
  )
}

export default Skeleton
