import React, { FC, useState } from 'react'
import { motion } from 'framer-motion'
import { Trans } from 'react-i18next'
import Star from '@components/atoms/Star'
import { IRatingProps } from './types'

const STAR_COUNT = 5
const starsArray = new Array(STAR_COUNT).fill(null)

/**
 * Gets the fill amount for a given star
 * @param {number} ratingFraction - A fraction representing how many stars to fill.
 * @param {number} starPos - The stars position
 * @return {number} A fill amount for a star
 */
function getStarFillAmount(ratingFraction: number, starPos: number): number {
  const ratingPercentage = ratingFraction * 100
  const starNum = starPos + 1
  const perStarPercentage = 100 / STAR_COUNT
  const reqPercentage = starNum * perStarPercentage

  if (ratingPercentage >= reqPercentage) {
    return 100
  } else if (ratingPercentage > reqPercentage - perStarPercentage) {
    return 50
  }

  return 0
}

const Rating: FC<IRatingProps> = ({
  value,
  disabled,
  userRating,
  onRatingChange,
}) => {
  const [hoverRating, setHoverRating] = useState(0)
  const avgRating = value ? Math.round((value + Number.EPSILON) * 2) / 2 : null

  const handleStarMouseEnter = (index: number) => {
    return () => {
      setHoverRating(index)
    }
  }

  const handleStarMouseLeave = () => {
    setHoverRating(0)
  }

  const handleStarClick = (index: number) => {
    return () => {
      onRatingChange && onRatingChange(index)
    }
  }

  const rating = avgRating ?? userRating ?? hoverRating
  const ratingFraction = rating / STAR_COUNT

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.25,
      },
    },
  }

  const showResults = avgRating !== null
  const disableStars = showResults || disabled

  return (
    <div className="w-full">
      <motion.div
        variants={container}
        initial="hidden"
        animate="show"
        className="flex w-full pb-2 px-4"
        data-testid="star-component"
      >
        {starsArray.map((_, i) => {
          const index = i + 1
          const fillAmount = getStarFillAmount(ratingFraction, i)

          return (
            <Star
              fillAmount={fillAmount}
              key={index}
              onMouseEnter={handleStarMouseEnter(index)}
              onMouseLeave={handleStarMouseLeave}
              onClick={handleStarClick(index)}
              disabled={disableStars}
              animate={Boolean(userRating)}
            />
          )
        })}
      </motion.div>
      {showResults && (
        <div className="flex justify-center w-full pt-2">
          <p
            data-testid="average-rating"
            className="text-sm pt-2 text-label-50 font-secondary font-medium"
          >
            <Trans
              values={{ rating: avgRating }}
              i18nKey="buff.averageRating"
            />
          </p>
        </div>
      )}
    </div>
  )
}

export default Rating
