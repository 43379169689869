import { WidgetConfig } from '@interfaces/widget'

interface IInitPlayerOverlayReturn {
  container: HTMLDivElement

  /**
   * Function used to remove the overlay and reset shadow dom back to default
   */
  reset: () => void
}

export const initPlayerOverlay = (
  config: WidgetConfig
): IInitPlayerOverlayReturn => {
  const { player, containerQuerySelector, videoPlayer } = config

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const shadowDOMContainer = (window as any).SportBuffShadowDOMContainer
  const buffContainer = document.createElement('div')

  buffContainer.id = 'SportBuff-buff'

  buffContainer.style.position = 'absolute'
  buffContainer.style.top = '0'
  buffContainer.style.right = '0'
  buffContainer.style.left = '0'
  buffContainer.style.bottom = '0'
  buffContainer.style.pointerEvents = 'none'

  shadowDOMContainer.shadowRoot.appendChild(buffContainer)

  if (videoPlayer) {
    videoPlayer.appendContainer(shadowDOMContainer)
  } else if (player === 'no-video') {
    const container = document.querySelector(containerQuerySelector ?? '')
    container?.appendChild(shadowDOMContainer)
  } else {
    throw new Error('No way to init player overlay')
  }

  const reset = () => {
    if (videoPlayer) {
      videoPlayer.destroy()
    } else if (player === 'no-video') {
      const container = document.querySelector(containerQuerySelector ?? '')
      container?.removeChild(shadowDOMContainer)
    }
  }

  return {
    reset,
    container: buffContainer,
  }
}
