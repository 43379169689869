import React, { FC } from 'react'
import { LateralImageProps } from './types'
import { getLateralImage } from '@utils/buff'

const LateralImage: FC<LateralImageProps> = ({
  buff,
  activeLanguage,
  'data-testid': testId = 'lateral-image',
}) => {
  const image = getLateralImage(activeLanguage, buff)
  if (!image) return null

  return (
    <div className="flex-none relative w-24" data-testid={testId}>
      <img
        className="object-cover rounded-e-md rounded-s-none absolute inset-0 w-full h-full"
        src={image}
        data-testid={`${testId}__image`}
      />
    </div>
  )
}

export default LateralImage
