import { client } from '@services/requests/client'
import { VodVoteable } from '@interfaces/voteable'
import { Announcement } from '@interfaces/announcement'
import {
  transformRawVodVoteableToVodVoteable,
  transformRawAnnouncementToAnnouncement,
} from '@utils/buff'

export const getVodVoteablesByStreamId = async (
  streamId: string
): Promise<VodVoteable[]> => {
  const data = await client.get(`/vods?filter="stream_id:${streamId}"`)
  const vod = data.data.vods[0]
  const vodData = await client.get(`vod/${vod.id}/voteables`)
  return vodData.data.voteables.map((voteable: any) =>
    transformRawVodVoteableToVodVoteable(voteable)
  )
}

export const getVodAnnouncementsByStreamId = async (
  streamId: string
): Promise<Announcement[]> => {
  const data = await client.get(`/vods?filter="stream_id:${streamId}"`)
  const vod = data.data.vods[0]
  const vodData = await client.get(`vod/${vod.id}/announcements`)
  if (!vodData.data.announcements) return []
  return vodData.data.announcements.map((announcement: any) => {
    return transformRawAnnouncementToAnnouncement(announcement)
  })
}
