import React from 'react'
import { motion } from 'framer-motion'
import { useGetLeaderboardMeta } from '@utils/hooks/useGetLeaderboardMeta'
import { getLeaderboardName } from '@utils/getLeaderboardName'
import { LeaderboardTabProps } from './types'

const tween = { duration: 0.25, type: 'tween' }

const variants = {
  active: {
    transition: { duration: 0.5 },
  },
  inactive: {
    transition: { duration: 0.3 },
  },
}

const LeaderboardTab = ({
  leaderboardId,
  isActive = false,
  activeLanguage,
}: LeaderboardTabProps) => {
  const { data: leaderboardMeta } = useGetLeaderboardMeta(leaderboardId)
  const tabName = getLeaderboardName(activeLanguage, leaderboardMeta)

  const textColor = isActive ? 'text-label-800 font-bold' : 'text-label-700'
  return (
    <>
      {isActive && (
        <motion.div
          layoutId="background-tab"
          initial={false}
          className={`absolute inset-0 px-4 rounded-md bg-secondary h-9`}
          transition={tween}
          data-testid="leaderboard-tab__background"
        />
      )}
      <motion.span
        variants={variants}
        className={`z-10 truncate select-none pointer-events-none ${textColor}`}
      >
        {tabName}
      </motion.span>
    </>
  )
}

export default LeaderboardTab
