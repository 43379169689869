import React, { FC, useContext } from 'react'
import { useNavigate, useOutlet } from 'react-router-dom'
import { LayoutGroup } from 'framer-motion'
import Tabs from '@components/molecules/Tabs'
import LeaderboardList from '@components/organisms/LeaderboardList'
import {
  LeaderboardContext,
  LeaderboardActionTypes,
} from '@services/providers/LeaderboardProvider'
import { UserContext } from '@services/providers/UserProvider'
import LeaderboardTab from '@components/atoms/LeaderboardTab'
import { ConfigContext } from '@services/providers/ConfigProvider'
import AnimatedView from '@components/atoms/AnimatedView'
import { RoutePaths } from '@interfaces/navigation'
import Carousel from '@components/molecules/Carousel'
import { SmallSignupBanner } from '@components/atoms/SignupBanner'
import { useTranslation } from 'react-i18next'
import { useSignupText } from '@utils/hooks/useSignupTexts'
import { useSDKFeatures } from '@utils/hooks/useSDKFeatures'
import { ILeaderboardProps } from './types'
import './Leaderboard.styles.css'

const LeaderBoard: FC<ILeaderboardProps> = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const outlet = useOutlet()
  const { widgetConfig } = useContext(ConfigContext)
  const { profile, userLanguage, token, userId, isLinkedTwitchUser } =
    useContext(UserContext)
  const { getRedirectPath } = useSDKFeatures()

  const { text: signUpText, actionText: signUpActionText } = useSignupText({
    placement: 'leaderboardBanner',
  })

  const isTwitch =
    widgetConfig.player === 'twitch-mobile' ||
    widgetConfig.player === 'twitch-web'

  const isUserAuthenticated = isTwitch ? isLinkedTwitchUser : Boolean(token)

  const { leaderboardIds, dispatch, activeLeaderboardId } =
    useContext(LeaderboardContext)

  const activeLeaderboardIndex = leaderboardIds.findIndex(
    (val) => val === activeLeaderboardId
  )

  const setActiveTab = (index: number) => {
    const id = leaderboardIds[index]
    dispatch({
      type: LeaderboardActionTypes.ACTIVE_LEADERBOARD,
      payload: { leaderboardId: id },
    })
  }

  const onGoBack = () => navigate(RoutePaths.LEADERBOARDS)

  const handleSignup = () => {
    navigate(RoutePaths.AUTH, {
      state: {
        onGoBack: onGoBack,
      },
    })
  }

  const handleLeaderboardItemClick = (entityId: string) => {
    if (!entityId) return

    if (profile?.id === entityId) {
      navigate(getRedirectPath(RoutePaths.PROFILE, 'profile'), {
        state: {
          prevPath: RoutePaths.LEADERBOARDS,
        },
      })
      return
    }

    navigate(
      `${RoutePaths.LEADERBOARDS_EXTENDED}/${activeLeaderboardId}/${entityId}`
    )
  }

  return (
    <LayoutGroup>
      <AnimatedView
        viewPath={RoutePaths.LEADERBOARDS}
        data-testid="leaderboard"
      >
        <Tabs
          className="h-full flex flex-col"
          activeTab={activeLeaderboardIndex}
          onTabChange={setActiveTab}
        >
          {({ selectedTab }: { selectedTab: number }) => (
            <>
              <div
                className={`flex relative items-center justify-center self-center w-11/12 my-4`}
              >
                <Carousel
                  className="tabs-fade rounded-md"
                  showLeftArrow
                  showRightArrow
                >
                  {({ addChildRef, scrollIntoItem }) => {
                    return leaderboardIds.map((id, i) => {
                      const isActive = selectedTab === i

                      return (
                        <Tabs.Tab
                          data-index={i}
                          ref={addChildRef(i)}
                          onClick={() => {
                            setActiveTab(i)
                            scrollIntoItem(i)
                          }}
                          key={i}
                          className={`flex items-center z-20 relative px-4 max-w-tab cursor-pointer flex-shrink-0 rounded-md bg-base-100 me-2 last:me-0 h-9`}
                        >
                          <LeaderboardTab
                            leaderboardId={id}
                            activeLanguage={userLanguage}
                            isActive={isActive}
                          />
                        </Tabs.Tab>
                      )
                    })
                  }}
                </Carousel>
              </div>

              {leaderboardIds.map((id, i) => {
                return (
                  <Tabs.Panel key={id} className="flex-grow flex flex-col">
                    <div
                      data-testid={`leaderboard__tab${i}`}
                      className={`relative overflow-auto hide-scrollbar h-full`}
                    >
                      <LeaderboardList
                        leaderboardId={id}
                        userId={userId}
                        className="absolute inset-0 flex flex-col"
                        userLanguage={userLanguage}
                        onLeaderboardItemClick={handleLeaderboardItemClick}
                        footer={
                          !isUserAuthenticated && (
                            <SmallSignupBanner
                              className="sticky bottom-0 z-20 mt-auto"
                              onClick={handleSignup}
                              text={signUpText}
                              buttonText={signUpActionText}
                            />
                          )
                        }
                      />
                    </div>
                  </Tabs.Panel>
                )
              })}
            </>
          )}
        </Tabs>
        {outlet}
      </AnimatedView>
    </LayoutGroup>
  )
}

export default LeaderBoard
