import { FC, SVGProps } from 'react'
import { TFunction } from 'react-i18next'
import { WelcomeBuff } from '@interfaces/welcomeBuff'
import { VodVoteable, Voteable } from '@interfaces/voteable'
import { AnswerTileStatus } from '@interfaces/answerTile'
import { VoteableAnswer } from '@interfaces/voteable'
import { getBuffType } from '@utils/buff'
import { BuffType } from '@interfaces/buff'
import { PubSubEventType } from '@interfaces/pubSub'
import { ReactComponent as Tick } from '@assets/icons/answer-tick.svg'
import { ReactComponent as Cross } from '@assets/icons/answer-cross.svg'
import { PercentageBarBackgroundVariant } from '@components/atoms/PercentageBar'

interface GetAnswerTileStatusArgs {
  buff: Voteable | WelcomeBuff | VodVoteable
  answer: VoteableAnswer
  pubSubEventType?: PubSubEventType
  selectedAnswerId?: string
  useLiveVotingEnabled?: boolean
}

export const getAnswerTileStatus = ({
  buff,
  answer,
  pubSubEventType,
  selectedAnswerId,
  useLiveVotingEnabled,
}: GetAnswerTileStatusArgs) => {
  const buffType = getBuffType(buff)
  const isSelectedAnswer = selectedAnswerId === answer.id
  const isAnswerCorrect = answer.correct

  // Handles resolved / expired states by buff type
  switch (buffType) {
    case BuffType.POLL:
      if (pubSubEventType === PubSubEventType.VOTEABLE_CLOSE) {
        return isSelectedAnswer
          ? AnswerTileStatus.SELECTED_VOTE_PERCENTAGE
          : AnswerTileStatus.VOTE_PERCENTAGE
      }
      break

    case BuffType.QUIZ:
      if (pubSubEventType === PubSubEventType.VOTEABLE_RESOLVE) {
        if (isAnswerCorrect && isSelectedAnswer) {
          return AnswerTileStatus.SELECTED_CORRECT
        }
        if (isAnswerCorrect) return AnswerTileStatus.CORRECT
        if (!isAnswerCorrect && isSelectedAnswer) {
          return AnswerTileStatus.WRONG
        }

        return AnswerTileStatus.VOTE_PERCENTAGE_TIMEOUT
      }

      break

    case BuffType.PREDICTION:
      if (pubSubEventType === PubSubEventType.VOTEABLE_RESOLVE) {
        if (isAnswerCorrect && isSelectedAnswer) {
          return AnswerTileStatus.SELECTED_CORRECT
        }
        if (isAnswerCorrect) return AnswerTileStatus.CORRECT
        if (!isAnswerCorrect && isSelectedAnswer) {
          return AnswerTileStatus.WRONG
        }

        return AnswerTileStatus.VOTE_PERCENTAGE_TIMEOUT
      }

      if (pubSubEventType === PubSubEventType.VOTEABLE_CLOSE) {
        return isSelectedAnswer
          ? AnswerTileStatus.SELECTED_VOTE_PERCENTAGE
          : AnswerTileStatus.VOTE_PERCENTAGE
      }

      break

    case BuffType.POPULAR_VOTE:
      if (pubSubEventType === PubSubEventType.VOTEABLE_RESOLVE) {
        if (isAnswerCorrect && isSelectedAnswer) {
          return AnswerTileStatus.SELECTED_WINNER
        }
        if (isAnswerCorrect) return AnswerTileStatus.WINNER
        if (!isAnswerCorrect && isSelectedAnswer) {
          return AnswerTileStatus.YOUR_PICK
        }

        return AnswerTileStatus.VOTE_PERCENTAGE_TIMEOUT
      }
      break

    default:
      throw new Error(`Cannot find status for ${buffType}`)
  }

  if (!selectedAnswerId) return AnswerTileStatus.ACTIVE

  return isSelectedAnswer
    ? AnswerTileStatus.SELECTED
    : useLiveVotingEnabled
    ? AnswerTileStatus.ACTIVE
    : AnswerTileStatus.INACTIVE
}

interface StatusStyle {
  borderBg: string
  background?: string
  text?: string
  textColour?: string
  Icon?: FC<SVGProps<SVGSVGElement>>
  progressBarColor?: string
  displaySelectedArrow?: boolean
  barBackgroundVariant?: PercentageBarBackgroundVariant
}

export const getStatusStyles = (t: TFunction, status: AnswerTileStatus) => {
  const winner = {
    borderBg: 'border-gold-shade',
    background: '!bg-gold-shade',
    text: t('answer.winner'),
    textColour: 'text-black',
    Icon: Tick,
    progressBarColor: 'bg-gold-shade',
  }

  const correct = {
    borderBg: 'border-success-shade',
    background: '!bg-success-shade',
    text: t('answer.correct'),
    textColour: 'text-white',
    Icon: Tick,
    progressBarColor: 'bg-success-shade',
    barBackgroundVariant: PercentageBarBackgroundVariant.DARK,
  }

  const map: Record<AnswerTileStatus, StatusStyle> = {
    [AnswerTileStatus.ACTIVE]: {
      borderBg: 'border-base-800',
    },
    [AnswerTileStatus.INACTIVE]: {
      borderBg: 'border-base-800',
    },
    [AnswerTileStatus.VOTE_PERCENTAGE]: {
      borderBg: 'border-base-800',
    },
    [AnswerTileStatus.VOTE_PERCENTAGE_TIMEOUT]: {
      borderBg: 'border-base-800',
    },
    [AnswerTileStatus.CORRECT]: correct,
    [AnswerTileStatus.SELECTED_CORRECT]: {
      ...correct,
      displaySelectedArrow: true,
    },
    [AnswerTileStatus.WINNER]: winner,
    [AnswerTileStatus.SELECTED_WINNER]: {
      ...winner,
      displaySelectedArrow: true,
    },
    [AnswerTileStatus.SELECTED]: {
      borderBg: 'border-primary',
      displaySelectedArrow: true,
    },
    [AnswerTileStatus.SELECTED_VOTE_PERCENTAGE]: {
      borderBg: 'border-primary',
      displaySelectedArrow: true,
      progressBarColor: 'bg-primary',
      textColour: 'text-label-200',
    },
    [AnswerTileStatus.YOUR_PICK]: {
      borderBg: 'border-primary',
      background: '!bg-primary',
      text: t('answer.yourPick'),
      textColour: 'text-label-200',
      displaySelectedArrow: true,
      progressBarColor: 'bg-primary',
    },
    [AnswerTileStatus.WRONG]: {
      borderBg: 'border-error-shade',
      background: '!bg-error-shade',
      text: t('answer.yourPick'),
      textColour: 'text-white',
      Icon: Cross,
      displaySelectedArrow: true,
      progressBarColor: 'bg-error-shade',
      barBackgroundVariant: PercentageBarBackgroundVariant.DARK,
    },
  }

  return map[status]
}

export const statusesToAlwaysDisplayStats = [
  AnswerTileStatus.SELECTED_VOTE_PERCENTAGE,
  AnswerTileStatus.VOTE_PERCENTAGE,
]

export const statusesToDisplayStatsAfterTimeout = [
  AnswerTileStatus.CORRECT,
  AnswerTileStatus.SELECTED_CORRECT,
  AnswerTileStatus.WRONG,
  AnswerTileStatus.WINNER,
  AnswerTileStatus.SELECTED_WINNER,
  AnswerTileStatus.YOUR_PICK,
  AnswerTileStatus.VOTE_PERCENTAGE_TIMEOUT,
]

export const inactiveStatuses = [
  AnswerTileStatus.INACTIVE,
  AnswerTileStatus.VOTE_PERCENTAGE,
  AnswerTileStatus.VOTE_PERCENTAGE_TIMEOUT,
]

export const statusesToDisplayPoints = [
  AnswerTileStatus.SELECTED,
  AnswerTileStatus.ACTIVE,
  AnswerTileStatus.INACTIVE,
  AnswerTileStatus.VOTE_PERCENTAGE_TIMEOUT,
  AnswerTileStatus.YOUR_PICK,
]
