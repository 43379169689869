import { create } from 'zustand'

interface UnreadCountStore {
  unreadCount: number
  setUnreadCount: (unreadCount: number) => void
  incrementUnreadCount: () => void
}

export const useUnreadCountStore = create<UnreadCountStore>((set) => ({
  unreadCount: 0,
  setUnreadCount: (unreadCount: number) => set(() => ({ unreadCount })),
  incrementUnreadCount: () =>
    set((state) => ({
      unreadCount: state.unreadCount + 1,
    })),
}))
