export interface NewConfig {
  hideMenu: boolean
  hideProfilePicture: boolean
  allowProfileEdit: boolean
  resolveVoteableAfter: number
  playVoteableSound: boolean
  voteableSound: string
  theme: string
  timeSync: boolean
  welcomeBuffId: string
  renderSportBuffUi: boolean
  allowGamePoints: boolean
  useLiveVoting: boolean
}

export enum Platform {
  WEB = 'WEB',
  TWITCH = 'TWITCH',
  ANDROID = 'ANDROID',
  IOS = 'IOS',
  TizenTV = 'TV_TIZEN_WEBOS',
  AndroidTV = 'TV_ANDROID',
}

export enum Position {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
}

export enum Orientation {
  orientationLandscape = 'orientationLandscape',
  orientationPortrait = 'orientationPortrait',
}

export interface Positioning {
  hAlign: Position
  hOffset: number
  vAlign: Position
  vOffset: number
}

export interface ElementsPositioning {
  leaderboard: Positioning
  voteable: Positioning
}

export interface PositionConfig {
  platform: Platform
  [Orientation.orientationLandscape]: ElementsPositioning
  [Orientation.orientationPortrait]: ElementsPositioning
  draggableLeaderboard: boolean
}

export interface Onboard {
  welcomeBuffId: string
}

export interface StreamConfig {
  streamId: string
  config: NewConfig
  positionConfig: PositionConfig[]
}

export interface StreamConfigResponse {
  streamConfig: StreamConfig
}
