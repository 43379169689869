import EventEmitter from 'eventemitter3'
import { VideoPlayerEvent } from '@interfaces/videoPlayer'

export const addDefaultUIHandlers = (
  container: Element,
  emitter: EventEmitter
) => {
  const handleMouseOver = () => emitter.emit(VideoPlayerEvent.SHOW_UI)
  const handleMouseOut = () => emitter.emit(VideoPlayerEvent.HIDE_UI)

  container.addEventListener('mouseover', handleMouseOver)
  container.addEventListener('mouseout', handleMouseOut)

  return () => {
    container.removeEventListener('mouseover', handleMouseOver)
    container.removeEventListener('mouseout', handleMouseOut)
  }
}
