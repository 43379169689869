import { client } from '@services/requests/client'
import { Stream } from '@interfaces/stream'

export const getStreamById = async (streamId: string): Promise<Stream> => {
  const data = await client.get(`/streams/${streamId}`)
  return data.data.stream
}

export const getStreamBySourceId = async (
  streamSourceId: string
): Promise<Stream> => {
  const filterValue = encodeURIComponent(
    `source_id:${streamSourceId};source:web`
  )
  const data = await client.get(`/streams?filter="${filterValue}"`)

  const stream = data.data.streams[0]
  if (!stream) {
    // Throwing error here so useActiveStream will go into retry loop
    throw new Error('NO_STREAM')
  }
  return stream
}

export const getStreamByTwitchChannelId = async (
  twitchChannelId: string
): Promise<Stream> => {
  const filterValue = encodeURIComponent(
    `source_id:${twitchChannelId};source:twitch`
  )
  const data = await client.get(`/streams?filter="${filterValue}"`)

  const stream = data.data.streams[0]
  if (!stream) {
    // Throwing error here so useActiveStream will go into retry loop
    throw new Error('NO_STREAM')
  }

  return stream
}
