import React, {
  createContext,
  useContext,
  FC,
  useEffect,
  useState,
  useCallback,
} from 'react'
import { StreamConfig } from '@interfaces/streamConfig'
import { getStreamConfig } from '@services/requests/streamConfig'
import { StreamContext } from '@services/providers/StreamProvider'
import { WidgetConfig } from '@interfaces/widget'
import { PubSubEventType } from '@interfaces/pubSub'
import { isStreamPubSubEvent, isPubSubEvent, formatEvent } from '@utils/pubSub'
import { useWebSocketClient } from '@utils/hooks/useWebSocketClient'
import { logError } from '@utils/log'
import { ClientConfig } from '@interfaces/clientConfig'
import { getClientConfig } from '@services/requests/clientConfig'
import { TwitchConfigState } from 'twitch-config'

export interface ConfigContextProps {
  /**
   * Stream config
   */
  streamConfig?: StreamConfig

  /**
   * Config sdk was initialised with
   */
  widgetConfig: WidgetConfig

  /**
   * Client config
   */
  clientConfig?: ClientConfig
}

export interface ConfigProviderProps {
  /**
   * Widget config
   */
  widgetConfig: WidgetConfig
}

export const ConfigContext = createContext<ConfigContextProps>(
  {} as ConfigContextProps
)

// TODO: Handle WelcomeBuff when supported by endpoint
export const ConfigProvider: FC<ConfigProviderProps> = ({
  children,
  widgetConfig,
}) => {
  const { stream } = useContext(StreamContext)
  const { subscribe, unsubscribe } = useWebSocketClient()
  const [streamConfig, setStreamConfig] = useState<StreamConfig>()
  const [clientConfig, setClientConfig] = useState<ClientConfig>()
  const configuration: TwitchConfigState = JSON.parse(
    window.Twitch?.ext?.configuration?.broadcaster?.content ?? '{}'
  )

  const fetchStreamConfig = useCallback(async (streamId: string) => {
    try {
      const streamConfigResponse = await getStreamConfig(streamId)
      setStreamConfig(streamConfigResponse)
    } catch (error) {
      logError(error)
    }
  }, [])

  const fetchClientConfig = useCallback(async () => {
    try {
      const clientConfigResponse = await getClientConfig({
        twitchClientName: configuration.clientName,
      })
      setClientConfig(clientConfigResponse)
    } catch (error) {
      logError(error)
    }
  }, [configuration.clientName])

  useEffect(() => {
    fetchClientConfig()
  }, [fetchClientConfig])

  useEffect(() => {
    if (!stream?.id) return
    fetchStreamConfig(stream.id)
  }, [stream?.id, fetchStreamConfig])

  const channel = stream ? `stream-${stream.id}` : undefined

  const handleMessage = useCallback(
    async (event: any) => {
      // Using try catch here so pubNub doesn't swallow error and not log anything
      try {
        const formattedEvent = formatEvent(event)
        if (
          !isPubSubEvent(formattedEvent) ||
          !isStreamPubSubEvent(formattedEvent)
        )
          return

        switch (formattedEvent.name) {
          case PubSubEventType.STREAM_CONFIG_CHANGE: {
            fetchStreamConfig(formattedEvent.body.streamId)
            return
          }
          default:
            return
        }
      } catch (error) {
        logError(error)
      }
    },
    [fetchStreamConfig]
  )

  useEffect(() => {
    if (!channel) return
    const handleMessageWithRef = (e: any) => handleMessage(e)

    const subscription = subscribe(channel, (message) => {
      handleMessageWithRef(message)
    })
    return () => {
      !!subscription && unsubscribe(subscription)
    }
  }, [channel, handleMessage, subscribe, unsubscribe])

  const state: ConfigContextProps = {
    streamConfig,
    widgetConfig,
    clientConfig,
  }

  return (
    <ConfigContext.Provider value={state}>{children}</ConfigContext.Provider>
  )
}
