import React, {
  createContext,
  FC,
  useContext,
  useRef,
  useState,
  useEffect,
} from 'react'
import { useCallback } from 'react'
import { ConfigContext } from '@services/providers/ConfigProvider'
import { BuffContext } from '@services/providers/BuffProvider'
import { UserContext } from '@services/providers/UserProvider'
import { PubSubEventType } from '@interfaces/pubSub'
import { sendCounterEvent } from '@utils/metrics'
import { PROPERTY_EVENTS } from '@interfaces/metrics'

export interface IAudioContextProps {
  /**
   * Is sound on or off
   */
  soundOn: boolean

  /**
   * A function that plays the audio when buff appears
   */
  playAudio: () => void

  /**
   * A function that toggles the state of sound
   */
  toggleSound: () => void
}

export const AudioContext = createContext<IAudioContextProps>(
  {} as IAudioContextProps
)

export const AudioProvider: FC = ({ children }) => {
  const [soundOn, setSoundOn] = useState<boolean>(false)
  const { streamConfig, widgetConfig } = useContext(ConfigContext)
  const { isProfileInitialized } = useContext(UserContext)
  const { activeBuff, pubSubEvent } = useContext(BuffContext)
  const volume = 0.5

  const audioHandler = useRef<HTMLAudioElement>()

  const preloadAudio = useCallback((url: string) => {
    fetch(url, {
      method: 'GET',
      mode: 'no-cors',
    }).then()
  }, [])

  useEffect(() => {
    if (!streamConfig) return
    const soundConfigEnabled =
      streamConfig.config.playVoteableSound &&
      !!streamConfig.config.voteableSound &&
      !!streamConfig.config?.renderSportBuffUi
        ? true
        : false

    if (soundConfigEnabled) {
      preloadAudio(streamConfig.config.voteableSound)
    }

    setSoundOn(soundConfigEnabled)
  }, [streamConfig, preloadAudio])

  const toggleSound = () => {
    if (!streamConfig?.config.playVoteableSound) return
    const isSoundEnabled = !soundOn

    const envetName = isSoundEnabled
      ? PROPERTY_EVENTS.muteDeactivated
      : PROPERTY_EVENTS.muteActivated
    sendCounterEvent(envetName)

    setSoundOn(isSoundEnabled)
  }

  const checkIfPlaying = () => {
    return (
      audioHandler.current &&
      audioHandler.current?.duration > 0 &&
      !audioHandler.current?.paused
    )
  }

  const pauseAudio = useCallback(() => {
    if (checkIfPlaying()) {
      audioHandler.current?.pause()
    }
  }, [audioHandler])

  const playAudio = useCallback(() => {
    if (!isProfileInitialized) return
    if (widgetConfig?.player === 'twitch-mobile') return

    if (checkIfPlaying()) {
      audioHandler.current?.pause()
    }

    if (
      soundOn &&
      streamConfig?.config?.voteableSound &&
      streamConfig?.config?.playVoteableSound &&
      streamConfig?.config?.renderSportBuffUi
    ) {
      if (!audioHandler.current) {
        audioHandler.current = new Audio()
      }

      if (audioHandler.current) {
        audioHandler.current.src = streamConfig?.config?.voteableSound as string
        audioHandler.current.volume = volume
        audioHandler.current.autoplay = true
        audioHandler.current.load()

        const soundPromise = audioHandler.current.play()

        if (soundPromise !== undefined) {
          soundPromise.catch(() => {
            pauseAudio()
          })
        }
      }
    }
  }, [
    streamConfig,
    widgetConfig,
    audioHandler,
    isProfileInitialized,
    pauseAudio,
    soundOn,
  ])

  // This is used for safari to play sound at initial click to bypass audio restriction
  // https://stackoverflow.com/a/58189464
  // https://stackoverflow.com/a/57547943
  useEffect(() => {
    if (!streamConfig?.config?.voteableSound) return
    if (widgetConfig?.player === 'twitch-mobile') return

    /**
     * Load test audio file for safari
     */
    function unlockAudio() {
      if (!audioHandler.current) {
        audioHandler.current = new Audio()
      }

      if (audioHandler.current) {
        audioHandler.current.src = streamConfig?.config?.voteableSound as string
        audioHandler.current.volume = 0
        audioHandler.current.autoplay = true

        const soundPromise = audioHandler.current.play()

        if (soundPromise !== undefined) {
          soundPromise
            .then(() => audioHandler.current?.pause())
            .catch()
            .finally(() => {
              if (audioHandler.current) {
                audioHandler.current.currentTime = 0
              }
            })
        }
      }

      document.body.removeEventListener('click', unlockAudio)
      document.body.removeEventListener('touchstart', unlockAudio)
    }

    document.body.addEventListener('click', unlockAudio)
    document.body.addEventListener('touchstart', unlockAudio)

    return () => {
      document.body.removeEventListener('click', unlockAudio)
      document.body.removeEventListener('touchstart', unlockAudio)
    }
  }, [widgetConfig, streamConfig?.config?.voteableSound])

  const props: IAudioContextProps = {
    soundOn,
    playAudio,
    toggleSound,
  }

  /**
   * Plays audio when there is an active buff
   */
  useEffect(() => {
    const typesToPlayAudio = [
      PubSubEventType.VOTEABLE_OPEN,
      PubSubEventType.ANNOUNCEMENT_OPEN,
      PubSubEventType.ANNOUNCE_WINNER,
    ]

    if (activeBuff && pubSubEvent && typesToPlayAudio.includes(pubSubEvent)) {
      playAudio()
    }
  }, [activeBuff, pubSubEvent, playAudio])

  return <AudioContext.Provider value={props}>{children}</AudioContext.Provider>
}
