import React, { FC, useEffect } from 'react'
import LeaderboardItem from '@components/molecules/LeaderboardItem'
import LeaderboardSponsor from '@components/molecules/LeaderboardSponsor'
import {
  DecoratedLeaderboardStanding,
  LeaderboardStanding,
  LeaderboardStandingMethod,
  PaymentCurrency,
} from '@interfaces/leaderboard'
import { useGetLeaderboardStandings } from '@utils/hooks/useGetLeaderboardStandings'
import { useGetGroupLeaderboardStandings } from '@utils/hooks/useGetGroupLeaderboardStandings'
import { useGetLeaderboardUserStanding } from '@utils/hooks/useGetLeaderboardUserStanding'
import { useGetLeaderboardMeta } from '@utils/hooks/useGetLeaderboardMeta'
import { getLeaderboardSponsorContent } from '@utils/leaderboard'
import VipLeaderboardBanner from '@components/atoms/VipLeaderboardBanner'
import { RoutePaths } from '@interfaces/navigation'
import { useNavigate } from 'react-router-dom'
import { queryClient } from '@utils/reactQuery'
import { LeaderboardListProps } from './types'

export const MIN_LEADERBOARD_ITEM_COUNT = 15

export const getPreparedStreamList = <T extends LeaderboardStanding>(
  leaderboard: T[],
  userRanking?: T
): (T | null)[] => {
  const list = [...leaderboard]
  if (userRanking) {
    list.push(userRanking)
  }

  const ids: string[] = []
  const dedupedList = list.filter((item) => {
    if (ids.includes(item.entityId)) return false

    ids.push(item.entityId)
    return true
  })

  dedupedList.sort((rankingA, rankingB) => {
    if (
      rankingA.standing.rank === undefined &&
      rankingB.standing.rank === undefined
    ) {
      return 0
    }

    if (rankingA.standing.rank === undefined) return 1
    if (rankingB.standing.rank === undefined) return -1
    return rankingA.standing.rank - rankingB.standing.rank
  })

  const placeholders = !dedupedList?.length
    ? new Array(MIN_LEADERBOARD_ITEM_COUNT).fill(null)
    : []

  return dedupedList.concat(placeholders)
}

const LeaderboardList: FC<LeaderboardListProps> = ({
  groupId,
  leaderboardId,
  userId,
  isGroupLeaderboard = false,
  isGroupOwner = false,
  onLeaderboardItemClick,
  onLeaderboardItemDelete,
  userLanguage,
  className = '',
  footer,
}) => {
  /**
   * isGroupLeaderboard should be a static prop, changing on fly will make react go boom
   */
  /* eslint-disable react-hooks/rules-of-hooks */
  const { data: leaderboardData } = isGroupLeaderboard
    ? useGetGroupLeaderboardStandings(leaderboardId, userId, groupId)
    : useGetLeaderboardStandings(leaderboardId)
  /* eslint-enable react-hooks/rules-of-hooks */

  const { data: userStandingData } = useGetLeaderboardUserStanding(
    leaderboardId,
    userId,
    true
  )

  const navigate = useNavigate()

  const { data: leaderboardMeta } = useGetLeaderboardMeta(leaderboardId)

  const sponsor = getLeaderboardSponsorContent(userLanguage, leaderboardMeta)

  const userStanding = userStandingData?.userStanding ?? undefined
  const list = getPreparedStreamList(
    leaderboardData?.standings ?? [],
    userStanding
  )

  useEffect(() => {
    window.Twitch?.ext?.bits.onTransactionComplete((transaction) => {
      navigate(RoutePaths.PURCHASE)
      queryClient.invalidateQueries([`leaderboard.${leaderboardId}.${userId}`])
    })

    window.Twitch?.ext?.bits.onTransactionCancelled(() => {
      console.log('transaction cancelled')
    })
  })

  const paymentOptions = leaderboardMeta?.requirePayment?.options?.find(
    (option) => option.currency === PaymentCurrency.TWITCH_BITS
  )

  const renderLeaderboardItem = (
    value: DecoratedLeaderboardStanding | null,
    i: number
  ) => {
    if (value === null) {
      return <LeaderboardItem key={`skeleton-${i}`} />
    }

    const { entityId, profilePicture, displayName, standing } = value
    const photo = profilePicture ?? ''
    const name = displayName ?? entityId

    const handleDelete =
      entityId === userId ? undefined : onLeaderboardItemDelete

    return (
      <LeaderboardItem
        key={entityId}
        id={entityId}
        points={standing.totalPoints}
        image={photo}
        username={name}
        rank={standing.position}
        percentage={standing.score}
        isActive={userId === entityId}
        isGroupOwner={isGroupOwner}
        onClick={onLeaderboardItemClick}
        onDelete={handleDelete}
      />
    )
  }

  const handleVipBannerClick = () => {
    paymentOptions?.sku && window.Twitch?.ext.bits.useBits(paymentOptions?.sku)
  }

  const showSponsor = !!sponsor && !isGroupLeaderboard

  const showVipLeaderboardBanner =
    !footer &&
    !!paymentOptions &&
    !!window.Twitch?.ext.features.isBitsEnabled &&
    userStanding?.method === LeaderboardStandingMethod.REQUIRES_PAYMENT

  return (
    <ul className={`m-0 p-0 ${className}`}>
      {showSponsor && <LeaderboardSponsor sponsor={sponsor} />}
      {list.map(renderLeaderboardItem)}
      {!!footer && footer}
      {showVipLeaderboardBanner && (
        <VipLeaderboardBanner
          amount={paymentOptions?.cost}
          className="sticky bottom-0 z-20 mt-auto"
          onClick={handleVipBannerClick}
        />
      )}
    </ul>
  )
}

export default LeaderboardList
