export type MappedTheme = Record<string, string | null>

export interface ITheme {
  variant: string
  base: {
    50: string
    100: string
    200: string
    300: string
    400: string
    500: string
    600: string
    700: string
    800: string
    900: string
  }
  label: {
    50: string
    100: string
    200: string
    400: string
    500: string
    600: string
    700: string
    800: string
    900: string
  }
  background: {
    start: string
    end: string
  }
  gradient: {
    start: string
    end: string
    background: string
  }
  timer: {
    start: string
    middle: string
    end: string
  }
  primary: string
  'primary-tint': string
  'primary-shade': string
  secondary: string
  'secondary-tint': string
  'secondary-shade': string
  bits: string
  'bits-tint': string
  'bits-shade': string
  success: string
  'success-tint': string
  'success-shade': string
  warning: string
  'warning-tint': string
  'warning-shade': string
  error: string
  'error-tint': string
  'error-shade': string
  white: string
  black: string
  gold: string
  'gold-tint': string
  'gold-shade': string
  'font-base': string
  'font-secondary': string
}

export interface DynamicTheme {
  theme: Theme
}

export interface Theme {
  name: string | ProviderTheme | Fonts
  [key: string]: string | ProviderTheme | Fonts
  fonts: string | ProviderTheme | Fonts
}

export interface FontType {
  name: string
  url: string
}

export interface FontItem {
  name: string
  regular: FontType
  semiBold: FontType
  bold: FontType
}

export interface Fonts {
  font1: FontItem
  font2: FontItem
}

export interface ProviderTheme {
  base50: string
  base100: string
  base200: string
  base300: string
  base400: string
  base500: string
  base600: string
  base700: string
  base800: string
  base900: string
  label50: string
  label100: string
  label200: string
  label400: string
  label500: string
  label600: string
  label700: string
  label800: string
  label900: string
  backgroundStart: string
  backgroundEnd: string
  gradientStart: string
  gradiendEnd: string
  gradientBackground?: string
  primaryDefault: string
  primaryTint: string
  primaryShade: string
  bitsDefault?: string
  bitsTint?: string
  bitsShade?: string
  successDefault: string
  successTint: string
  successShade: string
  warningDefault: string
  warningTint: string
  warningShade: string
  errorDefault: string
  errorTint: string
  errorShade: string
  goldDefault: string
  goldTint: string
  goldShade: string
  shadeWhite: string
  shadeBlack: string
  fonts: Fonts
}

export enum BuffSize {
  xSmall = 'xSmall',
  small = 'small',
  medium = 'medium',
  large = 'large',
}
