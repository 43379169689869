import React, { FC, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useFetchHTML, HTMLFile } from '@utils/hooks/useFetchHTML'
import { ConfigContext } from '@services/providers/ConfigProvider'
import Modal from '@components/molecules/Modal'
import InfoText from '@components/atoms/InfoText'

const PrivacyPolicy: FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { widgetConfig } = useContext(ConfigContext)

  const { error, data } = useFetchHTML(
    widgetConfig.clientName,
    HTMLFile.PRIVACY_POLICY
  )

  return (
    <Modal
      centerTitle
      titleClassName="normal-case"
      title={t('general.privacyPolicy')}
      onGoBack={() => navigate(-1)}
      data-testid="privacy-policy"
    >
      <div className="flex flex-col flex-1 px-1">
        {!!error && (
          <InfoText className="justify-center" text={t('error.unknown')} />
        )}

        {!!data && (
          <div
            dir="ltr"
            className="external-html__content"
            dangerouslySetInnerHTML={{
              __html: data,
            }}
          />
        )}
      </div>
    </Modal>
  )
}

export default PrivacyPolicy
