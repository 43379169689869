import { AxiosResponse } from 'axios'
import { client } from '@services/requests/client'
import {
  VoteableResponse,
  Voteable,
  EngagementResponse,
  Engagement,
  VoteablesResponse,
  RawVoteablesResponse,
} from '@interfaces/voteable'
import { Game } from '@interfaces/game'
import { Vote } from '@interfaces/vote'
import {
  transformRawAnnouncementToAnnouncement,
  transformRawEngagementToEngagement,
  transformRawVoteableToVoteable,
} from '@utils/buff'
import {
  Announcement,
  AnnouncementResponse,
  AnnouncementsResponse,
  RawAnnouncementsResponse,
} from '@interfaces/announcement'

export const getVoteableById = async (
  gameId: string,
  voteableId: string
): Promise<Voteable> => {
  const data: AxiosResponse<VoteableResponse> = await client.get(
    `/games/${gameId}/voteables/${voteableId}`
  )
  const voteable = data.data.voteable
  return transformRawVoteableToVoteable(voteable)
}

export const getVoteableEngagementById = async (
  gameId: string,
  voteableId: string
): Promise<Engagement> => {
  const data: AxiosResponse<EngagementResponse> = await client.get(
    `/games/${gameId}/voteables/${voteableId}/stats`
  )
  const engagement = data.data.engagement
  return transformRawEngagementToEngagement(engagement)
}

export const getAnnouncementById = async (
  gameId: string,
  announcementId: string
): Promise<Announcement> => {
  const data: AxiosResponse<AnnouncementResponse> = await client.get(
    `/games/${gameId}/announcements/${announcementId}`
  )
  const voteable = data.data.announcement
  return transformRawAnnouncementToAnnouncement(voteable)
}

export const getGameById = async (gameId: string): Promise<Game> => {
  const data = await client.get(`/games/${gameId}`)
  return data.data.game
}

/**
 * Cast a vote for a given voteable
 * @param {string} gameId
 * @param {string} voteableId
 * @param {string} answerId
 * @param {string} voterId
 * @param {string} viewedAt
 */
export const castVote = async (
  gameId: string,
  voteableId: string,
  answerId: string,

  /**
   * TODO: Remove once auth is added
   */
  voterId: string,

  /**
   * Timestamp the buff was viewed at (ISO timestamp without ms)
   * E.g 2019-08-24T14:15:22Z
   */
  viewedAt?: string
): Promise<Vote> => {
  const data = await client.post(
    `/games/${gameId}/voteables/${voteableId}/votes`,
    { answerId, voterId, viewedAt }
  )

  return data.data.vote
}

/**
 * Gets a list of voteables by the game id
 * @param {string} gameId Id of the gaem
 * @return {Promise<Voteable[]>}
 */
export const getVoteablesByGameId = async (gameId: string) => {
  const response = await client.get<RawVoteablesResponse>(
    `/games/${gameId}/voteables`
  )
  return response?.data?.voteables?.map(transformRawVoteableToVoteable) ?? []
}

/**
 * Gets a list of announcements by the game id
 * @param {string} gameId
 * @return {Promise<Announcement[]>}
 */
export const getAnnouncementsByGameId = async (gameId: string) => {
  const response = await client.get<RawAnnouncementsResponse>(
    `/games/${gameId}/announcements`
  )

  return (
    response.data?.announcements?.map(transformRawAnnouncementToAnnouncement) ??
    []
  )
}

interface VoteResponse {
  vote: Vote
}

export const getVoteStatus = async (
  gameId: string,
  voteableId: string,
  userId: string
) => {
  const response = await client.get<VoteResponse>(
    `/games/${gameId}/voteables/${voteableId}/votes/${userId}`
  )

  return response.data.vote
}
