import { useQuery } from 'react-query'
import {
  getDecoratedLeaderboardStandingByEntityId,
  getDecoratedLeaderboardStandingCurrentUser,
} from '@services/requests/leaderboards'
import { LEADERBOARD_STANDING_STALE_TIME } from '@utils/reactQuery'

export const useGetLeaderboardUserStanding = (
  leaderboardId?: string,
  userId?: string,
  isCurrentUser: boolean = false,
  enabled: boolean = true
) => {
  const fn = isCurrentUser
    ? getDecoratedLeaderboardStandingCurrentUser
    : getDecoratedLeaderboardStandingByEntityId

  return useQuery(
    `leaderboard.${leaderboardId}.${userId}`,
    async () => {
      if (userId) {
        const userStanding = await fn(leaderboardId, userId)
        return { userStanding }
      }

      return undefined
    },
    {
      enabled: Boolean(userId) && Boolean(leaderboardId) && enabled,
      staleTime: LEADERBOARD_STANDING_STALE_TIME,
      refetchInterval: LEADERBOARD_STANDING_STALE_TIME,
      retry: (failureCount, error) => {
        const e: any = error
        // Endpoint returns 404 when user has no rank
        if (e?.response?.status === 404) {
          return false
        }

        return failureCount < 3
      },
    }
  )
}
