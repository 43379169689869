import { useQuery } from 'react-query'
import { getDecoratedLeaderboardStandingById } from '@services/requests/leaderboards'
import { LEADERBOARD_STANDING_STALE_TIME } from '@utils/reactQuery'

export const useGetLeaderboardStandings = (leaderboardId: string) => {
  return useQuery(
    `leaderboard.${leaderboardId}`,
    async () => {
      const standings = await getDecoratedLeaderboardStandingById(leaderboardId)
      return { standings }
    },
    {
      enabled: Boolean(leaderboardId),
      staleTime: LEADERBOARD_STANDING_STALE_TIME,
      refetchInterval: LEADERBOARD_STANDING_STALE_TIME,
    }
  )
}
