import { motion } from 'framer-motion'
import React, { FC } from 'react'
import { InfoBubbleProps } from './types'

const InfoBubble: FC<InfoBubbleProps> = ({
  text,
  highlightText,
  highlightColor,
  className,
  bg,
  style,
  children,
  motionProps,
  'data-testid': testId,
}) => {
  return (
    <motion.div
      layout
      data-testid={testId}
      className={`text-xs ${bg} bg-opacity-90 line-clamp-2 inline-block px-2 py-1 rounded-full break-all font-medium overflow-hidden ${
        className || ''
      }`}
      style={style}
      {...motionProps}
    >
      {highlightText && (
        <span className={`font-bold me-1 inline-block ${highlightColor || ''}`}>
          {highlightText}{' '}
        </span>
      )}
      {text}
      {children}
    </motion.div>
  )
}

export default InfoBubble
