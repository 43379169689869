import React, { FC } from 'react'
import Skeleton from '@components/atoms/Skeleton'
import { LeaderboardSponsorProps } from './types'

const LeaderboardSponsor: FC<LeaderboardSponsorProps> = ({ sponsor }) => {
  const image = sponsor?.imageUrl ? (
    <img
      src={sponsor?.imageUrl}
      alt={sponsor?.imageAltText}
      className="absolute inset-0 object-cover w-full h-full"
      data-testid="leaderboard-sponsor__image"
    />
  ) : null

  const children = (
    <>
      <Skeleton
        className="absolute inset-0"
        data-testid="leaderboard-sponsor__skeleton"
      />
      {image}
    </>
  )

  return (
    <li
      className="relative before:block before:pb-[33.3333%]"
      data-testid="leaderboard-sponsor"
    >
      <div className="absolute inset-0">
        {sponsor?.linkTarget ? (
          <a
            href={sponsor.linkTarget}
            target="_blank"
            rel="noreferrer"
            data-testid="leaderboard-sponsor__link"
          >
            {children}
          </a>
        ) : (
          children
        )}
      </div>
    </li>
  )
}

export default LeaderboardSponsor
