import { useContext, useCallback } from 'react'
import { RouteHistoryContext } from '@services/providers/RouteHistoryProvider'

export const useRouteHistory = () => {
  const { routePaths } = useContext(RouteHistoryContext)

  const getPreviousRoute = useCallback(
    (userIndex: number = -1, filter?: RegExp) => {
      const array = routePaths

      const index = userIndex < 0 ? array.length + userIndex : userIndex

      const findItem = (index: number): string | null => {
        const item = array[index]
        if (!item) return null
        if (filter) {
          const passFilter = filter.test(item)
          if (passFilter) return item
          return findItem(index - 1)
        } else {
          return item
        }
      }

      const lastItem = findItem(index)
      if (!lastItem) return null
      return lastItem
    },
    [routePaths]
  )

  return {
    getPreviousRoute,
  }
}
