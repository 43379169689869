import React, {
  FC,
  createContext,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
} from 'react'
import { ConfigContext } from './ConfigProvider'

export interface FloatingTabsContextProps {
  isOpen: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

export const FloatingTabsContext = createContext<FloatingTabsContextProps>(
  {} as FloatingTabsContextProps
)

const FloatingTabsProvider: FC = ({ children }) => {
  const { widgetConfig } = useContext(ConfigContext)
  const [isOpen, setOpen] = useState(widgetConfig?.player === 'twitch-mobile')

  return (
    <FloatingTabsContext.Provider
      value={{
        isOpen,
        setOpen,
      }}
    >
      {children}
    </FloatingTabsContext.Provider>
  )
}

const useFloatingTabsContext = (): FloatingTabsContextProps => {
  const context = useContext(FloatingTabsContext)
  if (!context) {
    throw new Error(
      'useFloatingTabsContext must be used within a FloatingTabsProvider'
    )
  }
  return context
}

export { FloatingTabsProvider, useFloatingTabsContext }
