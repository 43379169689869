import { useEffect } from 'react'

export const useWindowResize = (callback: () => void): void => {
  useEffect(() => {
    let queued = false

    const handleResize = () => {
      if (queued) {
        return
      }

      queued = true
      window.requestAnimationFrame(function () {
        callback()
        queued = false
      })
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [callback])
}
