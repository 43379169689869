import React, { FC } from 'react'
import { Trans } from 'react-i18next'
import Image from '@components/atoms/Image'
import { ReactComponent as Chevron } from '@assets/icons/score-tray-chevron.svg'
import { ReactComponent as PersonIcon } from '@assets/icons/person.svg'
import { ReactComponent as SnoozeIcon } from '@assets/icons/snooze.svg'
import { PointsDeckProps } from './types'

enum PointsDeckView {
  SNOOZED = 'snoozed',
  POINTS_VISIBLE = 'points-visible',
}

const PointsDeck: FC<PointsDeckProps> = ({
  points,
  image,
  isOpen,
  onToggle,
  onProfileClick,
  snoozed = false,
  hidePoints = false,
  onUnSnoozeClick,
}) => {
  const i18nKey =
    points > 1 ? 'scoreTray.multiplePoints' : 'scoreTray.singlePoint'

  const view = snoozed ? PointsDeckView.SNOOZED : PointsDeckView.POINTS_VISIBLE

  // const chevronTransform = ''

  const chevronStyles = isOpen
    ? undefined
    : {
        transform: 'scaleY(-1)',
      }

  return (
    <>
      {view === PointsDeckView.POINTS_VISIBLE && (
        <div
          data-testid="points-deck-container"
          className="flex items-center bg-base-100 min-w-max px-1 py-0.5 rounded-t select-none"
        >
          <button
            data-testid="points-deck-profile-button"
            className="flex-shrink-0 w-5 h-5 rounded-md flex items-center justify-center bg-[#D1D6DE] overflow-hidden"
            onClick={onProfileClick}
          >
            <Image
              src={image}
              data-testid="points-deck-image"
              className="w-full h-full object-cover"
              fallbackComponent={
                <PersonIcon
                  className="w-3 h-3 text-[#212121]"
                  data-testid="points-deck__placeholder-image"
                />
              }
            />
          </button>

          {!hidePoints ? (
            <p
              data-testid="points-deck-points"
              className="font-bold font-secondary text-label-50 ms-1.5 me-1 text-sm"
            >
              <Trans
                i18nKey={i18nKey}
                values={{ points }}
                components={[
                  <sup
                    key={0}
                    className="text-xs ms-px"
                    style={{ top: '-0.3em' }}
                  />,
                ]}
              />
            </p>
          ) : (
            <div className="w-4" />
          )}

          <button
            onClick={onToggle}
            data-is-open={isOpen}
            data-testid="points-deck-right-button"
            className="w-7 h-7 cursor-pointer flex-shrink-0 flex justify-center items-center group"
          >
            <div className="bg-secondary p-0.5 rounded group-hover:bg-secondary-shade transition-colors duration-300">
              <Chevron
                fill="var(--color-base-50)"
                className={`w-4 h-4 transition-transform duration-300`}
                style={chevronStyles}
              />
            </div>
          </button>
        </div>
      )}

      {view === PointsDeckView.SNOOZED && (
        <div data-testid="points-deck-container-snoozed">
          <button
            type="button"
            onClick={onUnSnoozeClick}
            className="p-2 bg-base-200 rounded-full"
          >
            <SnoozeIcon
              className="w-4 h-4"
              fill="var(--color-label-50)"
              data-testid="points-deck__snooze"
            />
          </button>
        </div>
      )}
    </>
  )
}

export default PointsDeck
