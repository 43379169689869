/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Recursively convert the key names of an object.
 *
 * @param {Object} object the object to convert
 * @param {Function} convert the conversion function
 * @return {Object}
 */
export const convertKeyNames = (
  object: any,
  convert: (name: string) => string
): any => {
  if (object == null || typeof object !== 'object') {
    return object
  }

  if (Array.isArray(object)) {
    return object.map((value) => convertKeyNames(value, convert))
  } else {
    const converted: any = {}
    Object.keys(object).forEach((key) => {
      if (Object.keys(object).includes(key)) {
        converted[convert(key)] = convertKeyNames(object[key], convert)
      }
    })
    return converted
  }
}
/* eslint-enable @typescript-eslint/no-explicit-any */
