import EventEmitter from 'eventemitter3'
import { WidgetConfig } from '@interfaces/widget'
import { VideoPlayer, VideoPlayerEvent } from '@interfaces/videoPlayer'
import { addDefaultUIHandlers } from '@utils/videoPlayer/helpers'

export const getVideoJSVideoPlayer = async ({
  playerInstance,
}: WidgetConfig): Promise<VideoPlayer> => {
  const emitter = new EventEmitter()
  const videoJSContainer: HTMLElement = playerInstance.contentEl()

  // Wait for player to be ready
  await new Promise((resolve) => {
    playerInstance?.ready(resolve)
  })

  let sportBuffContainer: HTMLElement

  const appendContainer = (containerToAppend: HTMLElement) => {
    sportBuffContainer = containerToAppend
    videoJSContainer.appendChild(containerToAppend)
  }

  const removeUIHandlers = addDefaultUIHandlers(videoJSContainer, emitter)

  const handleTimeUpdate = () => {
    emitter.emit(VideoPlayerEvent.TIME_UPDATE, playerInstance.currentTime())
  }

  const destroy = () => {
    if (!sportBuffContainer) return
    videoJSContainer.removeChild(sportBuffContainer)
    playerInstance.off('timeupdate', handleTimeUpdate)
    removeUIHandlers()
  }

  playerInstance.on('timeupdate', handleTimeUpdate)

  return {
    on: emitter.on.bind(emitter),
    emit: emitter.emit.bind(emitter),
    off: emitter.off.bind(emitter),
    appendContainer,
    destroy,
  }
}
