import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'
import translationEN from '../locales/en/translation.json'
import translationES from '../locales/es/translation.json'
import translationFR from '../locales/fr/translation.json'
import translationAR from '../locales/ar/translation.json'
import translationHE from '../locales/he/translation.json'
import translationNO from '../locales/no/translation.json'
import translationEL from '../locales/el/translation.json'
import translationBG from '../locales/bg/translation.json'
import translationHU from '../locales/hu/translation.json'
import translationID from '../locales/id/translation.json'
import translationPT from '../locales/pt/translation.json'
import translationNL from '../locales/nl/translation.json'
import translationSR from '../locales/sr/translation.json'

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: translationEN,
    },
    es: {
      translation: translationES,
    },
    fr: {
      translation: translationFR,
    },
    pt: {
      translation: translationPT,
    },
    ar: {
      translation: translationAR,
    },
    he: {
      translation: translationHE,
    },
    no: {
      translation: translationNO,
    },
    el: {
      translation: translationEL,
    },
    bg: {
      translation: translationBG,
    },
    hu: {
      translation: translationHU,
    },
    id: {
      translation: translationID,
    },
    nl: {
      translation: translationNL,
    },
    sr: {
      translation: translationSR,
    },
  },
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  },
})

export default i18n
