import EventEmitter from 'eventemitter3'
import { WidgetConfig } from '@interfaces/widget'
import { VideoPlayer, VideoPlayerEvent } from '@interfaces/videoPlayer'

export const getCustomFunctionVideoPlayer = async ({
  appendContainer,
  destroyContainer,
}: WidgetConfig): Promise<VideoPlayer> => {
  const emitter = new EventEmitter()

  if (!appendContainer) {
    throw new Error('No appendContainer function')
  }
  if (!destroyContainer) {
    throw new Error('No destroyContainer function')
  }

  const hideUi = () => {
    emitter.emit(VideoPlayerEvent.HIDE_UI)
  }

  const showUi = () => {
    emitter.emit(VideoPlayerEvent.SHOW_UI)
  }

  const updateTimeVOD = (seconds: number) => {
    emitter.emit(VideoPlayerEvent.TIME_UPDATE, seconds)
  }

  const updateTimeTimeSync = (timestamp: number) => {
    emitter.emit(VideoPlayerEvent.TIME_SYNC_DATE_UPDATE, timestamp)
  }

  const showQRCode = (durationMs?: number) => {
    if (durationMs === undefined) {
      throw new Error('Missing durationMS arg')
    }

    emitter.emit(VideoPlayerEvent.SHOW_QR_CODE, durationMs)
  }

  const toggleSnooze = () => {
    emitter.emit(VideoPlayerEvent.TOGGLE_SNOOZE)
  }

  const customSignupCompleted = (refreshToken: string) => {
    emitter.emit(VideoPlayerEvent.CUSTOM_SIGNUP_COMPLETED, refreshToken)
  }

  const controls = {
    hideUi,
    showUi,
    updateTimeVOD,
    updateTimeTimeSync,
    showQRCode,
    toggleSnooze,
    customSignupCompleted,
  }

  return {
    controls,
    emit: emitter.emit.bind(emitter),
    on: emitter.on.bind(emitter),
    off: emitter.off.bind(emitter),
    appendContainer,
    destroy: destroyContainer,
  }
}
