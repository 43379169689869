import jwtDecode from 'jwt-decode'
import { TokenPayload } from '@interfaces/auth'
import { camelCase } from 'change-case'
import { convertKeyNames } from './object'

export const readJWT = (token: string): any => {
  const parts = token.split('.')
  return JSON.parse(window.atob(parts[1]))
}

export const readSportBuffJWT = (token: string): TokenPayload => {
  /**
   * Using a library to decode as the tokens payloads can be encoded in 2 different ways
   * base64 for 'web'
   * base64 url for twitch
   */
  const payload = jwtDecode(token)
  return convertKeyNames(payload, camelCase)
}
