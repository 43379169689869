import React, { FC, useContext, useEffect, useState } from 'react'
import { useNavigate, useOutlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import UserSummary from '@components/molecules/UserSummary'
import StreamSettings from '@components/molecules/StreamSettings'
import { UserContext } from '@services/providers/UserProvider'
import { AudioContext } from '@services/providers/AudioProvider'
import { BuffContext } from '@services/providers/BuffProvider'
import { ConfigContext } from '@services/providers/ConfigProvider'
import { useFloatingTabsContext } from '@services/providers/FloatingTabsProvider'
import { RoutePaths } from '@interfaces/navigation'
import AnimatedView from '@components/atoms/AnimatedView'
import { SignupBanner } from '@components/atoms/SignupBanner'
import TermsAndPolicy from '@components/atoms/TermsAndPolicy'
import { PROPERTY_EVENTS } from '@interfaces/metrics'
import { sendCounterEvent } from '@utils/metrics'
import { AnimatePresence } from 'framer-motion'
import storage from '@utils/storage'
import { useSDKFeatures } from '@utils/hooks/useSDKFeatures'
import ModalPopup from '@components/molecules/ModalPopup'
import Button, { ButtonBackground } from '@components/atoms/Button'
import { useSignupText } from '@utils/hooks/useSignupTexts'
import {
  BUFFUP_ANON_ID,
  BUFFUP_SDK_REFRESH_TOKEN,
  BUFFUP_SDK_TOKEN,
} from '../../../constants'
import { ISettingsProps } from './types'

const Settings: FC<ISettingsProps> = ({ disabled = false }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const outlet = useOutlet()

  const {
    profile,
    isLinkedTwitchUser,
    userLanguage,
    token,
    setToken,
    refetchProfile,
  } = useContext(UserContext)
  const { snooze, updateSnooze } = useContext(BuffContext)
  const { soundOn, toggleSound } = useContext(AudioContext)
  const { widgetConfig, streamConfig } = useContext(ConfigContext)
  const { getRedirectPath } = useSDKFeatures()

  const { text: signUpText, actionText: signUpActionText } = useSignupText({
    placement: 'settingsTab',
  })

  const { isOpen } = useFloatingTabsContext()

  const [isOpenLogoutPopup, setIsOpenLogoutPopup] = useState(false)

  const openLogoutPopup = () => {
    setIsOpenLogoutPopup(true)
  }

  const handleLogout = () => {
    setIsOpenLogoutPopup(false)

    setTimeout(() => {
      setToken(null)
      navigate(getRedirectPath(RoutePaths.LEADERBOARDS, 'leaderboard'))
      storage.removeItem(BUFFUP_ANON_ID)
      storage.removeItem(BUFFUP_SDK_REFRESH_TOKEN)
      storage.removeItem(BUFFUP_SDK_TOKEN)
    }, 300)
  }

  const isTwitch =
    widgetConfig.player === 'twitch-mobile' ||
    widgetConfig.player === 'twitch-web'

  const isUserAuthenticated = isTwitch ? isLinkedTwitchUser : Boolean(token)

  // TODO: Decide what to do with ranks
  const globalStanding = undefined
  const streamStanding = undefined

  const isTwitchMobile = widgetConfig?.player === 'twitch-mobile'

  const disabledSounds = isTwitchMobile

  const username = profile?.displayName ?? ''

  const settingsStyles = {
    topPadding: 'pt-3',
  }

  useEffect(() => {
    sendCounterEvent(PROPERTY_EVENTS.profileViewed)
  }, [])

  useEffect(() => {
    if (!isOpen) return
    refetchProfile()
  }, [refetchProfile, isOpen])

  const onGoBack = () => navigate(RoutePaths.SETTINGS)

  const onLanguageClick = () => {
    navigate(RoutePaths.SETTINGS_LANGUAGES)
  }

  const handleLinkTwitchProfile = () => {
    navigate(RoutePaths.SETTINGS_LINK_TWITCH, {
      state: {
        handleGoBack: onGoBack,
      },
    })
  }

  const handleSignup = () => {
    navigate(RoutePaths.AUTH, {
      state: {
        onGoBack: onGoBack,
      },
    })
  }

  const showLogout = !!profile?.passportId && !isTwitch

  const image = streamConfig?.config.hideProfilePicture
    ? undefined
    : profile?.profilePicture

  return (
    <div>
      <AnimatedView
        className={`${settingsStyles.topPadding} p-4 h-full flex flex-col`}
        data-testid="settings-content"
        viewPath={RoutePaths.SETTINGS}
      >
        <div className="flex-grow">
          {isUserAuthenticated && (
            <div className="flex items-center justify-between mb-4">
              <UserSummary username={username} image={image} />
              {showLogout && (
                <Button
                  data-testid="profile__logout-button"
                  background={ButtonBackground.PRIMARY}
                  className="!py-1 !px-2"
                  onClick={openLogoutPopup}
                >
                  <span className="text-label-800 font-semibold text-sm">
                    {t('general.logout')}
                  </span>
                </Button>
              )}
            </div>
          )}

          {!isUserAuthenticated && (
            <SignupBanner
              text={signUpText}
              buttonText={signUpActionText}
              onClick={handleSignup}
            />
          )}
          <StreamSettings
            locale={userLanguage}
            showMute={
              widgetConfig?.player !== 'twitch-mobile' &&
              streamConfig?.config.playVoteableSound
            }
            muted={!soundOn}
            snoozed={snooze}
            disabled={disabled}
            disabledSounds={disabledSounds}
            onLanguageClick={onLanguageClick}
            onMuteClick={toggleSound}
            onSnoozeClick={updateSnooze}
          />
        </div>
        {isUserAuthenticated && (
          <TermsAndPolicy
            className="px-2"
            descriptionTranslationKey="settings.termsAndPolicy"
            termsConditionsUrl={RoutePaths.SETTINGS_TERMS_CONDITIONS}
            privacyPolicyUrl={RoutePaths.SETTINGS_PRIVACY_POLICY}
          />
        )}

        <AnimatePresence>{outlet}</AnimatePresence>
      </AnimatedView>

      <ModalPopup isOpen={isOpenLogoutPopup}>
        <div className="flex flex-col justify-center px-6 py-2">
          <button
            onClick={handleLogout}
            className="py-2 text-sm text-label-700"
          >
            {t('general.logout')}
          </button>
          <button
            className="py-2 text-sm font-bold text-label-700"
            onClick={() => setIsOpenLogoutPopup(false)}
          >
            {t('general.cancel')}
          </button>
        </div>
      </ModalPopup>
    </div>
  )
}

export default Settings
