import React, {
  useContext,
  useRef,
  FC,
  useEffect,
  useState,
  useCallback,
} from 'react'
import { useNavigate } from 'react-router-dom'
import {
  motion,
  AnimatePresence,
  useMotionValue,
  useDragControls,
} from 'framer-motion'
import PointsDeck from '@components/atoms/PointsDeck'
import { UserContext } from '@services/providers/UserProvider'
import { ConfigContext } from '@services/providers/ConfigProvider'
import { BuffContext } from '@services/providers/BuffProvider'
import { StreamContext } from '@services/providers/StreamProvider'
import { useFloatingTabsContext } from '@services/providers/FloatingTabsProvider'
import { LeaderboardContext } from '@services/providers/LeaderboardProvider'
import { useMeasure } from '@utils/hooks/useMeasure'
import getPlatform from '@utils/getPlatform'
import { useWindowResize } from '@utils/hooks/useWindowResize'
import { WidgetLayout } from '@interfaces/widget'
import { useGetLeaderboardUserStanding } from '@utils/hooks/useGetLeaderboardUserStanding'
import { RoutePaths } from '@interfaces/navigation'
import {
  useStreamConfigPosition,
  StreamConfigTarget,
} from '@utils/hooks/useStreamConfigPosition'
import { useLocalPosition } from '@utils/hooks/useLocalPosition'
import { sendCounterEvent } from '@utils/metrics'
import { useSDKFeatures } from '@utils/hooks/useSDKFeatures'
import { PROPERTY_EVENTS } from '@interfaces/metrics'
import { PositionConfig } from '@interfaces/streamConfig'
import usePreviousValue from '@utils/hooks/usePreviousValue'
import { IFloatingPanelProps } from './types'

import './FloatingPanel.styles.css'

const mockDragConstraints = {
  current: {
    getBoundingClientRect: () => {
      return {
        bottom: 0,
        height: 0,
        left: 0,
        right: 0,
        top: 0,
        width: 0,
      } as DOMRect
    },
  } as any,
}

enum PanelXAlignment {
  LEFT = 'left',
  RIGHT = 'right',
}

enum PanelYAlignment {
  /**
   * Panel is positioned above points deck
   */
  TOP = 'top',

  /**
   * Panel is positioned below points deck
   */
  BOTTOM = 'bottom',
}

const FloatingPanel: FC<IFloatingPanelProps> = ({
  children,
  isPanelOpen = false,
  playerControlsVisible = true,
  sdkContainer,
}) => {
  const { activeLeaderboardId } = useContext(LeaderboardContext)
  const { profile, isProfileInitialized, userId } = useContext(UserContext)
  const { stream } = useContext(StreamContext)
  const { streamConfig, widgetConfig } = useContext(ConfigContext)
  const { snooze, updateSnooze } = useContext(BuffContext)
  const [draggable, setDraggable] = useState<boolean>(true)
  const [listeningContainers, setListeningContainers] = useState<Element[]>([])
  const [isListening, setIsListening] = useState<boolean>(false)
  const { allowGamePoints, getRedirectPath, features } = useSDKFeatures()

  const prevPositionConfig = usePreviousValue(streamConfig?.positionConfig)

  const controls = useDragControls()

  const { isOpen, setOpen } = useFloatingTabsContext()
  const menuPositionStyles = useStreamConfigPosition(
    StreamConfigTarget.LEADERBOARD
  )

  const navigate = useNavigate()
  const menuRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(sdkContainer as HTMLDivElement)
  const pointsDeckContainerRef = useRef<HTMLDivElement>(null)

  const {
    ref: pointsDeckRef,
    elementRef: pointsDeckElementRef,
    bounds: { height: pointsDeckHeight, width: pointsDeckWidth },
  } = useMeasure()

  const x = useMotionValue(0)
  const y = useMotionValue(0)

  const { save: saveLocalPosition, reset: resetPosition } = useLocalPosition()

  const { data: userStandingData } = useGetLeaderboardUserStanding(
    activeLeaderboardId,
    userId,
    true,
    Boolean(stream)
  )

  const [panelXAlignment, setPanelXAlignment] = useState(PanelXAlignment.RIGHT)
  const [panelYAlignment, setPanelYAlignment] = useState(PanelYAlignment.BOTTOM)

  const toggleFloatingPanelVisibility = () => {
    const newIsOpenValue = !isOpen
    if (newIsOpenValue) {
      navigate(getRedirectPath(RoutePaths.LEADERBOARDS, 'leaderboard'))
      sendCounterEvent(PROPERTY_EVENTS.menuShown)
    }
    setOpen(newIsOpenValue)
  }

  const openFloatingPanelSettings = () => {
    if (!features?.profile?.enabled) return
    if (!isOpen) {
      setOpen(!isOpen)
      sendCounterEvent(PROPERTY_EVENTS.menuShown)
    }
    navigate(getRedirectPath(RoutePaths.PROFILE, 'profile'))
  }

  const autoHideMenu = streamConfig?.config.hideMenu ?? false

  const rootOpacity =
    !autoHideMenu || isOpen || playerControlsVisible
      ? 'opacity-100'
      : 'opacity-0'

  const panelXPositionClass =
    panelXAlignment === PanelXAlignment.RIGHT ? 'right-0' : 'left-0'

  const panelYPositionClass =
    panelYAlignment === PanelYAlignment.BOTTOM ? 'top-full' : 'bottom-full'

  /**
   * Updates the panel position based on the space available on the screen
   */
  const updateMenuPosition = useCallback(() => {
    if (!isOpen) {
      setPanelXAlignment(PanelXAlignment.RIGHT)
      return
    }
    if (!menuRef.current) return

    if (!menuRef.current.classList.contains('floating-panel--open-right')) {
      menuRef.current.classList.add('floating-panel--open-right')
    }

    if (!menuRef.current.classList.contains('floating-panel--open-bottom')) {
      menuRef.current.classList.add('floating-panel--open-bottom')
    }

    const parentBounds = sdkContainer?.getBoundingClientRect() ?? null
    const bounds = menuRef.current.getBoundingClientRect()

    if (!parentBounds) {
      return
    }

    if (
      widgetConfig.layout === WidgetLayout.PORTRAIT_TOGGLE_LEADERBOARD &&
      parentBounds.height < 600
    ) {
      setPanelYAlignment(PanelYAlignment.BOTTOM)
      return
    }

    const availableHeight = parentBounds.height

    const top = bounds.top - parentBounds.top
    const bottomOfElement = top + bounds.height
    const left = bounds.left - parentBounds.left
    const topAlignPosition = top - bounds.height - pointsDeckHeight

    let bottomOverflow = availableHeight - bottomOfElement
    if (bottomOverflow > 0) {
      bottomOverflow = 0
    } else {
      bottomOverflow = Math.abs(bottomOverflow)
    }

    const topOverflow = Math.max(0 - topAlignPosition, 0)

    if (left < 0) {
      setPanelXAlignment(PanelXAlignment.LEFT)
    } else {
      setPanelXAlignment(PanelXAlignment.RIGHT)
    }

    if (bottomOverflow <= topOverflow) {
      setPanelYAlignment(PanelYAlignment.BOTTOM)
    } else {
      setPanelYAlignment(PanelYAlignment.TOP)
    }

    menuRef.current.classList.remove('floating-panel--open-right')
    menuRef.current.classList.remove('floating-panel--open-bottom')
  }, [isOpen, sdkContainer, pointsDeckHeight, widgetConfig?.layout])

  const handleUnSnoozeClick = () => {
    setOpen(true)
    updateSnooze(false)
    navigate(RoutePaths.SETTINGS)
  }

  const startDrag = (event: React.PointerEvent) => {
    controls.start(event)
  }

  useEffect(() => {
    const containers = document.querySelectorAll('.jwplayer')
    const containerArr: Element[] = []
    containers.forEach((elem) => {
      containerArr.push(elem)
    })
    setListeningContainers(containerArr)
  }, [])

  useEffect(updateMenuPosition, [updateMenuPosition, isOpen])

  // Update the position of the floating panel when positioning config is changed
  useEffect(() => {
    if (
      isOpen &&
      !!prevPositionConfig &&
      !!streamConfig?.positionConfig &&
      prevPositionConfig !== streamConfig?.positionConfig
    ) {
      window.setTimeout(() => {
        updateMenuPosition()
      }, 100)
    }
  }, [
    updateMenuPosition,
    isOpen,
    prevPositionConfig,
    streamConfig?.positionConfig,
  ])

  useEffect(() => {
    /**
     *
     * @param {Event} event
     */
    function stopPropagation(event: Event) {
      event.stopPropagation()
    }

    if (isOpen) {
      listeningContainers.forEach((container) => {
        container.addEventListener('keydown', stopPropagation, true)
        setIsListening(true)
      })
    }

    return () => {
      listeningContainers.forEach((container) => {
        container.removeEventListener('keydown', stopPropagation, true)
      })
    }
  }, [isListening, isOpen, listeningContainers])

  useWindowResize(updateMenuPosition)

  useEffect(() => {
    setOpen(isPanelOpen || false)
  }, [isPanelOpen, setOpen])

  useEffect(() => {
    if (snooze) setOpen(false)
  }, [snooze, setOpen])

  useEffect(() => {
    const platform = getPlatform(widgetConfig)
    const isDraggable =
      streamConfig?.positionConfig?.find(
        (x: PositionConfig) => x.platform === platform
      )?.draggableLeaderboard ?? true

    if (!isDraggable) resetPosition()
    setDraggable(isDraggable)
  }, [resetPosition, streamConfig, widgetConfig])

  if (!isProfileInitialized) return null

  const pointsDeckImage = streamConfig?.config.hideProfilePicture
    ? undefined
    : profile?.profilePicture

  if (widgetConfig.tvDeviceId) {
    return <div data-testid="floating-panel-tv" />
  }

  if (!stream) {
    return <div data-testid="floating-panel-no-stream" />
  }

  return (
    <motion.div
      ref={pointsDeckContainerRef}
      dragTransition={{ bounceStiffness: 600, bounceDamping: 20 }}
      dragElastic={false}
      drag={draggable}
      dragListener={false}
      dragMomentum={false}
      dragControls={controls}
      dragConstraints={
        containerRef?.current ? containerRef : mockDragConstraints
      }
      onDragStart={() => setOpen(false)}
      onDragEnd={(event: PointerEvent) => {
        const deckSize = pointsDeckElementRef.current?.getBoundingClientRect?.()
        const width = event.view?.innerWidth || 0
        const height = event.view?.innerHeight || 0
        const containerSize = sdkContainer?.getBoundingClientRect()

        if (!containerSize) return
        if (!deckSize) return

        const hOffset =
          ((deckSize.x - containerSize.left) /
            ((width * containerSize.width) / width)) *
          100
        const vOffset =
          ((deckSize.y - containerSize.top) /
            ((height * containerSize.height) / height)) *
          100

        saveLocalPosition({ hOffset, vOffset })
        setTimeout(() => {
          x.set(0)
          y.set(0)
          setOpen(true)
        }, 0)
      }}
      data-testid="floating-panel"
      className={`z-1 flex flex-col items-end ${rootOpacity} absolute max-w-xs pointer-events-auto antialiased`}
      style={{
        ...menuPositionStyles,
        x,
        y,
        touchAction: 'none',
      }}
    >
      <div
        ref={pointsDeckRef}
        id="points-deck"
        onPointerDown={startDrag}
        style={{ cursor: 'grabbing' }}
      >
        <PointsDeck
          hidePoints={!allowGamePoints}
          points={userStandingData?.userStanding?.standing.totalPoints ?? 0}
          image={pointsDeckImage}
          isOpen={isOpen}
          onProfileClick={openFloatingPanelSettings}
          onToggle={toggleFloatingPanelVisibility}
          snoozed={snooze}
          onUnSnoozeClick={handleUnSnoozeClick}
        />
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={`flex flex-col justify-between max-w-xs bg-gradient-to-r from-background-start to-background-end rounded-md rounded-tr-none absolute w-80 overflow-hidden ${panelYPositionClass} ${panelXPositionClass}`}
            ref={menuRef}
            style={{ cursor: 'default' }}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  )
}

export default FloatingPanel
