import { Dispatch, useContext, useEffect, useRef } from 'react'
import { StreamContext } from '@services/providers/StreamProvider'
import { Actions } from '../state/buffReducer'
import { ConfigContext } from '@services/providers/ConfigProvider'
import { VideoPlayer, VideoPlayerEvent } from '@interfaces/videoPlayer'
import {
  getVodAnnouncementsByStreamId,
  getVodVoteablesByStreamId,
} from '@services/requests/vod'
import { UseBuffQueueReturn } from '@utils/hooks/useBuffQueue'
import { logError } from '@utils/log'

interface UseVODBuffsProps {
  videoPlayer?: VideoPlayer
  dispatch: Dispatch<Actions>
  queueHelpers: UseBuffQueueReturn
}

const fetchVODBuffs = async (streamId: string) => {
  try {
    const announcements = await getVodAnnouncementsByStreamId(streamId)
    const voteables = await (
      await getVodVoteablesByStreamId(streamId)
    ).filter((voteable) => !!voteable.visible)
    const buffs = [...announcements, ...voteables]

    return buffs
  } catch (err) {
    logError(err)
  }
}

export const useVODBuffs = ({
  videoPlayer,
  queueHelpers,
}: UseVODBuffsProps) => {
  const { updateGameTime, handleVODBuffs } = queueHelpers

  const playlistPlaybackRef = useRef<number>()
  const playlistPlaybackTime = useRef<number>(0)

  const { stream } = useContext(StreamContext)
  const { widgetConfig } = useContext(ConfigContext)

  const isPlaylistMode = widgetConfig.playlistMode ?? false

  const isVodStream = stream && stream.endedAt && stream.id

  useEffect(() => {
    if (!isVodStream) return
    ;(async () => {
      const buffs = await fetchVODBuffs(stream?.id)
      if (!buffs) return
      handleVODBuffs(buffs)
    })()
  }, [isVodStream, stream?.id, handleVODBuffs])

  useEffect(() => {
    if (
      isPlaylistMode ||
      !videoPlayer ||
      !widgetConfig.player ||
      widgetConfig.player === 'twitch-web' || // Doesn't support VOD
      widgetConfig.player === 'twitch-mobile' // Doesn't support VOD
    ) {
      return
    }

    const updateGame = (seconds: number) => {
      return updateGameTime(seconds)
    }

    videoPlayer.on(VideoPlayerEvent.TIME_UPDATE, updateGame)
    return () => {
      videoPlayer.off(VideoPlayerEvent.TIME_UPDATE, updateGame)
    }
  }, [videoPlayer, isPlaylistMode, widgetConfig.player, updateGameTime])

  useEffect(() => {
    if (!isPlaylistMode) return

    playlistPlaybackRef.current = window.setInterval(() => {
      playlistPlaybackTime.current = playlistPlaybackTime.current + 1
      updateGameTime(playlistPlaybackTime.current)
    }, 1000)

    return () => {
      clearInterval(playlistPlaybackRef.current)
    }
  }, [isPlaylistMode, updateGameTime])
}
