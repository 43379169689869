import axios, { HeadersDefaults } from 'axios'
import { TwitchUserData } from '@interfaces/twitch'
import { changeCaseInterceptor } from '@services/requestClient'

interface CommonHeaderProperties extends HeadersDefaults {
  'X-Buffup-Broadcaster-ID': string
}

const client = axios.create()

const accountName = process.env.ACCOUNT_NAME

client.defaults.headers = {
  'X-Buffup-Broadcaster-ID': accountName,
} as CommonHeaderProperties

/**
 * Rename object keys to and from the API so we can model our data in camelCase
 * But send and receive in snake_case.
 */
client.interceptors.response.use(
  changeCaseInterceptor.responseFulfilled,
  changeCaseInterceptor.responseRejected
)

/**
 * Gets a user by their twitch id
 * @param {string} userId The twitch id of the user
 * @param {string} clientId
 * @return {Promise<TwitchUserData>} Users' public twitch info
 */
export const getTwitchUser = async (
  userId: string,
  clientId: string
): Promise<TwitchUserData> => {
  const twitchUserResponse = await client.get(
    `https://api.twitch.tv/kraken/users/${userId}`,
    {
      headers: {
        'Client-ID': clientId,
        Accept: 'application/vnd.twitchtv.v5+json',
      },
    }
  )

  if (twitchUserResponse.status === 200) {
    return twitchUserResponse.data
  }

  throw new Error(
    `Failed to fetch Twitch API, https://api.twitch.tv/kraken/users/${userId}`
  )
}

interface AuthTwitchUserResponse {
  refreshToken: string
  userID: string
  expiresAt: string
  createdAt: string
}

export const authTwitchUser = async (
  clientName: string,
  accessToken: string
) => {
  const response = await client.post<AuthTwitchUserResponse>(
    `https://api.${clientName}.buffup.net/auth/twitch/login`,
    { accessToken },
    { headers: { 'Content-Type': 'application/json' } }
  )
  return response.data
}
