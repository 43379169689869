export enum AnswerTileStatus {
  INACTIVE = 'inactive',
  ACTIVE = 'active',
  SELECTED = 'selected',

  /**
   * State for showing the selected answer tile with percentage vote
   */
  SELECTED_VOTE_PERCENTAGE = 'selected-vote-percentage',

  /**
   * State for showing the unselected answer tile with percentage vote
   */
  VOTE_PERCENTAGE = 'vote-percentage',

  /**
   * State for showing the percentage vote after a timeout
   */
  VOTE_PERCENTAGE_TIMEOUT = 'vote-percentage-timeout',

  /**
   * The statuses below will display the corresponding titles and
   * then show a percentage bar with percentage
   */
  CORRECT = 'correct',
  SELECTED_CORRECT = 'selected-correct',
  WINNER = 'winner',
  SELECTED_WINNER = 'selected-winner',
  WRONG = 'wrong',
  YOUR_PICK = 'your-pick',
}

export interface AnswerTileProps {
  /**
   * Status of the answer tile
   */
  status: AnswerTileStatus

  /**
   * Text to display in the answer tile
   */
  text: string

  /**
   * Image of the answer tile
   */
  image?: string

  /**
   * If the answer tile should be disabled
   * @default false
   */
  disabled?: boolean

  /**
   * Percentage of people that selected a single answer
   */
  percentage?: number

  /**
   * Number of points assigned to answer tile
   */
  points?: number

  /**
   * Overrides text color
   */
  textColor?: string

  /**
   * Overrides background colour
   */
  bgColor?: string

  /**
   * Whether there is at least one answer with point
   */
  hasPoints?: boolean

  /**
   * Handler that will be clicked when answer tile is clicked
   */
  onClick?: () => void

  /**
   * Check if live voting mode is enabled
   */
  isLiveVotingEnabled: boolean

  /**
   * Selection ID for test purposes.
   */
  'data-testid'?: string
}
