import React, { FC } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Button, { ButtonBackground } from '@components/atoms/Button'
import Modal from '@components/molecules/Modal'
import { ReactComponent as CheckBulletIcon } from '@assets/icons/icon_icon_check_bullet.svg'
import BulletList from '@components/atoms/BulletList'
import { IBulletListItem } from '@components/atoms/BulletList/types'

const LinkTwitchProfile: FC = () => {
  const { t } = useTranslation()
  const { state } = useLocation()
  const { handleGoBack } = state

  const onLinkTwitch = () => {
    const twitchExt = window.Twitch.ext
    twitchExt.actions.requestIdShare()
  }

  const bulletList: IBulletListItem[] = [
    {
      text: t('twitch.bulletList1'),
      icon: CheckBulletIcon,
      iconBg: '#9146ff',
    },
    {
      text: t('twitch.bulletList2'),
      icon: CheckBulletIcon,
      iconBg: '#9146ff',
    },
    {
      text: t('twitch.bulletList3'),
      icon: CheckBulletIcon,
      iconBg: '#9146ff',
    },
  ]

  return (
    <Modal
      title={t('twitch.linkTwitchTitle')}
      onGoBack={handleGoBack}
      data-testid="linkTwitchProfile"
    >
      <div className="mb-4">
        <span className=" text-sm">{t('twitch.linkAccount')}</span>
      </div>

      <div className="mb-4">
        <BulletList list={bulletList} />
      </div>

      <div className="mb-4">
        <Button
          onClick={onLinkTwitch}
          data-testid="linkTwitchProfile__button-link"
          className="px-6"
          background={ButtonBackground.PRIMARY}
        >
          {t('twitch.linkTwitch')}
        </Button>
      </div>
    </Modal>
  )
}

export default LinkTwitchProfile
