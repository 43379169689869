import { useNavigate } from 'react-router-dom'
import React, {
  useContext,
  createContext,
  useEffect,
  useRef,
  useCallback,
  useState,
  FC,
  Dispatch,
  SetStateAction,
} from 'react'
import { useTranslation } from 'react-i18next'
import { getUser, createAnonUser } from '@services/requests/auth'
import { StreamContext } from '@services/providers/StreamProvider'
import { User } from '@interfaces/user'
import { LanguageEnum } from '@interfaces/languages'
import { refreshJwtToken } from '@services/requests/auth'
import { languagesMap } from '@utils/language'
import { readSportBuffJWT } from '@utils/jwt'
import { amplitudeInstance, ENABLE_ANALYTICS } from '@utils/metrics'
import { logError } from '@utils/log'
import storage from '@utils/storage'
import { BUFFUP_SDK_TOKEN, BUFFUP_SDK_REFRESH_TOKEN } from '../../constants'
import { VideoPlayer, VideoPlayerEvent } from '@interfaces/videoPlayer'
import { ConfigContext } from './ConfigProvider'
import { RoutePaths } from '@interfaces/navigation'

export interface IError {
  error: string
}

export interface IUserProviderProps {
  /**
   * Twitch user data. Only will be provided if the user has linked their account
   */
  isLinkedTwitchUser: boolean

  /**
   * Writing mode for SDK (used for dev)
   */
  writingMode?: 'ltr' | 'rtl'

  /**
   * Refresh token passed in from widget config
   */
  refreshToken?: string

  /**
   * Log in user by generating anonymous login
   */
  anonymousLogin?: boolean

  /**
   * Video player instance to control login event
   */
  videoPlayer?: VideoPlayer
}

export const rtlLocales = [
  LanguageEnum.LANGUAGE_ARABIC,
  LanguageEnum.LANGUAGE_HEBREW,
]

export interface IUserContextProps {
  /**
   * Uuid of the authenticated user
   */
  userId?: string

  /**
   * Users profile
   */
  profile?: User

  /**
   * User's JWT token
   */
  token: string | null

  /**
   * User's language
   */
  userLanguage: LanguageEnum

  /**
   * Whether initial profile call is success
   */
  isProfileInitialized?: boolean

  /**
   * Writing mode of the sdk
   */
  writingMode?: 'ltr' | 'rtl'

  /**
   * If the user has a linked twitch account
   */
  isLinkedTwitchUser: boolean

  /**
   * A promise that will resolve when the profile data has been refreshed
   */
  refetchProfile: () => Promise<void>

  /**
   * A function that will change the user's language
   */
  setUserLanguage: Dispatch<SetStateAction<LanguageEnum>>

  /**
   * A function that will change the user id
   */
  setToken: Dispatch<SetStateAction<string | null>>
}

export const UserContext = createContext<IUserContextProps>(
  {} as IUserContextProps
)

const language =
  (storage.getItem('TEMP_USER_LANGUAGE') as LanguageEnum) ??
  LanguageEnum.LANGUAGE_ENGLISH
storage.setItem('TEMP_USER_LANGUAGE', language)

export const UserProvider: FC<IUserProviderProps> = ({
  children,
  isLinkedTwitchUser = false,
  writingMode: devWritingMode,
  refreshToken,
  anonymousLogin = false,
}) => {
  const { i18n } = useTranslation()
  const navigate = useNavigate()
  const { widgetConfig } = useContext(ConfigContext)
  const anonUserInitialised = useRef<boolean>(false)
  const [profile, setProfile] = useState<User>()
  const [token, setToken] = useState<string | null>(() => {
    const storedToken = storage.getItem(BUFFUP_SDK_TOKEN)
    if (storedToken) {
      try {
        // Check token is valid when reading from storage
        readSportBuffJWT(storedToken)
        return storedToken
      } catch (error) {
        console.error(error)
      }
    }
    return null
  })
  const [userId, setUserId] = useState<string>()
  const { stream } = useContext(StreamContext)
  const [userLanguage, setUserLanguage] = useState<LanguageEnum>(
    LanguageEnum.LANGUAGE_ENGLISH
  )
  const writingMode = devWritingMode
    ? devWritingMode
    : rtlLocales.includes(userLanguage)
    ? 'rtl'
    : 'ltr'

  const isProfileInitialized = profile !== undefined

  const handleCustomSignUp = useCallback(
    async (refreshToken: string) => {
      try {
        storage.setItem(BUFFUP_SDK_REFRESH_TOKEN, refreshToken)

        const token = await refreshJwtToken()
        setToken(token)
        navigate(RoutePaths.WELCOME)
      } catch (error) {
        console.error(error)
      }
    },
    [navigate]
  )

  const fetchProfile = useCallback(async () => {
    if (!userId) {
      setProfile({ displayName: '', id: '' })
      return
    }

    try {
      const user = await getUser(userId)
      setProfile(user)
    } catch (e) {
      setProfile({ displayName: '', id: '' })
    }
  }, [userId])

  const props: IUserContextProps = {
    userId,
    token,
    userLanguage,
    profile,
    writingMode,
    isProfileInitialized,
    isLinkedTwitchUser,
    setUserLanguage,
    refetchProfile: fetchProfile,
    setToken,
  }

  useEffect(() => {
    const resetToken = () => {
      setToken(null)
    }

    window.addEventListener('expiredRefreshToken', resetToken)
    return () => {
      window.removeEventListener('expiredRefreshToken', resetToken)
    }
  }, [])

  useEffect(() => {
    widgetConfig?.videoPlayer?.on(
      VideoPlayerEvent.CUSTOM_SIGNUP_COMPLETED,
      handleCustomSignUp
    )

    return () => {
      widgetConfig?.videoPlayer?.off(
        VideoPlayerEvent.CUSTOM_SIGNUP_COMPLETED,
        handleCustomSignUp
      )
    }
  }, [handleCustomSignUp, widgetConfig?.videoPlayer])

  /**
   * Gets a new token from clients refresh token
   */
  useEffect(() => {
    if (!refreshToken) {
      // If SportBuff token is not present or wrong, a page reload was causing the refresh token to be removed
      // causing the user to logout. This checks if the token is there to enforce a new jwt creation
      const storageRefreshToken = storage.getItem(BUFFUP_SDK_REFRESH_TOKEN)

      if (!storageRefreshToken) return

      storage.setItem(BUFFUP_SDK_REFRESH_TOKEN, storageRefreshToken)
      refreshJwtToken()
        .then(() => {
          setToken(storage.getItem(BUFFUP_SDK_TOKEN))
        })
        .catch((err) => {
          logError(err)
        })
    }
  }, [refreshToken])

  /**
   * Gets a new token from clients refresh token
   */
  useEffect(() => {
    if (!refreshToken) return

    storage.setItem(BUFFUP_SDK_REFRESH_TOKEN, refreshToken)
    refreshJwtToken()
      .then(() => {
        setToken(storage.getItem(BUFFUP_SDK_TOKEN))
      })
      .catch((err) => {
        logError(err)
      })
  }, [refreshToken])

  useEffect(() => {
    if (!anonymousLogin || !stream || anonUserInitialised.current || token) {
      return
    }

    anonUserInitialised.current = true

    createAnonUser()
      .then(({ refreshToken }) => {
        storage.setItem(BUFFUP_SDK_REFRESH_TOKEN, refreshToken)
        return refreshJwtToken().then(() => {
          setToken(storage.getItem(BUFFUP_SDK_TOKEN))
        })
      })
      .catch((error) => {
        logError(error)
        anonUserInitialised.current = false
      })
  }, [anonymousLogin, stream, token])

  useEffect(() => {
    if (!token) {
      setUserId(undefined)
      setProfile({ displayName: '', profilePicture: '', id: '' })
      storage.removeItem(BUFFUP_SDK_TOKEN)
      return
    }

    const tokenDetails = readSportBuffJWT(token)
    setUserId(tokenDetails.uuid)

    storage.setItem(BUFFUP_SDK_TOKEN, token)
  }, [token])

  useEffect(() => {
    fetchProfile().catch((err) => logError(err))
  }, [fetchProfile])

  useEffect(() => {
    if (userLanguage && stream) {
      const streamLanguages = stream?.languages
      // const userSelectedLanguage = profile?.locale as LanguageEnum

      const userLanguageInStream = streamLanguages?.find(
        (lang) => lang === userLanguage
      )

      if (userLanguageInStream) {
        i18n.changeLanguage(languagesMap[userLanguageInStream].locale)
      } else {
        // If user's language is not found in stream languages, then we check if stream has english,
        // if it has, assign english as language, otherwise use stream's first language
        const englishLanguage = streamLanguages?.find(
          (lang) => lang === LanguageEnum.LANGUAGE_ENGLISH
        )
        const locale = englishLanguage || streamLanguages?.[0]
        setUserLanguage(locale)
        i18n.changeLanguage(languagesMap[locale].locale)
        // setProfile({ ...profile, locale })
      }
    }
  }, [userLanguage, stream, i18n, setUserLanguage])

  useEffect(() => {
    if (!profile) return
    if (!ENABLE_ANALYTICS) return

    if (profile.id) {
      amplitudeInstance.setUserId(profile.id)
      const identify = new amplitudeInstance.Identify()
        .set('passportUser', !!profile?.passportId)
        .set('twitchAccountLinked', isLinkedTwitchUser)
        .set('sdkVersion', process.env.SDK_VERSION ?? '')
      amplitudeInstance.identify(identify)
    }
  }, [profile, isLinkedTwitchUser])

  return <UserContext.Provider value={props}>{children}</UserContext.Provider>
}
