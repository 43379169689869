import React, { CSSProperties, FC, UIEvent, useRef } from 'react'
import { motion } from 'framer-motion'
import { ReactComponent as BackIcon } from '@assets/icons/icon_filled_back.svg'
import { ModalProps } from './types'
import './Modal.styles.css'

const variants = {
  enter: {
    x: 0,
    transition: {
      duration: 0.3,
    },
  },
  out: {
    x: '100%',
    transition: {
      duration: 0.3,
    },
  },
}

const Modal: FC<ModalProps> = ({
  onGoBack,
  title,
  className,
  titleClassName,
  childrenClassName = '',
  children,
  centerTitle,
  rightAction,
  routerChildren,
  displaySolidBackground = true,
  shouldAnimate = true,
  'data-testid': testId = 'modal',
}) => {
  const headerJustify = centerTitle ? 'justify-between' : ''
  const headerClasses = `flex mb-4 items-center ${headerJustify}`
  const backgroundClass = displaySolidBackground ? 'bg-black' : ''
  const rightSideRef = useRef<HTMLDivElement>(null)

  const rightSideView =
    rightAction ||
    (centerTitle ? (
      <div data-testid="modal__right-side" className="h-6 w-6 flex-shrink-0" />
    ) : null)

  const leftSideStyle: CSSProperties | undefined = centerTitle
    ? {
        width: rightSideRef.current?.clientWidth,
      }
    : undefined

  const rightSideClasses = !centerTitle ? 'ms-auto' : ''

  const leftSideView = onGoBack ? (
    <button
      data-testid={`${testId}__back-button`}
      className="bg-transparent relative modal__back-button h-6 w-6 flex-shrink-0"
      onClick={onGoBack}
    >
      <BackIcon className="w-full" fill="var(--color-label-50)" />
    </button>
  ) : (
    <div data-testid="modal__left-side" className="h-6 w-6 flex-shrink-0" />
  )

  /**
   * Fixes an animation bug with passcode & email login routes
   * @param {UIEvent} event
   */
  const handleModalScroll = (event: UIEvent) => {
    const target: any = event.target
    if (target.scrollLeft) {
      target.scrollLeft = 0
    }
    event.preventDefault()
  }

  return (
    <motion.div
      className={`text-label-50 ${backgroundClass} absolute inset-0 z-20 rounded-ts-md ${className}`}
      data-testid={testId}
      variants={shouldAnimate ? variants : undefined}
      initial="out"
      animate="enter"
      exit="out"
      onScroll={handleModalScroll}
    >
      <div className="p-4 z-10 flex flex-col overflow-auto hide-scrollbar inset-0 absolute bg-gradient-to-r from-background-start to-background-end">
        <div className={headerClasses}>
          <div className="flex" style={leftSideStyle}>
            {leftSideView}
          </div>
          <span
            className={`text-lg mx-2 font-bold capitalize sb-break-words text-center ${titleClassName}`}
          >
            {title}
          </span>
          <div className={rightSideClasses} ref={rightSideRef}>
            {rightSideView}
          </div>
        </div>

        <div className={`flex flex-1 ${childrenClassName}`}>{children}</div>
      </div>
      {routerChildren}
    </motion.div>
  )
}

export default Modal
