import {
  METRIC_EVENTS,
  METRIC_PAYLOAD_MAP,
  PROPERTY_EVENTS,
} from '@interfaces/metrics'
import amplitude from 'amplitude-js'

const AMPLITUDE_KEY = process.env.AMPLITUDE_KEY ?? ''
export const ENABLE_ANALYTICS = process.env.ENABLE_ANALYTICS === 'true'

export const amplitudeInstance = amplitude.getInstance()

export const initAmplitude = () => {
  if (!ENABLE_ANALYTICS) return

  amplitudeInstance.init(AMPLITUDE_KEY, undefined, { disableCookies: true })

  if (amplitudeInstance.isNewSession()) {
    amplitudeInstance.clearUserProperties()
  }

  amplitudeInstance.setVersionName(process.env.SDK_VERSION ?? '')

  return amplitudeInstance
}

/**
 * Doesn't actually destroy anything as no method for this
 */
export const destroyAmplitude = () => {
  amplitudeInstance.clearUserProperties()
}

export const setGroup = (key: string, value: string) => {
  if (!ENABLE_ANALYTICS || !amplitudeInstance) return
  amplitudeInstance.setGroup(key, value)
}

export const sendEvent = <T extends METRIC_EVENTS>(
  event: T,
  data?: METRIC_PAYLOAD_MAP[T]
) => {
  const session = amplitudeInstance.getSessionId()
  if (!ENABLE_ANALYTICS || !amplitudeInstance || !session) return
  amplitudeInstance?.logEvent?.(event, data)
}

export const sendCounterEvent = (
  event: PROPERTY_EVENTS,
  counter: number = 1
) => {
  const session = amplitudeInstance.getSessionId()
  if (!ENABLE_ANALYTICS || !amplitudeInstance || !session) return
  const identify = new amplitudeInstance.Identify().add(event, counter)
  amplitudeInstance.identify(identify)
}
