import { LanguageEnum } from '@interfaces/languages'
import {
  LocalisedImageContent,
  LocalisedAnswerContent,
  LocalisedSponsorContent,
  LocalisedContent,
  LocalisedAuthor,
  LocalisedQuestion,
  LocalisedSponsorContentField,
} from '@interfaces/localisedContent'

export enum VoteableLifecycle {
  VOTEABLE_LIFECYCLE_POLL = 'VOTEABLE_LIFECYCLE_POLL',
  VOTEABLE_LIFECYCLE_QUIZ = 'VOTEABLE_LIFECYCLE_QUIZ',
  VOTEABLE_LIFECYCLE_PREDICTION = 'VOTEABLE_LIFECYCLE_PREDICTION',
  VOTEABLE_LIFECYCLE_POPULAR_VOTE = 'VOTEABLE_LIFECYCLE_POPULAR_VOTE',
}

export enum VoteableLayout {
  VOTEABLE_LAYOUT_HORIZONTAL = 'VOTEABLE_LAYOUT_HORIZONTAL',
  VOTEABLE_LAYOUT_VERTICAL = 'VOTEABLE_LAYOUT_VERTICAL',
}

export enum VoteableAnswerInterface {
  ANSWER_INTERFACE_PICK_ONE = 'ANSWER_INTERFACE_PICK_ONE',
  ANSWER_INTERFACE_DISCRETE_SLIDER = 'ANSWER_INTERFACE_DISCRETE_SLIDER',
  ANSWER_INTERFACE_STAR_SELECT = 'ANSWER_INTERFACE_STAR_SELECT',
}

export enum VoteableTransactionType {
  TRANSACTION_TYPE_UNSPECIFIED = 'TRANSACTION_TYPE_UNSPECIFIED',
  TRANSACTION_TYPE_TWITCH_BITS = 'TRANSACTION_TYPE_TWITCH_BITS',
}

interface VoteableAnswerPaymentOption {
  transactionType: VoteableTransactionType
  cost: number
}

/**
 * Properties that are optional are not set when voteable has no votes on it
 */
export interface RawEngagementAnswer {
  answerId: string
  views?: number
  votesCast?: number
  votesCounted?: number
}

/**
 * Properties that are optional are not set when voteable has no votes on it
 */
export interface RawEngagement {
  voteableId: string
  gameId: string
  views?: number
  votesCast?: number
  votesCounted?: number
  perAnswer: RawEngagementAnswer[]
}

export type Engagement = Omit<RawEngagement, 'perAnswer'> & {
  perAnswer: Record<string, EngagementAnswer>
}

export interface EngagementAnswer extends RawEngagementAnswer {
  percentage: number
}

export interface RawVoteableAnswer {
  /**
   * Id of the answer
   */
  id: string

  /**
   * Translated content
   */
  localisations: LocalisedAnswerContent[]

  /**
   * Payment options
   */
  paymentOptions?: VoteableAnswerPaymentOption[]

  /**
   * Number of points the answer will reward
   * VOTEABLE_LIFECYCLE_POLL shouldn't have any
   */
  points?: number

  /**
   * Boolean indicating if the answer is correct
   * VOTEABLE_LIFECYCLE_POLL shouldn't have
   */
  correct?: boolean
}

export type VoteableAnswer = Omit<RawVoteableAnswer, 'localisations'> & {
  localisations: Partial<Record<LanguageEnum, LocalisedAnswerContent>>
}

export interface RawVoteable<T = RawVoteableAnswer> {
  /**
   * Id of the voteable
   */
  voteableId: string

  /**
   * Id of the game voteable is attached to
   */
  gameId: string

  /**
   * Languages voteable will be shown in
   */
  localisations: LanguageEnum[]

  /**
   * Participation points for answering a voteable
   */
  participationPoints: number

  /**
   * How BE processes the voteable
   */
  lifecycle: VoteableLifecycle

  /**
   * date timestamp of when the voteable should be shown
   */
  opensAt: string

  /**
   * date timestamp when voteable should be hidden
   */
  closesAt: string

  /**
   * date timestamp when voteable should be resolved
   */
  resolvesAt?: string

  /**
   * The display mode the voteable should display in
   */
  layout: VoteableLayout

  /**
   * The display mode of the answers options
   */
  answerInterface: VoteableAnswerInterface

  /**
   * Author of the voteable
   */
  author: {
    localisations: LocalisedImageContent[]
  }

  /**
   * Sponsor image and link to be displayed on the side of the buff
   */
  sponsor?: {
    localisations: LocalisedSponsorContent[]
  } | null

  /**
   * Question of the voteable
   */
  question: {
    localisations: LocalisedContent[]
  }

  /**
   * Answers of the voteable
   */
  answers: T[]

  /**
   * How long the buff should display for resolved state
   */
  resultsDurationSeconds: number

  /**
   * How long the buff should display for expire state
   */
  summaryDurationSeconds?: number

  /**
   * How long the buff should display for published state
   */
  voteDurationSeconds: number
}

export type Voteable = Omit<
  RawVoteable<VoteableAnswer>,
  'author' | 'sponsor' | 'question'
> & {
  author: LocalisedAuthor
  sponsor?: LocalisedSponsorContentField
  question: LocalisedQuestion

  /**
   * Used to hide the timebar when used as a preview component
   */
  developer?: {
    hideTimebar?: boolean
  }
}

export interface VoteableResponse {
  voteable: RawVoteable
}

export interface VoteablesResponse {
  voteables: Voteable[]
  pagination: {
    nextPageToken: string
  }
}

export interface RawVoteablesResponse {
  voteables?: RawVoteable[]
  pagination?: {
    nextPageToken: string
  }
}

export interface EngagementResponse {
  engagement: RawEngagement
}

export type RawVodVoteable = Omit<
  RawVoteable,
  'gameId' | 'voteableId' | 'opensAt' | 'closesAt' | 'resolvesAt'
> & {
  id: string
  openedAtSeconds?: number
  closedAtSeconds?: number
  resolvedAtSeconds?: number
  disabled?: boolean
  visible?: boolean
}

export type VodVoteable = Omit<
  Voteable,
  'gameId' | 'voteableId' | 'opensAt' | 'closesAt' | 'resolvesAt'
> & {
  id: string
  openedAtSeconds?: number
  closedAtSeconds?: number
  resolvedAtSeconds?: number
  disabled?: boolean
  visible?: boolean
}
