export enum FloatingPanelTabsLayout {
  FILL_SCREEN = 'fill-screen',
  FIXED_HEIGHT = 'fixed-height',
}

export interface FloatingPanelTabsProps {
  /**
   * The layout of the tabs to render
   * @default FloatingPanelTabsLayout.FIXED_HEIGHT
   */
  layout?: FloatingPanelTabsLayout
}
