import { Buff } from '@interfaces/buff'
import { Voteable } from '@interfaces/voteable'

export enum BuffActionTypes {
  SELECT_ANSWER = 'select-answer',
  DELETE_ANSWER = 'delete-answer',
  ADD_VOTEABLE_TO_LIST = 'add-voteable-to-list',
  ADD_BUFF_TO_DISMISSED_LIST = 'add-buff-to-dismissed-list',
  ADD_ANNOUNCEMENT_TO_CLICKED_LIST = 'add-announcement-to-clicked-list',
}

interface Action<T, P = undefined> {
  type: T
  payload?: P
}

interface SelectAnswerActionPayload {
  voteableId: string
  answerId: string
}

interface DeleteAnswerActionPayload {
  voteableId: string
}

interface AddVoteableToListPayload {
  voteable: Voteable
}

interface AddBuffToDismissedList {
  id: string
}

interface AddAnnouncementToList {
  id: string
}

export interface BuffState {
  /**
   * An object containing a mapping of buff to selected answer
   *
   * key being the id of the voteable
   * value being the selected answer id
   */
  selectedAnswers: Record<string, string>

  /**
   * A set containing a list of dismissed buffs
   */
  dismissedBuffs: string[]

  /**
   * A set containing a list of announcements that link is clicked
   */
  clickedAnnouncements: string[]

  /**
   * Object of voteables listed by ids
   *
   * key being in the format gameId.voteableId
   */
  voteables: Record<string, Voteable>

  /**
   * List of vod buffs
   */
  vodBuffs: Buff[]
}

type SelectAnswerAction = Action<
  BuffActionTypes.SELECT_ANSWER,
  SelectAnswerActionPayload[]
>
type DeleteAnswerAction = Action<
  BuffActionTypes.DELETE_ANSWER,
  DeleteAnswerActionPayload
>
type AddVoteableToListAction = Action<
  BuffActionTypes.ADD_VOTEABLE_TO_LIST,
  AddVoteableToListPayload
>

type AddBuffToDismissedListAction = Action<
  BuffActionTypes.ADD_BUFF_TO_DISMISSED_LIST,
  AddBuffToDismissedList
>

type AddAnnouncementToClickedListAction = Action<
  BuffActionTypes.ADD_ANNOUNCEMENT_TO_CLICKED_LIST,
  AddAnnouncementToList
>

export type Actions =
  | SelectAnswerAction
  | DeleteAnswerAction
  | AddVoteableToListAction
  | AddBuffToDismissedListAction
  | AddAnnouncementToClickedListAction

export const buffReducer = (state: BuffState, action: Actions) => {
  switch (action.type) {
    case BuffActionTypes.SELECT_ANSWER:
      if (!action.payload) return state

      const newObj = action.payload.reduce(
        (acc: BuffState['selectedAnswers'], { voteableId, answerId }) => {
          acc[voteableId] = answerId
          return acc
        },
        {}
      )

      return {
        ...state,
        selectedAnswers: {
          ...state.selectedAnswers,
          ...newObj,
        },
      }

    case BuffActionTypes.DELETE_ANSWER:
      if (!action.payload) return state

      const selectedAnswersCopy = { ...state.selectedAnswers }
      delete selectedAnswersCopy[action.payload.voteableId]

      return {
        ...state,
        selectedAnswers: {
          ...selectedAnswersCopy,
        },
      }

    case BuffActionTypes.ADD_VOTEABLE_TO_LIST:
      const voteable = action.payload?.voteable
      if (!voteable) return state

      const key = `${voteable.gameId}.${voteable.voteableId}`

      return {
        ...state,
        voteables: {
          ...state.voteables,
          [key]: voteable,
        },
      }

    case BuffActionTypes.ADD_BUFF_TO_DISMISSED_LIST: {
      const id = action.payload?.id

      if (!id) return state

      const dismissedBuffs = [...state.dismissedBuffs, id]

      return {
        ...state,
        dismissedBuffs,
      }
    }

    case BuffActionTypes.ADD_ANNOUNCEMENT_TO_CLICKED_LIST: {
      const id = action.payload?.id

      if (!id) return state

      const clickedAnnouncements = [...state.clickedAnnouncements, id]

      return {
        ...state,
        clickedAnnouncements,
      }
    }

    default:
      return state
  }
}

export const initialState: BuffState = {
  selectedAnswers: {},
  voteables: {},
  vodBuffs: [],
  dismissedBuffs: [],
  clickedAnnouncements: [],
}
