import React from 'react'
import { AnimatePresence } from 'framer-motion'
import { Routes, Route, useLocation, Navigate } from 'react-router-dom'
import { RouteConfig } from '@interfaces/router'
import { RouteMainPaths } from '@interfaces/navigation'
import { SdkRouterProps } from './types'

const mapRoute = ({ children, ...route }: RouteConfig) => {
  const renderedChildren = children ? children.map(mapRoute) : undefined
  return (
    <Route key={route.path} {...route}>
      {renderedChildren}
    </Route>
  )
}

const SdkRouter = ({ routes }: SdkRouterProps) => {
  return <Routes>{routes.map(mapRoute)}</Routes>
}

export const AnimatedRouter = ({
  routes,
  animateKeyFirstSlug,
}: SdkRouterProps) => {
  const location = useLocation()
  const firstSlug = location.pathname.split('/')[1]

  const key = animateKeyFirstSlug ? firstSlug : location.pathname
  return (
    <AnimatePresence initial={false} custom={location.pathname}>
      <Routes location={location} key={key}>
        <Route
          path="/"
          element={<Navigate to={RouteMainPaths.LEADERBOARDS} replace />}
        />
        {routes.map(mapRoute)}
      </Routes>
    </AnimatePresence>
  )
}

export default SdkRouter
