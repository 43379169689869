import React, {
  createContext,
  useEffect,
  useReducer,
  useCallback,
  useContext,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { PubSubEventType } from '@interfaces/pubSub'
import { useBuffQueue, BuffQueueType } from '@utils/hooks/useBuffQueue'
import {
  getBuffType,
  isAnnouncement,
  isVodVoteable,
  getLateralImage,
} from '@utils/buff'
import { StreamContext } from '@services/providers/StreamProvider'
import { ConfigContext } from '@services/providers/ConfigProvider'
import { useSnoozeHandler } from '@utils/hooks/useSnoozeHandler'
import { useValueAsRef } from '@utils/hooks/useValueAsRef'
import {
  getAnnouncementsByGameId,
  getVoteablesByGameId,
} from '@services/requests/games'
import { VideoPlayerEvent } from '@interfaces/videoPlayer'
import { getBuffId, isVoteable } from '@utils/buff'
import usePreviousValue from '@utils/hooks/usePreviousValue'
import { UserContext } from '@services/providers/UserProvider'
import { ScreenContext } from '@services/providers/ScreenProvider'
import { useWebSocketClient } from '@utils/hooks/useWebSocketClient'
import { METRIC_EVENTS } from '@interfaces/metrics'
import { sendEvent } from '@utils/metrics'
import { queryClient } from '@utils/reactQuery'
import { logError } from '@utils/log'
import storage from '@utils/storage'
import { BUFF_LOCAL_STORAGE_NAMESPACE } from '../../../constants'
import { IBuffContextProps, IBuffProviderProps } from './types'
import { useVODBuffs } from './hooks/useVODBuffs'
import { buffReducer, initialState, BuffActionTypes } from './state/buffReducer'
import { useCastVoteableVote } from './hooks/useCastVoteableVote'
import { useWelcomeBuff } from './hooks/useWelcomeBuff'

export const missedBuffsStorageKey = `${BUFF_LOCAL_STORAGE_NAMESPACE}.missedBuffs`

export const REBROADCASTABLE_BUFFS = [
  'games:announcement.',
  'games:voteable.',
  'games:game.announce-winners',
]

export const BuffContext = createContext<IBuffContextProps>(
  {} as IBuffContextProps
)

export const BuffProvider = forwardRef(
  (
    { children, videoPlayer, preStreamBuffFrequency }: IBuffProviderProps,
    ref
  ) => {
    const { streamConfig } = useContext(ConfigContext)
    const { stream, games } = useContext(StreamContext)
    const { userId, userLanguage, token } = useContext(UserContext)
    const { screenDetails } = useContext(ScreenContext)
    const { subscribe, publish, unsubscribe, disconnectClient, connectClient } =
      useWebSocketClient()

    const {
      snooze,
      updateSnooze: updateSnoozeFn,
      snoozeStartDate,
    } = useSnoozeHandler()
    const firstGame = games?.[0]

    const userLanguageRef = useValueAsRef(userLanguage)
    const snoozeRef = useValueAsRef(snooze)
    const tokenRef = useValueAsRef(token)

    let queueType = streamConfig?.config?.timeSync
      ? BuffQueueType.TIME_SYNC
      : BuffQueueType.LIVE

    if (stream?.endedAt) {
      queueType = BuffQueueType.VOD
    }

    if (preStreamBuffFrequency) {
      queueType = BuffQueueType.PRE_STREAM_MODE
    }

    const queueHelpers = useBuffQueue({
      userId,
      snooze,
      preStreamBuffFrequency,
      type: queueType,
      language: userLanguage,
      gameEnded: firstGame?.ended ?? false,
      gameId: firstGame?.gameId,
    })

    const {
      activeQueueItem,
      activeQueueMessage,
      removeActiveQueueItem,
      clearQueue,
      updateGameTime,
      handleStreamPubSubMessage,
      getEngagement,
      handlePreStreamBuffs,
      voteState,
    } = queueHelpers

    const activeBuff = activeQueueItem?.buff
    const pubSubEvent = activeQueueItem?.eventType
    const [state, dispatch] = useReducer(buffReducer, initialState)

    const { selectedAnswers, dismissedBuffs, clickedAnnouncements } = state

    const prevPubSubEvent = usePreviousValue(pubSubEvent)
    const prevActiveBuff = usePreviousValue(activeBuff)
    const prevActiveQueueItem = usePreviousValue(activeQueueItem)

    const channel = games.length === 0 ? undefined : `games.${games[0].gameId}`
    const tvChannel = !screenDetails
      ? undefined
      : `tvSDK-${screenDetails?.screenId}`

    // Show selected answer for every state of the voteable for now
    const showSelectedAnswerId = !!activeBuff
    //  && (!!userId || (!userId && pubSubEvent === PubSubEventType.VOTEABLE_OPEN))

    const selectedAnswerId = showSelectedAnswerId
      ? selectedAnswers[getBuffId(activeBuff)]
      : undefined

    const selectedAnswersRef = useValueAsRef(selectedAnswers)
    const dismissedBuffsRef = useValueAsRef(dismissedBuffs)
    const clickedAnnouncementsRef = useValueAsRef(clickedAnnouncements)

    /**
     * Wraps the updateSnooze function to fire metrics
     */
    const updateSnooze = useCallback(
      (snooze: boolean) => {
        if (snooze) {
          disconnectClient()
          sendEvent(METRIC_EVENTS.snoozeActivated)
        } else {
          connectClient()
          const snoozedTime = snoozeStartDate ? Date.now() - snoozeStartDate : 0
          const missedBuffs = Number(
            storage.getItem(missedBuffsStorageKey) ?? 0
          )
          sendEvent(METRIC_EVENTS.snoozeDeactivated, {
            SnoozedTime: snoozedTime / 1000, // convert milliseconds to seconds
            missedBuffs,
          })
        }
        updateSnoozeFn(snooze)
      },
      [updateSnoozeFn, snoozeStartDate, connectClient, disconnectClient]
    )

    /**
     * A helper that will remove the active buff after a number of seconds
     *
     * @param {number} delay Number of ms to delay removing the active buff defaults to 0
     * @param {boolean} userClosed If the user closed the buff themselves
     */
    const removeActiveBuff = useCallback(
      (delay: number = 0, userClosed: boolean = false) => {
        removeActiveQueueItem(delay)

        if (userClosed && activeBuff) {
          dispatch({
            type: BuffActionTypes.ADD_BUFF_TO_DISMISSED_LIST,
            payload: { id: getBuffId(activeBuff) },
          })
        }
      },
      [activeBuff, removeActiveQueueItem, dispatch]
    )

    const broadcastCentrifugoTVMessage = useCallback(async () => {
      if (!tvChannel) return
      await publish(tvChannel, activeQueueMessage?.data)
    }, [publish, activeQueueMessage, tvChannel])

    useEffect(() => {
      if (!activeQueueMessage) return
      const shouldBroadcast = REBROADCASTABLE_BUFFS.some((type) =>
        activeQueueMessage.data.name.includes(type)
      )
      if (!shouldBroadcast) return
      broadcastCentrifugoTVMessage()
    }, [activeQueueMessage, broadcastCentrifugoTVMessage])

    const { voteWelcomeBuff } = useWelcomeBuff({
      dispatch,
      activeBuff,
      removeActiveBuff,
      queueHelpers,
      state,
      pubSubEventType: pubSubEvent,
    })

    const { voteBuff } = useCastVoteableVote({
      activeBuff,
      queueHelpers,
      dispatch,
      removeActiveBuff,
    })

    useVODBuffs({
      dispatch,
      videoPlayer,
      queueHelpers,
    })

    useImperativeHandle(ref, () => ({
      queueHelpers,
    }))

    useEffect(() => {
      if (!channel) return

      const subscription = subscribe(channel, handleStreamPubSubMessage)
      return () => {
        !!subscription && unsubscribe(subscription)
      }
    }, [channel, handleStreamPubSubMessage, subscribe, unsubscribe])

    useEffect(() => {
      if (!tvChannel) return

      const subscription = subscribe(tvChannel, () => {})
      return () => {
        !!subscription && unsubscribe(subscription)
      }
    }, [tvChannel, subscribe, unsubscribe])

    /**
     * Clears queue when channel changes
     */
    useEffect(() => {
      if (!channel) return
      clearQueue()
    }, [channel, clearQueue])

    /**
     * Sends buffTimeout metric if previous buff wasn't voted on
     */
    useEffect(() => {
      if (
        prevPubSubEvent === PubSubEventType.VOTEABLE_OPEN &&
        prevActiveBuff &&
        (isVoteable(prevActiveBuff) || isVodVoteable(prevActiveBuff)) &&
        prevActiveBuff !== activeBuff
      ) {
        const voteableId = getBuffId(prevActiveBuff)
        const hasVoted = Boolean(selectedAnswersRef.current[voteableId])

        const dismissed = dismissedBuffsRef.current?.includes(voteableId)

        const sponsorClicked = queryClient.getQueryData<boolean>(
          `buff.${getBuffId(prevActiveBuff)}.sponsorClicked`
        )

        const sponsorContent =
          prevActiveBuff?.sponsor?.localisations?.[userLanguageRef.current]

        const durationInSeconds = prevActiveQueueItem?.displayedAt
          ? (Date.now() - prevActiveQueueItem?.displayedAt) / 1000
          : undefined

        const roundedDurationInSeconds = durationInSeconds
          ? Math.round(durationInSeconds * 10) / 10
          : undefined

        const votedInSeconds =
          prevActiveQueueItem?.votedAt && prevActiveQueueItem?.displayedAt
            ? (prevActiveQueueItem?.votedAt -
                prevActiveQueueItem?.displayedAt) /
              1000
            : undefined

        const roundedVotedInSeconds = votedInSeconds
          ? Math.round(votedInSeconds * 10) / 10
          : -1

        sendEvent(METRIC_EVENTS.voteableDisplayed, {
          id: voteableId,
          answers: prevActiveBuff?.answers?.length,
          type: getBuffType(prevActiveBuff) ?? undefined,
          ignore: !hasVoted && !dismissed,
          dismiss: dismissed,
          sponsored: !!sponsorContent?.imageUrl,
          link: {
            URL: sponsorContent?.linkTarget,
            Clicked: !!sponsorClicked,
          },
          duration: roundedDurationInSeconds,
          loggedIn: !!tokenRef.current,
          votedInSeconds: roundedVotedInSeconds,
        })
      }

      if (
        prevPubSubEvent === PubSubEventType.ANNOUNCEMENT_OPEN &&
        prevActiveBuff &&
        isAnnouncement(prevActiveBuff) &&
        prevActiveBuff !== activeBuff
      ) {
        const durationInSeconds = prevActiveQueueItem?.displayedAt
          ? (Date.now() - prevActiveQueueItem?.displayedAt) / 1000
          : undefined

        const roundedDurationInSeconds = durationInSeconds
          ? Math.round(durationInSeconds * 10) / 10
          : undefined

        const dismissed = dismissedBuffsRef.current?.includes(
          prevActiveBuff.announcementId
        )
        const isClicked = clickedAnnouncementsRef.current?.includes(
          prevActiveBuff.announcementId
        )

        const sponsorContent =
          prevActiveBuff?.sponsor?.localisations?.[userLanguageRef.current]

        const sponsorClicked = queryClient.getQueryData<boolean>(
          `buff.${getBuffId(prevActiveBuff)}.sponsorClicked`
        )

        const image = getLateralImage(userLanguageRef.current, prevActiveBuff)

        sendEvent(METRIC_EVENTS.announcementDisplayed, {
          id: prevActiveBuff?.announcementId ?? '',
          dismiss: dismissed,
          sponsored: !!sponsorContent?.imageUrl,
          sponsor: {
            URL: sponsorContent?.linkTarget,
            Clicked: !!sponsorClicked,
          },
          link: {
            Clicked: isClicked,
            URL: prevActiveBuff?.content?.localisations?.[
              userLanguageRef.current
            ]?.linkUrl,
          },
          image: {
            attached: Boolean(image),
          },
          duration: roundedDurationInSeconds,
        })
      }
    }, [
      prevActiveQueueItem,
      activeBuff,
      prevActiveBuff,
      pubSubEvent,
      prevPubSubEvent,
      selectedAnswersRef,
      dismissedBuffsRef,
      clickedAnnouncementsRef,
      userLanguageRef,
    ])

    /**
     * Hooks up the TIME_SYNC time updating to the queue
     */
    useEffect(() => {
      if (queueType !== BuffQueueType.TIME_SYNC || !videoPlayer) return

      videoPlayer.on(VideoPlayerEvent.TIME_SYNC_DATE_UPDATE, (time) => {
        updateGameTime(time)
      })
    }, [queueType, videoPlayer, updateGameTime])

    /**
     * Hooks up the TOGGLE_SNOOZE event to update snooze
     */
    useEffect(() => {
      if (!videoPlayer) return

      const handleToggleSnooze = () => updateSnooze(!snoozeRef.current)

      videoPlayer.on(VideoPlayerEvent.TOGGLE_SNOOZE, handleToggleSnooze)

      return () => {
        videoPlayer.off(VideoPlayerEvent.TOGGLE_SNOOZE, handleToggleSnooze)
      }
    }, [videoPlayer, snoozeRef, updateSnooze])

    useEffect(() => {
      const gameId = firstGame?.gameId
      if (queueType !== BuffQueueType.PRE_STREAM_MODE || !gameId) return
      if (!token) return

      Promise.all([
        getAnnouncementsByGameId(gameId),
        getVoteablesByGameId(gameId),
      ])
        .then(([announcements, voteables]) => {
          const liveVoteables = voteables.filter((voteable) => {
            const closesAt = new Date(voteable.closesAt)
            const now = new Date()

            return closesAt > now
          })
          const mergedArray = [...announcements, ...liveVoteables]
          handlePreStreamBuffs(mergedArray)
        })
        .catch((error) => {
          logError(error)
        })
    }, [queueType, handlePreStreamBuffs, firstGame?.gameId, token])

    const providerProps: IBuffContextProps = {
      activeBuff,
      getEngagement,
      selectedAnswers,
      removeActiveBuff,
      voteBuff,
      voteWelcomeBuff,
      dispatch,
      selectedAnswerId,
      voteState,
      pubSubEvent,
      snooze,
      updateSnooze,
    }

    return (
      <BuffContext.Provider value={providerProps}>
        {children}
      </BuffContext.Provider>
    )
  }
)

BuffProvider.displayName = 'BuffProvider'
