import { client } from '@services/requests/client'
import { Screen } from '@interfaces/screen'

/**
 * Registers a screen
 * @param {string} secret Secret, typically device's unique identifier
 * @param {string} streamId Id of the stream
 * @return {Promise<Screen>}
 */
export const registerScreen = async (secret: string, streamId: string) => {
  const response = await client.post<Screen>('/screens', {
    secret,
    streamID: streamId,
  })
  return response.data
}
