import React, { useState } from 'react'
import Image from '@components/atoms/Image'
import { ReactComponent as Tick } from '@assets/icons/tick.svg'
import { ReactComponent as Person } from '@assets/icons/person.svg'
import { ReactComponent as Placeholder } from '@assets/icons/placeholder.svg'
import { PictureSelectionProps } from './types'

const PictureSelection = ({
  selected,
  className,
  image,
  disabled,
  onSelect,
}: PictureSelectionProps) => {
  const borderColor = selected ? 'border-primary' : 'border-label-500'

  return (
    <button
      disabled={disabled}
      className={`relative rounded-full m-2 w-14 h-14 ${className}`}
      onClick={() => onSelect(image)}
      data-testid="profile-selection"
    >
      <div
        data-testid="profile-selection__placeholder"
        className={`absolute inset-0 flex items-center justify-center bg-[#E9EEF1] rounded-full border-2 ${borderColor}`}
      >
        <Placeholder className="w-6" />
      </div>

      <Image
        data-testid="profile-selection__image"
        className={`absolute inset-0 rounded-full w-full h-full bg-white border-2 ${borderColor} inline-block`}
        src={image}
        displayOnLoad={true}
        fallbackComponent={
          <div
            data-testid="profile-selection__default-image"
            className={`absolute inset-0 flex items-center justify-center bg-[#D1D6DE] rounded-full border-2 ${borderColor}`}
          >
            <Person
              className="text-[#323A46] w-9"
              data-testid="profile-selection__placeholder"
            />
          </div>
        }
      />

      {selected && (
        <>
          <div
            data-testid="profile-selection__background"
            className="absolute -inset-0.5 bg-primary rounded-full -z-1 opacity-50"
          />
          <div className="absolute flex items-center justify-center bottom-0 right-0 bg-primary w-5 h-5 rounded-full">
            <Tick className="text-white w-2" />
          </div>
        </>
      )}
    </button>
  )
}

export default PictureSelection
