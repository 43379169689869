import React, { useState } from 'react'
import { ImageProps } from './types'

const Image = ({
  src,
  alt,
  fallbackComponent,
  className = '',
  'data-testid': testId,
  forceFallback = false,
  displayOnLoad = false,
}: ImageProps) => {
  const [srcError, setSrcError] = useState<Record<string, boolean>>({})
  const [srcLoaded, setSrcLoaded] = useState<Record<string, boolean>>({})

  const handleError = () => {
    if (!src) return
    setSrcError((values) => {
      return {
        ...values,
        [src]: true,
      }
    })
  }

  const handleLoad = () => {
    if (!src) return
    setSrcLoaded((values) => {
      return {
        ...values,
        [src]: true,
      }
    })
  }

  if (src && srcError[src] === undefined && !forceFallback) {
    const imageStyle =
      displayOnLoad && srcLoaded[src] === undefined ? { display: 'none' } : {}

    return (
      <img
        src={src}
        alt={alt}
        className={className}
        data-testid={testId}
        onError={handleError}
        onLoad={handleLoad}
        style={imageStyle}
      />
    )
  }

  if (fallbackComponent || forceFallback) {
    return fallbackComponent || <></>
  }

  return null
}

export default Image
