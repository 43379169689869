import React from 'react'
import { languagesMap } from '@utils/language'
import { ReactComponent as CheckedCircle } from '@assets/icons/checked-circle.svg'
import { LanguageOptionProps } from './types'

const LanguageOption = ({
  language,
  onClick,
  isSelected = false,
  'data-testid': testId = 'language-option',
}: LanguageOptionProps) => {
  const { name } = languagesMap[language]
  const fontWeight = isSelected ? 'font-bold' : ''
  const iconSharedClasses = 'w-4 h-4 me-2'
  const cursor = isSelected ? 'cursor-default' : 'cursor-pointer'

  return (
    <button
      type="button"
      className={`${cursor} w-full p-2 text-base ${fontWeight}`}
      onClick={onClick}
      disabled={isSelected}
      data-testid={testId}
      id={language.toLowerCase()}
    >
      <div className="flex items-center">
        {isSelected ? (
          <CheckedCircle
            className={`text-primary ${iconSharedClasses}`}
            data-testid={`${testId}__check`}
          />
        ) : (
          <div
            className={`rounded-full border border-label-50 bg-label-400 opacity-30 ${iconSharedClasses}`}
            data-testid={`${testId}__circle`}
          />
        )}

        {name}
      </div>
    </button>
  )
}

export default LanguageOption
