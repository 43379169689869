import React, { useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RoutePaths } from '@interfaces/navigation'
import { ConfigContext } from '@services/providers/ConfigProvider'
import { TermsAndPolicyProps } from './types'

const TermsAndPolicy = ({
  className,
  descriptionTranslationKey = 'auth.termsAndPolicy',
  termsConditionsUrl = RoutePaths.TERMS_CONDITIONS_AUTH,
  privacyPolicyUrl = RoutePaths.PRIVACY_POLICY_AUTH,
}: TermsAndPolicyProps) => {
  const { clientConfig } = useContext(ConfigContext)
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleTermsClick = () => {
    if (!!clientConfig?.signup?.termsOfService) {
      window.open(clientConfig?.signup?.termsOfService, '_blank')
    } else {
      navigate(termsConditionsUrl)
    }
  }

  const handlePrivacyClick = () => {
    if (!!clientConfig?.signup?.privacyPolicy) {
      window.open(clientConfig?.signup?.privacyPolicy, '_blank')
    } else {
      navigate(privacyPolicyUrl)
    }
  }

  return (
    <p
      className={`text-xs text-label-50 text-center ${className}`}
      data-testid="terms-and-policy"
    >
      <Trans
        i18nKey={descriptionTranslationKey}
        values={{
          value1: t('general.termsOfService'),
          value2: t('general.privacyPolicy'),
        }}
        components={[
          <button
            onClick={handleTermsClick}
            key="index_1"
            className="text-primary underline outline-none border-none"
            data-testid="terms-link"
          ></button>,
          <button
            onClick={handlePrivacyClick}
            key="index_2"
            className="text-primary underline outline-none border-none"
            data-testid="policy-link"
          ></button>,
        ]}
      />
    </p>
  )
}

export default TermsAndPolicy
