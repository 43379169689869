import { MotionProps } from 'framer-motion'
import { CSSProperties, ReactElement } from 'react'

export interface InfoBubbleProps {
  /**
   * Text to display in the bubble
   */
  text: string | ReactElement

  /**
   * Text to display in the highlighted section
   */
  highlightText?: string

  /**
   * Text colour in the highlighted section
   */
  highlightColor?: string

  /**
   * Custom class name
   */
  className?: string

  /**
   * Background of the bubble
   */
  bg?: string

  /**
   * Extra style from parent
   */
  style?: CSSProperties

  /**
   * Extra animation props
   */
  motionProps?: MotionProps

  /**
   * Show/Hide points
   * @default true
   */
  allowGamePoints?: boolean

  /**
   * Selection ID for test purposes.
   */
  'data-testid'?: string
}

export enum InfoBubbleHighlightColors {
  ERROR = 'text-error',
  SUCCESS = 'text-success',
  PARTICIPATION = 'text-secondary',
}
