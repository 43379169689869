export const BUFF_LOCAL_STORAGE_NAMESPACE = 'SportBuff'

export const BUFFUP_SDK_TOKEN = `SPORT_BUFF_TOKEN`
export const BUFFUP_SDK_REFRESH_TOKEN = `SPORT_BUFF_REFRESH_TOKEN`

export const BUFFUP_ANON_ID = 'SPORT_BUFF_ANON_ID'

export const QRCODE_URL = 'https://www.sportbuff.com/2ndscreenapp'

export const ANSWER_SHOW_STATE_MS = 3500
