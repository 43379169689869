import React, { useContext, FC } from 'react'
import { UserContext } from '@services/providers/UserProvider'

const WritingModeWrapper: FC = ({ children }) => {
  const { writingMode } = useContext(UserContext)

  return (
    <div
      dir={writingMode}
      className="absolute inset-0 pointer-events-none writing-mode-wrapper"
    >
      {children}
    </div>
  )
}

export default WritingModeWrapper
