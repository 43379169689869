import { Asset, AssetClass, AssetsResponse } from '@interfaces/asset'
import { client } from '@services/requests/client'
import { AxiosResponse } from 'axios'

/**
 * Get all assets
 * @return {Promise<Asset[]>}
 */
export const getAssets = async (): Promise<Asset[]> => {
  const response: AxiosResponse<AssetsResponse> = await client.get(
    `assets/${AssetClass.ProfilePictures}?pagination.pageSize=1000`
  )

  return response.data.assets
}
