import React, { FC } from 'react'
import { PubSubEventType } from '@interfaces/pubSub'
import { Voteable, Engagement } from '@interfaces/voteable'
import Rating from '@components/molecules/Rating'
import { IStarRatingProps } from './types'

const getRating = (voteable: Voteable, engagement?: Engagement) => {
  if (!engagement) return undefined
  const totalVotes = engagement?.votesCounted ?? 0
  if (totalVotes === 0) return undefined

  const weightedVotes = Object.values(engagement.perAnswer).reduce(
    (total, engagement) => {
      // Get index from buff as these are in order unlike engagement where can be random
      const index = voteable.answers.findIndex(
        (answer) => answer.id === engagement.answerId
      )
      if (index === -1) return total

      return total + (index + 1) * (engagement.votesCounted ?? 0)
    },
    0
  )
  const average = weightedVotes / totalVotes

  if (Number.isNaN(average)) {
    return undefined
  }

  return average
}

const StarsRating: FC<IStarRatingProps> = ({
  buff,
  pubSubEventType,
  selectedAnswerId,
  engagement,
  votedAnswerId,
  setSelectedAnswerId,
}) => {
  const userRating = selectedAnswerId
    ? buff.answers.findIndex(({ id }) => id === selectedAnswerId) + 1
    : undefined

  const handleRatingChange = (rating: number) => {
    const answer = buff.answers[rating - 1]
    setSelectedAnswerId && setSelectedAnswerId(answer.id)
  }

  const disabled =
    !!votedAnswerId || pubSubEventType !== PubSubEventType.VOTEABLE_OPEN

  const rating =
    pubSubEventType === PubSubEventType.VOTEABLE_CLOSE
      ? getRating(buff, engagement)
      : undefined

  return (
    <div data-testid="star" className="relative">
      {(pubSubEventType === PubSubEventType.VOTEABLE_OPEN ||
        pubSubEventType === PubSubEventType.VOTEABLE_CLOSE) && (
        <div className="flex w-full">
          <Rating
            disabled={disabled}
            value={rating}
            userRating={userRating}
            onRatingChange={handleRatingChange}
          />
        </div>
      )}
    </div>
  )
}

export default StarsRating
