import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { snakeCase, camelCase } from 'change-case'
import { convertKeyNames } from '@utils/object'

/**
 * @param {AxiosRequestConfig} request
 * @return {AxiosRequestConfig}
 */
const requestFulfilled = (request: AxiosRequestConfig): AxiosRequestConfig => {
  // if we have no data then we can just return the original request
  if (!request.data || request?.data instanceof FormData) {
    return request
  }

  // convert our data keys to snake case
  const data = convertKeyNames(request.data, snakeCase)

  // add our converted json data to the request and return it
  request.data = data
  return request
}

/**
 * @param {AxiosResponse} response
 * @return {AxiosResponse}
 */
const responseFulfilled = (response: AxiosResponse): AxiosResponse => {
  response.data = convertKeyNames(response.data, camelCase)
  return response
}

/**
 * @param {any} error
 * @return {Promise}
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const responseRejected = (error: any) => {
  if (!axios.isCancel(error) && typeof error?.response?.data === 'object') {
    error.response.data = convertKeyNames(error.response.data, camelCase)
  }
  return Promise.reject(error)
}

export const changeCaseInterceptor = {
  requestFulfilled,
  responseFulfilled,
  responseRejected,
}

/**
 * Will return the response data from the axios response
 * @param {AxiosResponse} response The response
 * @return {any}
 */
export const returnResponseData = (response: AxiosResponse) => {
  if (response.data.data !== undefined) {
    return response.data.data
  }

  return response.data
}
